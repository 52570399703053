import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Typography
} from "@material-ui/core";

import { toast } from "react-toastify";

import { storage } from "../firebase";

import { compareObjects, splitTextInParagraphs } from "../utils";

import UploadImage from "./UploadImage";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  avatar: {
    borderRadius: 5
  },
  field: {
    padding: "16px 0"
  },
  socialItem: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  socialFields: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }
});

class ShopWebsiteInfoFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshShopImages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { info } = this.props;
    const prevInfo = prevProps.info;

    const assetFilenames =
      info && info.assets ? Object.values(info.assets) : undefined;
    const prevAssetFilenames =
      prevInfo && prevInfo.assets ? Object.values(prevInfo.assets) : undefined;

    if (!compareObjects(assetFilenames, prevAssetFilenames)) {
      this.refreshShopImages();
    }
  }

  refreshShopImages() {
    const { shop, info } = this.props;

    if (shop && shop.id && info && info.assets) {
      storage
        .getShopImages(shop.id, info.assets)
        .then((shopImages) => {
          this.setState({ shopImages });
        })
        .catch((error) => {
          this.setState({ shopImages: null });

          console.warn(error);
        });
    } else {
      this.setState({ shopImages: null });
    }
  }

  handleTextChange = (name) => (event) => {
    const value = event.target.value;
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, [name]: value });
  };

  handleLongTextChange = (name) => (event) => {
    const value = splitTextInParagraphs(event.target.value);
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, [name]: value });
  };

  handleSocialChange = (name) => (event) => {
    const value = event.target.value;
    const { info, onUpdate } = this.props;

    let addChannel = true;
    const newSocial = info.social.map((channel) => {
      if (channel.name === name) {
        channel.url = value;
        addChannel = false;
      }

      return channel;
    });

    if (addChannel) {
      newSocial.push({ name, url: value });
    }

    onUpdate({
      ...info,
      social: newSocial
    });
  };

  handleImageUploadSuccess = (field) => (filename) => {
    const { info, onUpdate } = this.props;

    onUpdate({
      ...info,
      assets: { ...info.assets, [field]: filename }
    });
  };

  handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  render() {
    const { classes, open, onClose, shop, info, ...other } = this.props;
    const { tagline, story, social } = info;
    const { shopImages } = this.state;

    let socialFacebookUrl = "";
    let socialInstagramUrl = "";
    if (social) {
      social.forEach((channel) => {
        switch (channel.name) {
          case "facebook":
            socialFacebookUrl = channel.url;
            break;
          case "instagram":
            socialInstagramUrl = channel.url;
            break;
        }
      });
    }

    return (
      <div className={classes.content}>
        <List>
          <ListItem className={classes.field}>
            <ListItemAvatar>
              <Avatar
                alt="Logo"
                src={
                  shopImages && shopImages.logo ? shopImages.logo : undefined
                }
                className={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText primary="Logo" />
            <ListItemSecondaryAction>
              {shop && shop.id && (
                <UploadImage
                  label=""
                  uploadRef={storage.getShopAssetsRef(shop.id)}
                  onSuccess={this.handleImageUploadSuccess("logoFilename")}
                  onError={this.handleImageUploadError}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.field}>
            <ListItemAvatar>
              <Avatar
                alt="Primaire achtergrondfoto"
                src={
                  shopImages && shopImages.launch
                    ? shopImages.launch
                    : undefined
                }
                className={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText primary="Primaire achtergrondfoto" />
            <ListItemSecondaryAction>
              {shop && shop.id && (
                <UploadImage
                  label=""
                  uploadRef={storage.getShopAssetsRef(shop.id)}
                  onSuccess={this.handleImageUploadSuccess("launchFilename")}
                  onError={this.handleImageUploadError}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.field}>
            <ListItemAvatar>
              <Avatar
                alt="Secundaire achtergrondfoto"
                src={
                  shopImages && shopImages.story ? shopImages.story : undefined
                }
                className={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText primary="Secundaire achtergrondfoto" />
            <ListItemSecondaryAction>
              {shop && shop.id && (
                <UploadImage
                  label=""
                  uploadRef={storage.getShopAssetsRef(shop.id)}
                  onSuccess={this.handleImageUploadSuccess("storyFilename")}
                  onError={this.handleImageUploadError}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.field}>
            <TextField
              margin="dense"
              id="tagline"
              label="Tagline (dit verschijnt onder je logo)"
              fullWidth
              value={tagline}
              onChange={this.handleTextChange("tagline")}
            />
          </ListItem>
          <ListItem className={classes.field}>
            <TextField
              margin="dense"
              id="story"
              label="Vertel je klanten iets over je zaak"
              multiline
              rows="4"
              fullWidth
              value={story.join("\n\n")}
              onChange={this.handleLongTextChange("story")}
            />
          </ListItem>
          <ListItem className={classNames(classes.socialItem, classes.field)}>
            <div className={classes.socialFields}>
              <TextField
                margin="dense"
                id="singular"
                label="Link naar je Facebook pagina"
                fullWidth
                value={socialFacebookUrl}
                onChange={this.handleSocialChange("facebook")}
              />
              <TextField
                margin="dense"
                id="plural"
                label="Link naar je Instagram pagina"
                fullWidth
                value={socialInstagramUrl}
                onChange={this.handleSocialChange("instagram")}
              />
            </div>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(ShopWebsiteInfoFields);
