import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({});

class AvailabilityFade extends PureComponent {
  constructor() {
    super();
  }

  getAvailabilityColor(availability) {
    if (availability === undefined) {
      return "#DDD";
    }

    switch (availability) {
      case -1:
        return "#AAA";
      case 0:
        return "#FF7E79";
      case 1:
        return "#D4FB79";
      default:
        return "#D4FB79";
    }
  }

  render() {
    const { width, height, availability, classes } = this.props;

    return (
      <div>
        {availability &&
          Object.keys(availability).map((hour, index) => (
            <div
              key={index}
              style={{
                width,
                height,
                backgroundColor: this.getAvailabilityColor(availability[hour])
              }}
            />
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(AvailabilityFade);
