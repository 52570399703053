import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import AuthUserContext from '../context/auth';

import * as routes from '../constants/routes';

const Navigation = () =>
  <div></div>
  /*<AuthUserContext.Consumer>
  {authUser => authUser
    ? <NavigationAuth />
    : <NavigationNonAuth />
  }
  </AuthUserContext.Consumer>*/

const NavigationAuth = () =>
  <ul>
    <li><Link to={routes.LANDING}>Landing</Link></li>
    <li><Link to={routes.HOME}>Home</Link></li>
    <li><Link to={routes.ACCOUNT}>Account</Link></li>
  </ul>

const NavigationNonAuth = () =>
  <ul>
    <li><Link to={routes.LANDING}>Landing</Link></li>
    <li><Link to={routes.SIGN_IN}>Sign In</Link></li>
  </ul>

export default Navigation;
