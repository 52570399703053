import React, { Component } from "react";
import PropTypes from "prop-types";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Loading from "./Loading";
import Error from "./Error";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import {
  jsDateToDateString,
  isValidDate,
  dateStringToJSDate,
  timeToMinutes,
  minutesToTime
} from "../utils";
import { store } from "../firebase";

import Moment from "moment";

import CircularProgress from "@material-ui/core/CircularProgress";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  picker: {},
  resize: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 14,
    textTransform: "uppercase",
    color: "#f50057"
  },
  progress: {
    display: "block",
    textAlign: "center"
  },
  horizontal: {
    display: "flex",
    flexDirection: "row"
  }
});

class AddHoliday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      begin: new Date(),
      end: new Date(),
      dayType: "fullDay",
      beginMinutes: 600,
      endMinutes: 1200,
      loading: {
        open: false
      },
      error: {
        open: false
      }
    };

    this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
    //this.handleBeginDateBlur = this.handleBeginDateBlur.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    //this.handleEndDateBlur = this.handleEndDateBlur.bind(this);
    this.handleBeginHourChange = this.handleBeginHourChange.bind(this);
    this.handleBeginHourBlur = this.handleBeginHourBlur.bind(this);
    this.handleEndHourChange = this.handleEndHourChange.bind(this);
    this.handleEndHourBlur = this.handleEndHourBlur.bind(this);
    this.handleDayTypeChange = this.handleDayTypeChange.bind(this);
    this.handleAddHoliday = this.handleAddHoliday.bind(this);
  }

  handleBeginDateChange(date) {
    const { end } = this.state;

    if (date) {
      try {
        const beginDate = date.add(2, "hours").toDate(); // GMT+2

        if (isValidDate(beginDate)) {
          const newState = { begin: beginDate };
          if (beginDate > end) {
            newState.end = beginDate;
          }
          this.setState(newState);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  /*handleBeginDateChange(event) {
    if (event.target.value) {
      try {
        const beginDate = dateStringToJSDate(event.target.value);

        if (isValidDate(beginDate)) {
          this.setState({ begin: beginDate });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleBeginDateBlur(event) {
    const { begin, end } = this.state;

    if (begin > end) {
      this.setState({ end: begin });
    }
  }*/

  handleEndDateChange(date) {
    const { begin } = this.state;

    if (date) {
      try {
        const endDate = date.add(2, "hours").toDate(); // GMT+2

        if (isValidDate(endDate)) {
          const newState = { end: endDate };
          if (endDate < begin) {
            this.setState({ begin: endDate });
          }
          this.setState(newState);
        }
      } catch (error) {
        console.warn(error);
      }
    }
  }

  /*handleEndDateChange(event) {
    if (event.target.value) {
      try {
        const endDate = dateStringToJSDate(event.target.value);

        if (isValidDate(endDate)) {
          this.setState({ end: endDate });
        }
      } catch (error) {
        console.warn(error);
      }
    }
  }

  handleEndDateBlur(event) {
    const { begin, end } = this.state;

    if (end < begin) {
      this.setState({ begin: end });
    }
  }*/

  handleDayTypeChange(event) {
    this.setState({ dayType: event.target.value });
  }

  handleBeginHourChange(event) {
    if (event.target.value) {
      try {
        const beginMinutes = timeToMinutes(event.target.value);

        this.setState({ beginMinutes });
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleBeginHourBlur(event) {
    const { beginMinutes, endMinutes } = this.state;

    if (beginMinutes > endMinutes) {
      this.setState({ endMinutes: beginMinutes });
    }
  }

  handleEndHourChange(event) {
    if (event.target.value) {
      try {
        const endMinutes = timeToMinutes(event.target.value);

        this.setState({ endMinutes });
      } catch (error) {
        console.warn(error);
      }
    }
  }

  handleEndHourBlur(event) {
    const { beginMinutes, endMinutes } = this.state;

    if (endMinutes < beginMinutes) {
      this.setState({ beginMinutes: endMinutes });
    }
  }

  handleAddHoliday() {
    const { begin, end } = this.state;
    const { shop } = this.props;

    const beginDate = jsDateToDateString(begin);
    const endDate = jsDateToDateString(end);
    const numberOfDays = Moment(endDate).diff(beginDate, "day") + 1;
    //const duration = numberOfDays * 24 * 60;

    this.setState({
      loading: {
        open: true,
        description: "Verlof toevoegen..."
      }
    });

    let beginAtMinute = 0;
    let durationInMinutes = 1440;
    if (this.state.dayType === "timeFrame") {
      beginAtMinute = this.state.beginMinutes;
      durationInMinutes = this.state.endMinutes - this.state.beginMinutes;
    }

    store
      .addHoliday(
        shop.id,
        shop.barberId,
        begin,
        numberOfDays,
        beginAtMinute,
        durationInMinutes
      )
      .then(
        () => {
          this.setState({
            loading: {
              open: false
            }
          });
          this.handleClose();
        },
        error => {
          console.warn(error);

          this.setState({
            loading: {
              open: false
            },
            error: {
              open: true,
              description: error
            }
          });
        }
      );
  }

  handleErrorClose = () => {
    this.setState({ error: { open: false } });
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    const { begin, end, dayType, beginMinutes, endMinutes } = this.state;
    const { classes, open, onClose, ...other } = this.props;

    const isValidDate = begin <= end;

    return (
      <div>
        <Loading
          open={this.state.loading.open}
          description={this.state.loading.description}
        />
        <Error
          open={this.state.error.open}
          description={this.state.error.description}
          onClose={this.handleErrorClose}
        />
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={this.handleClose}
          disableBackdropClick={true}
          aria-labelledby="Laden..."
        >
          <DialogTitle>
            <Typography className={classes.title}>Verlof toevoegen</Typography>
          </DialogTitle>
          <List>
            <form className={classes.picker} noValidate>
              <div className={classes.horizontal}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="nl-be">
                  <ListItem>
                    <DatePicker
                      color="secondary"
                      id="begin"
                      label="VAN"
                      value={jsDateToDateString(begin)}
                      className={classes.dateField}
                      onChange={this.handleBeginDateChange}
                      //onBlur={this.handleBeginDateBlur}
                      format="dd D MMMM YYYY"
                      autoOk={true}
                      showTodayButton={true}
                      okLabel="OK"
                      cancelLabel="Annuleren"
                      todayLabel="Vandaag"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: classes.resize
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <DatePicker
                      color="secondary"
                      id="end"
                      label="TOT EN MET"
                      value={jsDateToDateString(end)}
                      className={classes.dateField}
                      onChange={this.handleEndDateChange}
                      //onBlur={this.handleEndDateBlur}
                      format="dd D MMMM YYYY"
                      autoOk={true}
                      showTodayButton={true}
                      okLabel="OK"
                      cancelLabel="Annuleren"
                      todayLabel="Vandaag"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          input: classes.resize
                        }
                      }}
                    />
                  </ListItem>
                </MuiPickersUtilsProvider>
              </div>
              <ListItem>
                <RadioGroup
                  aria-label="gender"
                  name="dayType"
                  className={classes.group}
                  value={dayType}
                  onChange={this.handleDayTypeChange}
                >
                  <FormControlLabel
                    value="fullDay"
                    control={<Radio color="primary" />}
                    label="Volledige dag"
                  />
                  <FormControlLabel
                    value="timeFrame"
                    control={<Radio color="primary" />}
                    label="Begin- en einduur"
                  />
                </RadioGroup>
              </ListItem>
              {dayType === "timeFrame" && (
                <div className={classes.horizontal}>
                  <ListItem>
                    <TextField
                      label="Van"
                      type="time"
                      value={minutesToTime(beginMinutes)}
                      className={classes.textField}
                      onChange={this.handleBeginHourChange}
                      onBlur={this.handleBeginHourBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      label="Tot en met"
                      type="time"
                      value={minutesToTime(endMinutes)}
                      className={classes.textField}
                      onChange={this.handleEndHourChange}
                      onBlur={this.handleEndHourBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                    />
                  </ListItem>
                </div>
              )}
            </form>

            {isValidDate && (
              <ListItem
                button
                className={classes.button}
                onClick={this.handleAddHoliday}
              >
                <CheckIcon color="primary" className={classes.leftIcon} />
                <ListItemText primary="Bevestigen" />
              </ListItem>
            )}
            <ListItem
              button
              className={classes.button}
              onClick={this.handleClose}
            >
              <CloseIcon color="secondary" className={classes.leftIcon} />
              <ListItemText primary="Annuleren" />
            </ListItem>
          </List>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AddHoliday));
