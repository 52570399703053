import React, { Component } from "react";

import WeekHeader from "../components/WeekHeader";
import DayFooter from "../components/DayFooter";
import Week from "../components/Week";

import * as modals from "../constants/modals";

import { withStyles } from "@material-ui/core/styles";

import Loading from "../modals/Loading";
import Confirm from "../modals/Confirm";

import ControlShowAvailability from "../components/ControlShowAvailability";
import ControlSpacing from "../components/ControlSpacing";

import { store } from "../firebase";
import {
  getAvailableSlots,
  mergeSlots,
  getBeginAndEndHourFromDayHours,
  jsDateToDateString,
  findInArrayByPropVal
} from "../utils";

import withCrewAuthorization from "../components/withCrewAuthorization";

import Moment from "moment";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    zIndex: 100,
    position: "fixed",
    bottom: 20,
    left: "50%",
    transform: "translate(-50%, 0)",
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

class WeekSchedulePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      confirm: {
        open: false
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.refreshAvailability();
  }

  componentWillUnmount() {}

  refreshAvailability() {
    const { shop, requestAvailability } = this.props;

    const beginOfWeekDate = Moment(shop.date)
      .startOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift
    const endOfWeekDate = Moment(shop.date)
      .endOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift

    this.setState({
      beginOfWeekDate,
      endOfWeekDate
    });

    const beginOfWeekDateAsString = jsDateToDateString(beginOfWeekDate);
    const endOfWeekDateAsString = jsDateToDateString(endOfWeekDate);

    // Availability
    requestAvailability(beginOfWeekDateAsString, endOfWeekDateAsString);
  }

  handleDateChange(newDate) {
    const { shop } = this.props;

    shop
      .update({ date: newDate })
      .then(() => {
        this.refreshAvailability();
      })
      .catch(error => {
        console.warn(error);
      });
  }

  handleConfirmClick = () => {
    const slot = this.state.slotToRemove;

    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {
        this.handleSlotRemove(slot);
      }
    );
  };

  handleCancelClick = () => {
    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {}
    );
  };

  handleSlotClick = slot => {
    switch (slot.type) {
      case "available":
        this.handleBookSlotOpen(slot);
        break;
      case "appointment":
        this.handleSlotOpen(slot);
        break;
    }
  };

  handleSlotOpen = slot => {
    const { openModal } = this.props;

    openModal(modals.EDIT_SLOT, {
      id: slot.id
    });
  };

  handleBookSlotOpen = slot => {
    const { shop, services, openModal } = this.props;
    const { date, begin } = slot;
    let { duration } = slot;

    if (duration === undefined && shop) {
      const service = findInArrayByPropVal(services, "id", shop.serviceId);
      duration = service.duration;
    }

    openModal(modals.BOOK_SLOT, {
      date,
      begin,
      duration
    });
  };

  handleConfirmSlotRemove = slot => {
    this.setState({
      slotToRemove: slot,
      confirm: {
        open: true,
        description: "Ben je zeker dat je deze afspraak wil verwijderen?"
      }
    });
  };

  handleSlotRemove = slot => {
    this.setState({
      loading: {
        open: true,
        description: "Versturen van je aanvraag..."
      }
    });

    store
      .removeBooking(
        this.props.shop.id,
        this.props.auth.user.uid,
        this.props.auth.isCrew,
        slot.id,
        taskId => {
          console.log(taskId);
          let loading = this.state.loading;
          loading.description = "Verifiëren en verwerken van je aanvraag...";
          this.setState({
            loading: loading
          });
        }
      )
      .then(() => {
        console.log("Document successfully updated!");
        this.setState({ loading: { open: false } });
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        this.setState({ loading: { open: false } });
      });
  };

  handleShowAvailabilityChange = showAvailability => {
    const { shop } = this.props;

    const settings = shop.settings;
    settings.showAvailableSlots = showAvailability;
    shop.update({ settings });
  };

  handleSpacingChange = spacing => {
    const { shop } = this.props;

    const parsedSpacing = parseInt(spacing);
    if (!isNaN(parsedSpacing) && parsedSpacing > 0) {
      const settings = shop.settings;
      settings.spacingInMinutes = parsedSpacing;
      shop.update({ settings });
    }
  };

  render() {
    const {
      classes,
      shop,
      info,
      barbers,
      services,
      availability,
      slots,
      openModal,
      toggleDrawer
    } = this.props;
    const { beginOfWeekDate, endOfWeekDate } = this.state;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);
    const service = findInArrayByPropVal(services, "id", shop.serviceId);

    let days = [];
    let beginHour = -1,
      endHour = -2;

    if (barber && availability && slots) {
      let date = beginOfWeekDate;

      while (date < endOfWeekDate) {
        const dayOfWeek = date.getDay();

        if (barber.bookingHours && barber.bookingHours[dayOfWeek]) {
          const bookingHours = barber.bookingHours[dayOfWeek];

          const dateAsString = jsDateToDateString(date);
          let daySlots = slots[dateAsString] ? { ...slots[dateAsString] } : {};

          if (shop.settings.showAvailableSlots) {
            const dayAvailability = availability[dateAsString]
              ? { ...availability[dateAsString] }
              : {};
            const availableSlots = getAvailableSlots(
              dayAvailability,
              bookingHours,
              service ? service.duration : 20,
              shop.settings.spacingInMinutes
            );
            daySlots = mergeSlots(daySlots, availableSlots);
          }

          /*const hours = getBeginAndEndHourFromDayHours(bookingHours);

          if(minHour === undefined || hours.beginHour < minHour) {
            minHour = hours.beginHour;
          }
          if(maxHour === undefined || hours.endHour > maxHour) {
            maxHour = hours.endHour;
          }*/
          const hours = Object.keys(daySlots);
          if (hours.length > 0) {
            const dayBeginHour = Math.min(...hours);
            const dayEndHour = Math.max(...hours);

            if (beginHour < 0 || dayBeginHour < beginHour) {
              beginHour = dayBeginHour;
            }
            if (endHour < 0 || dayEndHour > endHour) {
              endHour = dayEndHour;
            }
          }

          days.push({
            date,
            bookingHours,
            slots: daySlots
          });
        }

        date = Moment(date)
          .add(1, "day")
          .toDate();
      }
    }

    const leftButtons = [
      <ControlShowAvailability
        showAvailability={shop.settings.showAvailableSlots}
        handleShowAvailabilityChange={this.handleShowAvailabilityChange}
      />
    ];
    const rightButtons = [];
    if (shop.settings.showAvailableSlots) {
      rightButtons.push(
        <ControlSpacing
          spacing={shop.settings.spacingInMinutes}
          handleSpacingChange={this.handleSpacingChange}
          showIcon={true}
        />
      );
    }

    return (
      <div className={classes.root}>
        <Loading
          open={this.state.loading.open}
          description={this.state.loading.description}
        />
        <Confirm
          open={this.state.confirm.open}
          description={this.state.confirm.description}
          handleConfirm={this.handleConfirmClick}
          handleCancel={this.handleCancelClick}
        />
        <WeekHeader
          shop={shop}
          onDateChanged={this.handleDateChange}
          canGoBack={true}
          canGoForward={true}
          className="AppBar"
        />
        <Week
          date={shop.date}
          days={days}
          beginHour={beginHour}
          endHour={endHour}
          services={services}
          handleSlotClick={this.handleSlotClick}
          handleBookSlotClick={this.handleBookSlotOpen}
          handleRemoveSlotClick={this.handleConfirmSlotRemove}
        />
        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          services={services}
          hideCustomer={true}
          openModal={openModal}
          leftButtons={leftButtons}
          rightButtons={rightButtons}
          toggleDrawer={toggleDrawer}
        />
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(WeekSchedulePage));
