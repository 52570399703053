import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { store } from '../firebase';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import Moment from 'react-moment';

import { minutesToTime, timeToMinutes } from '../utils';

const styles = theme => ({
  title: {
    fontFamily: 'Lumberjack Inline Rough',
    fontSize: 20
  },
  textField: {
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SelectSlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
        begin: 600
    };

    this.handleBeginHourChange = this.handleBeginHourChange.bind(this);
    this.handleSlotConfirm = this.handleSlotConfirm.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  onEntering() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  handleBeginHourChange(event) {
    if(event.target.value) {
      try {
        const begin = timeToMinutes(event.target.value);
        this.setState({ begin });
      } catch(error) {
        console.log(error);
      }
    }
  }

  handleSlotConfirm = () => {
    const { shop, onConfirm } = this.props;
    const { begin } = this.state;

    if(onConfirm) {
      const slot = {
        date: shop.date,
        begin: begin
      };

      this.handleClose();

      onConfirm(slot);
    }
  };


  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, open, onClose, shop, ...other } = this.props;
    const { begin } = this.state;

    return (
      <Dialog fullScreen TransitionComponent={ Transition } onEntering={ this.onEntering } open={ open } onClose={ this.handleClose } aria-labelledby="Afspraak kiezen" shop={ shop }>
        <DialogTitle>
          <Typography className={ classes.title } variant="caption" id="simple-modal-description">
            Afspraak kiezen
          </Typography>
        </DialogTitle>
        <List>
            <ListItem>
                <ListItemText>
                <Typography className={ classes.text } variant="subheading" id="simple-modal-description">
                    <span>Maak je afspraak op </span>
                    <strong>
                    <Moment locale="nl-be" format="dddd D MMMM">
                    { shop.date }
                    </Moment>
                    </strong>
                </Typography>
                </ListItemText>
            </ListItem>
            <ListItem>
                <TextField
                    label="Uur"
                    type="time"
                    value={ minutesToTime(begin) }
                    className={ classes.textField }
                    onChange={ this.handleBeginHourChange }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </ListItem>
            <ListItem button className={classes.button} onClick={ this.handleSlotConfirm }>
                <CheckIcon color="primary" className={classes.leftIcon} />
                <ListItemText primary="Bevestigen" />
            </ListItem>
            <ListItem button className={classes.button} onClick={ this.handleClose }>
                <CloseIcon color="secondary" className={classes.leftIcon} />
                <ListItemText primary="Annuleren" />
            </ListItem>
        </List>
      </Dialog>
    );
  }
}

SelectSlot.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default withMobileDialog()(withStyles(styles)(SelectSlot));
