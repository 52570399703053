import React, { PureComponent } from "react";

import ReactMoment from "react-moment";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckIcon from "@material-ui/icons/Check";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import { withStyles } from "@material-ui/core/styles";

import { minutesToTime } from "../utils";

import { store } from "../firebase";

const styles = theme => ({
  text: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  service: {
    textTransform: "lowercase"
  }
});

class Notification extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleItemClick(event) {
    const { onClick, id, type, timestamp, data } = this.props;

    if (onClick) {
      onClick(id, type, timestamp, data);
    }
  }

  handleButtonClick(event) {
    event.stopPropagation();

    const { onCheckClick, id, type, timestamp, data } = this.props;

    if (onCheckClick) {
      onCheckClick(id, type, timestamp, data);
    }
  }

  render() {
    const { type, timestamp, data, classes } = this.props;

    return (
      <MenuItem
        style={{ height: "auto", whiteSpace: "normal" }}
        onClick={this.handleItemClick}
      >
        <ListItemIcon>
          {type === "bookingAdded" ? (
            <PlaylistAddIcon />
          ) : type === "bookingRemoved" ? (
            <HighlightOffIcon />
          ) : (
            <HelpOutlineIcon />
          )}
        </ListItemIcon>
        <div className={classes.text}>
          {data && data.customer && (
            <Typography variant="inherit">
              {type === "bookingAdded" ? (
                <span variant="inherit">
                  <strong>{data.customer.name}</strong> maakte een afspraak voor
                </span>
              ) : type === "bookingRemoved" ? (
                <span variant="inherit">
                  <strong>{data.customer.name}</strong> verwijderde een afspraak
                  voor
                </span>
              ) : (
                <span variant="inherit">onbekend</span>
              )}
              {data && data.service && data.slot && (
                <span variant="inherit">
                  {" "}
                  <strong className={classes.service}>
                    {data.service.name}
                  </strong>{" "}
                  op{" "}
                  <strong>
                    <ReactMoment format="D MMMM">{data.slot.date}</ReactMoment>
                  </strong>{" "}
                  om <strong>{minutesToTime(data.slot.begin)}</strong>
                </span>
              )}
            </Typography>
          )}
          <Typography variant="caption">
            {<ReactMoment fromNow>{timestamp}</ReactMoment>}
          </Typography>
        </div>
        <IconButton color="primary" onClick={this.handleButtonClick}>
          <CheckIcon />
        </IconButton>
      </MenuItem>
    );
  }
}

export default withStyles(styles)(Notification);
