import React, { Component } from "react";

import Moment from "moment";

import DayHeader from "../components/DayHeader";
import Day from "../components/Day";
import DayFooter from "../components/DayFooter";

import DaysPreview from "../components/DaysPreview";

import Fab from "@material-ui/core/Fab";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import * as modals from "../constants/modals";

import { withStyles } from "@material-ui/core/styles";

import Loading from "../modals/Loading";
import Confirm from "../modals/Confirm";

import { store } from "../firebase";
import {
  getAvailableSlots,
  mergeSlots,
  jsDateToDateString,
  findInArrayByPropVal
} from "../utils";

import withCrewAuthorization from "../components/withCrewAuthorization";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  preview: {
    zIndex: 101,
    position: "sticky",
    top: 56,
    padding: "15px 0 0 0",
    fontFamily: "Lumberjack Inline Rough",
    lineHeight: "26px",
    textAlign: "center",
    backgroundColor: "white",
    color: "#666"
  },
  button: {
    zIndex: 100,
    position: "fixed",
    bottom: 20,
    left: "50%",
    transform: "translate(-50%, 0)",
    margin: theme.spacing.unit
  },
  day: {
    paddingBottom: 80
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  addContainer: {
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    zIndex: 100,
    right: 0,
    bottom: 68
  },
  addButton: {}
});

class SchedulePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      confirm: {
        open: false
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleDateChange(newDate) {
    const { shop } = this.props;

    shop
      .update({ date: newDate })
      .then(() => {})
      .catch(error => {
        console.warn(error);
      });
  }

  handleConfirmClick = () => {
    const slot = this.state.slotToRemove;

    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {
        this.handleSlotRemove(slot);
      }
    );
  };

  handleCancelClick = () => {
    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {}
    );
  };

  handleSlotClick = slot => {
    switch (slot.type) {
      case "available":
        //this.handleBookSlotOpen(slot);
        break;
      case "appointment":
        this.handleSlotOpen(slot);
        break;
    }
  };

  /*handleSlotOptionsClick = (slot) => {
    switch(slot.type) {
      case 'appointment':
        this.handleSlotRemove(slot);
        break;
    }
  };*/

  handleBookSlotOpen = slot => {
    const { shop, services, openModal } = this.props;
    const { date, begin } = slot;
    let { duration } = slot;

    if (duration === undefined && shop) {
      const service = findInArrayByPropVal(services, "id", shop.serviceId);
      duration = service.duration;
    }

    openModal(modals.BOOK_SLOT, {
      date,
      begin,
      duration
    });
  };

  handleSlotOpen = slot => {
    const { openModal } = this.props;

    openModal(modals.EDIT_SLOT, {
      id: slot.id
    });
  };

  handleConfirmSlotRemove = slot => {
    this.setState({
      slotToRemove: slot,
      confirm: {
        open: true,
        description: "Ben je zeker dat je deze afspraak wil verwijderen?"
      }
    });
  };

  handleSlotRemove = slot => {
    this.setState({
      loading: {
        open: true,
        description: "Versturen van je aanvraag..."
      }
    });

    store
      .removeBooking(
        this.props.shop.id,
        this.props.auth.user.uid,
        this.props.auth.isCrew,
        slot.id,
        taskId => {
          console.log(taskId);
          let loading = this.state.loading;
          loading.description = "Verifiëren en verwerken van je aanvraag...";
          this.setState({
            loading: loading
          });
        }
      )
      .then(() => {
        this.setState({ loading: { open: false } });
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        this.setState({ loading: { open: false } });
      });
  };

  handleSelectSlotOpen = () => {
    this.props.openModal(modals.SELECT_SLOT, {
      onConfirm: this.handleBookSlotOpen
    });
  };

  render() {
    const {
      classes,
      shop,
      info,
      barbers,
      services,
      availability,
      slots,
      openModal,
      openMenu,
      toggleDrawer,
      requestAvailability
    } = this.props;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);
    const service = findInArrayByPropVal(services, "id", shop.serviceId);

    const dateAsString = jsDateToDateString(shop.date);
    const availabilityForDate = availability
      ? availability[dateAsString]
        ? { ...availability[dateAsString] }
        : {}
      : undefined;
    const slotsForDate =
      slots && slots[dateAsString] ? { ...slots[dateAsString] } : {};

    let mergedSlots, beginHour, endHour; //, availabilityPerHour;

    if (barber && barber.bookingHours && availabilityForDate) {
      let day = shop.date.getDay();
      let bookingHours = barber.bookingHours[day];

      const availableSlots = getAvailableSlots(
        availabilityForDate,
        bookingHours,
        service ? service.duration : 20
      );

      mergedSlots = mergeSlots(slotsForDate, availableSlots);

      const hours = Object.keys(mergedSlots);
      if (hours.length > 0) {
        beginHour = Math.min(...hours);
        endHour = Math.max(...hours);
      } else {
        beginHour = 0;
        endHour = -1;
      }
    }

    // Preview

    let daysPreviewBegin = Moment(shop.date)
      .add(-3, "days")
      .toDate();
    let daysPreviewEnd = Moment(shop.date)
      .add(3, "days")
      .toDate();

    return (
      <div className={classes.root}>
        <Loading
          open={this.state.loading.open}
          description={this.state.loading.description}
        />
        <Confirm
          open={this.state.confirm.open}
          description={this.state.confirm.description}
          handleConfirm={this.handleConfirmClick}
          handleCancel={this.handleCancelClick}
        />
        <DayHeader
          shop={shop}
          barbers={barbers}
          services={services}
          availability={availability}
          onDateChanged={this.handleDateChange}
          requestAvailability={requestAvailability}
          canGoBack={true}
          canGoForward={true}
          hasMonthPopup={true}
          className="AppBar"
        />
        <div className={classes.preview}>
          <DaysPreview
            shop={shop}
            barbers={barbers}
            services={services}
            availability={availability}
            beginDate={daysPreviewBegin}
            endDate={daysPreviewEnd}
            selectedDate={shop.date}
            onDateClick={newDate => {
              this.handleDateChange(newDate);
            }}
          />
        </div>
        <div className={classes.day}>
          <Day
            date={shop.date}
            beginHour={beginHour}
            endHour={endHour}
            slots={mergedSlots}
            services={services}
            handleSlotClick={this.handleSlotClick}
            handleBookSlotClick={this.handleBookSlotOpen}
            handleRemoveSlotClick={this.handleConfirmSlotRemove}
            stickyTop={100}
          />
        </div>
        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          services={services}
          hideCustomer={true}
          openModal={openModal}
          openMenu={openMenu}
          toggleDrawer={toggleDrawer}
        />
        <div className={`AppFixedElement ${classes.addContainer}`}>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="slot"
            className={classes.add}
            onClick={this.handleSelectSlotOpen}
          >
            <AccessTimeIcon className={classes.extendedIcon} />
            Zelf een tijdstip kiezen
          </Fab>
        </div>
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(SchedulePage));
