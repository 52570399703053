/*<a id="atecllink" rel="external" data-role="none" style="display: none;" target="_self" href="https://www.addevent.com/create/?service=appleical&amp;dstart=11%2F07%2F2018%2009%3A00%20AM&amp;dend=11%2F07%2F2018%2011%3A00%20AM&amp;dtime=America%2FNew_York&amp;dsum=Title%20of%20the%20event&amp;ddesc=%3Cp%20class%3D%22atc_node%22%3EAddEvent%20example%20text.%3C%2Fp%3E%3Cp%20class%3D%22atc_node%22%3EThis%20is%20the%20text%20entered%20in%20the%20event%20description%20field.%20The%20text%20can%20be%20plain%20text%20or%20simple%20formatted%20HTML.%20The%20AddEvent%20rendering%20engine%20converts%20and%20formats%20text%20beautifully%20and%20adjusts%20the%20formatting%20to%20the%20selected%20calendar%20media.%3C%2Fp%3E%3Cp%20class%3D%22atc_node%22%3E%3Ca%20href%3D%22http%3A%2F%2Fexample.com%22%20class%3D%22atc_node%22%3EExample%20of%20a%20link%3C%2Fa%3E%3C%2Fp%3E%3Cul%20class%3D%22atc_node%22%3E%3Cli%20class%3D%22atc_node%22%3EMany%20of%20our%20users%20use%20HTML%20to%20add%20lists%20like%20this%20one%3C%2Fli%3E%3Cli%20class%3D%22atc_node%22%3EHTML%20lists%20are%20converted%20into%20bullet%20lists%3C%2Fli%3E%3C%2Ful%3E&amp;dloca=Broadway%2C%20New%20York&amp;reference=https://www.addevent.com">{addeventatc-ghost-link}</a> */


import React, { Component } from 'react';

import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { timeToMinutes, minutesToTime } from '../utils';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    addBooking: {
      justifyContent: 'space-between',
      /*paddingTop: 0,
      paddingBottom: 0*/
    },
    picker: {
        
    },
    hour: {
        display: 'flex',
        alignItems: 'center',
    },
    resize: {
        marginLeft: 10,
        marginTop: 2,
        fontFamily: 'Lumberjack Inline Rough',
        fontSize: 16,
        textTransform: 'uppercase',
        color: '#f50057'
    },
    minutesButton: {
        /*width: 20,
        height: 20,
        minHeight: 20,*/
        marginRight: 10
    },
    minutesButtonIcon: {
        /*width: 15,
        height: 15*/
    }
});

class AddBookingForHour extends Component {
  constructor(props) {
    super(props);

    this.state = {
        begin: props.begin ? props.begin : 600,
    };

    this.handleBeginChange = this.handleBeginChange.bind(this);
    this.handleRemoveMinutes = this.handleRemoveMinutes.bind(this);
    this.handleAddMinutes = this.handleAddMinutes.bind(this);
    this.handleBookSlotClick = this.handleBookSlotClick.bind(this);
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {

  }

  handleBeginChange(event) {
    if(event.target.value) {
      try {
        const begin = timeToMinutes(event.target.value);

        this.setState({ begin });
      } catch(error) {
        console.log(error);
      }
    }
  }

  handleRemoveMinutes() {
    let { begin } = this.state;

    begin -= 5;

    if(begin < this.props.begin) {
        begin = this.props.begin + 55;
    }

    this.setState({ begin });
  }

  handleAddMinutes() {
    let { begin } = this.state;

    begin += 5;

    if(begin >= this.props.begin + 60) {
        begin = this.props.begin;
    }

    this.setState({ begin });
  }

  handleBookSlotClick() {
    const { handleBookSlotClick, barberId, date } = this.props;
    const { begin } = this.state;

    handleBookSlotClick({
        barberId,
        date,
        begin
    });
  }

  render() {
    const { classes } = this.props;
    const { begin } = this.state;

    return (
        <form className={ classes.picker } noValidate>
            <ListItem className={ classes.addBooking }>
                <div className={ classes.hour }>
                    <Fab variant="extended" size="small" color="secondary" aria-label="-5 minuten" className={ classes.minutesButton } onClick={ this.handleRemoveMinutes }>
                        <RemoveIcon className={ classes.minutesButtonIcon } />
                    </Fab>
                    <Fab variant="extended" size="small" color="primary" aria-label="+5 minuten" className={ classes.minutesButton } onClick={ this.handleAddMinutes }>
                        <AddIcon className={ classes.minutesButtonIcon } />
                    </Fab>
                    <TextField
                        color="secondary"
                        id="begin"
                        type="time"
                        value={ minutesToTime(begin) }
                        onChange={ this.handleBeginChange }
                        label=""
                        InputProps={{
                            step: 300, // 5 min
                            disableUnderline: true,
                            classes: {
                                input: classes.resize,
                            }
                        }}
                    />
                </div>
                <Button color="secondary" className={ classes.button } onClick={ this.handleBookSlotClick }>
                    Boek nu
                </Button>
            </ListItem>
        </form>
    );
  }
}

export default withStyles(styles)(AddBookingForHour);