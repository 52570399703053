import React, { PureComponent } from "react";

import Moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import DayPreview from "./DayPreview";

import {
  findInArrayByPropVal,
  jsDateToDateString,
  getBeginAndEndHourFromDayHours
} from "../utils";

const styles = theme => ({
  days: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  day: {
    margin: "0 3px 0 3px"
  }
});

class DaysPreview extends PureComponent {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const {
      shop,
      barbers,
      services,
      availability,
      beginDate,
      endDate,
      onDateClick,
      selectedDate,
      isDayEnabled,
      classes
    } = this.props;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);
    const service = findInArrayByPropVal(services, "id", shop.serviceId);

    let days = [],
      beginHour = 24,
      endHour = 0;

    if (
      barber &&
      barber.bookingHours &&
      availability &&
      beginDate &&
      endDate &&
      beginDate <= endDate
    ) {
      // Account for Daylight Savings Time, GMT +2
      const formattedBeginDate = Moment(beginDate)
        .startOf("day")
        .add(10, "hours")
        .toDate();
      const formattedEndDate = Moment(endDate)
        .startOf("day")
        .add(14, "hours")
        .toDate();

      let date = formattedBeginDate;

      while (date <= formattedEndDate) {
        days.push({
          date,
          bookingHours: barber.bookingHours[date.getDay()],
          availability: availability[jsDateToDateString(date)],
          isSelected:
            selectedDate &&
            jsDateToDateString(date) === jsDateToDateString(selectedDate)
        });

        date = Moment(date)
          .add(1, "days")
          .toDate();
      }

      for (const day in barber.bookingHours) {
        const bookingHours = getBeginAndEndHourFromDayHours(
          barber.bookingHours[day]
        );
        if (bookingHours.beginHour < beginHour) {
          beginHour = bookingHours.beginHour;
        }
        if (bookingHours.endHour > endHour) {
          endHour = bookingHours.endHour;
        }
      }
    }

    const duration = service && service.duration ? service.duration : 30;

    return (
      <div className={classes.days}>
        {availability &&
          days.map(day => (
            <div key={day.date} className={classes.day}>
              <DayPreview
                date={day.date}
                beginHour={beginHour}
                endHour={endHour}
                bookingHours={day.bookingHours}
                availability={day.availability}
                duration={duration}
                isSelected={day.isSelected}
                isEnabled={isDayEnabled ? isDayEnabled(day.date) : true}
                onDateClick={onDateClick}
              />
            </div>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(DaysPreview);
