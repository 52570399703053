import React, { Component } from "react";

import { toast } from "react-toastify";

import * as modals from "../constants/modals";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import CrewHeader from "../components/CrewHeader";

import DayFooter from "../components/DayFooter";

import DayHours from "../components/DayHours";

import Loading from "../modals/Loading";

import { withStyles } from "@material-ui/core/styles";

import ReactMoment from "react-moment";

import { store } from "../firebase";

import {
  minutesToTime,
  jsDateToDateString,
  findInArrayByPropVal
} from "../utils";

import withCrewAuthorization from "../components/withCrewAuthorization";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  sectionHeader: {
    zIndex: 101,
    top: "56px",
    [theme.breakpoints.up("600")]: {
      top: "64px"
    },
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.paper
  },
  dayHoursContainer: {
    display: "flex",
    flexDirection: "row"
    //flexWrap: "wrap"
  },
  dayHours: {
    width: "100%"
  },
  shop: {
    backgroundColor: theme.palette.background.paper
  },
  textField: {
    /*left: '50%',
    transform: 'translate(-50%, 0)'*/
    width: "100%;"
  },
  month: {
    display: "inline-block",
    width: 100,
    fontSize: 14,
    textTransform: "uppercase",
    color: "#aaa"
  },
  time: {
    paddingLeft: 100,
    fontSize: 12,
    fontWeight: "bold",
    textTransform: "uppercase"
  }
});

class AvailabilityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      dayHoursEditing: false
    };

    this.handleStartEditingDayHours = this.handleStartEditingDayHours.bind(this);
    this.handleStopEditingDayHours = this.handleStopEditingDayHours.bind(this);
    this.handleSaveOpeningHours = this.handleSaveOpeningHours.bind(this);
    this.handleSaveBookingHours = this.handleSaveBookingHours.bind(this);
    this.handleAddHolidayClick = this.handleAddHolidayClick.bind(this);
    this.handleRemoveHolidayClick = this.handleRemoveHolidayClick.bind(this);
  }

  componentDidMount() {
    const { shop } = this.props;

    if (shop.barberId) {
      const todayDate = jsDateToDateString(new Date());
      this.unsubscribeHolidays = store.onGetHolidaysFromDate(
        shop.id,
        shop.barberId,
        todayDate,
        holidays => {
          this.setState({ holidays: holidays });
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeHolidays) {
      this.unsubscribeHolidays();
      this.unsubscribeHolidays = undefined;
    }
  }

  handleStartEditingDayHours() {
    this.setState({ dayHoursEditing: true });
  }

  handleStopEditingDayHours() {
    this.setState({ dayHoursEditing: false });
  }

  handleSaveOpeningHours(openingHours) {
    const { shop } = this.props;

    if (shop.barberId && openingHours) {
      const toastId = toast("Openingsuren bewaren...", { autoClose: false });

      store
        .setBarberOpeningHours(shop.id, shop.barberId, openingHours)
        .then(() => {
          toast.update(toastId, {
            render: "Openingsuren bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });
        })
        .catch(error => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    }
  }

  handleSaveBookingHours(bookingHours) {
    const { shop } = this.props;

    if (shop.barberId && bookingHours) {
      const toastId = toast("Boekingsuren bewaren...", { autoClose: false });

      store
        .setBarberBookingHours(shop.id, shop.barberId, bookingHours)
        .then(() => {
          toast.update(toastId, {
            render: "Boekingsuren bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });
        })
        .catch(error => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    }
  }

  handleAddHolidayClick() {
    const { openModal } = this.props;

    openModal(modals.ADD_HOLIDAY);
  }

  handleRemoveHolidayClick(event, data) {
    this.setState({
      loading: {
        open: true,
        description: "Verlof verwijderen..."
      }
    });

    store.removeAvailability(this.props.shop.id, data.id).then(
      () => {
        this.setState({
          loading: {
            open: false
          }
        });
      },
      error => {
        this.setState({
          loading: {
            open: false
          }
        });
        console.warn(error);
      }
    );
  }

  render() {
    const {
      classes,
      auth,
      shop,
      info,
      barbers,
      openModal,
      toggleDrawer
    } = this.props;
    const { dayHoursEditing, holidays } = this.state;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);

    return (
      <div className={classes.root}>
        <Loading
          open={this.state.loading.open}
          description={this.state.loading.description}
        />
        <CrewHeader title="Beschikbaarheid" toggleDrawer={toggleDrawer} />
        <List className={classes.shop}>
          {barber && (
            <div className={classes.dayHoursContainer}>
              <div className={classes.dayHours}>
                <DayHours
                  title="Openingsuren"
                  description="Deze uren verschijnen op je publieke welkomstpagina."
                  canBeEdited={!dayHoursEditing}
                  onStartEditing={this.handleStartEditingDayHours}
                  onStopEditing={this.handleStopEditingDayHours}
                  dayHours={barber.openingHours}
                  saveChanges={this.handleSaveOpeningHours}
                />
              </div>
              <div className={classes.dayHours}>
                <DayHours
                  title="Boekingsuren"
                  description="Op deze uren kunnen klanten afspraken maken."
                  canBeEdited={!dayHoursEditing}
                  onStartEditing={this.handleStartEditingDayHours}
                  onStopEditing={this.handleStopEditingDayHours}
                  dayHours={barber.bookingHours}
                  saveChanges={this.handleSaveBookingHours}
                />
              </div>
            </div>
          )}
          <Divider />
          <ListSubheader className={classes.sectionHeader}>
            Verlof
            <ListItemSecondaryAction>
              <IconButton onClick={this.handleAddHolidayClick}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListSubheader>
          {holidays &&
            holidays.map(holiday => (
              <ListItem key={holiday.id}>
                <ListItemText>
                  <ReactMoment
                    locale="nl-be"
                    format="MMMM"
                    className={classes.month}
                  >
                    {holiday.date}
                  </ReactMoment>
                  <ReactMoment locale="nl-be" format="dd D/MM/YYYY">
                    {holiday.date}
                  </ReactMoment>
                  <div className={classes.time}>
                    {holiday.duration < 1440 ? (
                      <div>
                        {minutesToTime(holiday.begin)} -{" "}
                        {minutesToTime(holiday.begin + holiday.duration)}
                      </div>
                    ) : (
                      <div>Volledige dag</div>
                    )}
                  </div>
                </ListItemText>
                <ListItemIcon
                  onClick={event =>
                    this.handleRemoveHolidayClick(event, holiday)
                  }
                >
                  <DeleteIcon />
                </ListItemIcon>
              </ListItem>
            ))}
        </List>
        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          hideService={true}
          hideCustomer={true}
          hideCrew={true}
          openModal={openModal}
        />
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(AvailabilityPage));
