import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

import config from "../env";

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

const db = firebase.database();
const auth = firebase.auth();
auth.useDeviceLanguage(); //.languageCode = 'nl';

const store = firebase.firestore();
const settings = {
  /* timestampsInSnapshots: true */
};
store.settings(settings);

const recaptcha = firebase.auth.RecaptchaVerifier;

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const deleteField = firebase.firestore.FieldValue.delete;

const storage = firebase.storage();

export { db, store, auth, recaptcha, serverTimestamp, deleteField, storage };
