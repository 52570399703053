import React, { Component } from 'react';

import AuthContext from '../context/auth';

import ReactMoment from 'react-moment';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import UserHeader from '../components/UserHeader';
import UserFooter from '../components/UserFooter';

import List from '@material-ui/core/List';

import { withStyles } from '@material-ui/core/styles';

import { findInArrayByPropVal } from "../utils";

import withCustomersOnly from '../components/withCustomersOnly';

import { store } from '../firebase';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  list: {
    minHeight: 'calc(100vh - 122px)',
    backgroundColor: theme.palette.background.paper
  }
});


class UserReservationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { shop, auth } = this.props;
    const { reservations } = this.state;

    if(shop.id) {
      if(auth.customer === undefined || auth.customer.id === undefined) {
        if(this.unsubscribeCustomerReservations) {
          this.unsubscribeCustomerReservations();
          this.unsubscribeCustomerReservations = undefined;
          this.setState({ reservations: [] });
        }
      } else if(reservations === undefined) {
        this.unsubscribeCustomerReservations = store.onGetCustomerReservations(shop.id, auth.customer.id, (customerReservations) => {
          this.setState({ reservations: customerReservations });
        });
      }
    }
  }

  componentWillUnmount() {
    if(this.unsubscribeCustomerReservations) {
      this.unsubscribeCustomerReservations();
      this.unsubscribeCustomerReservations = undefined;
    }
  }

  componentDidUpdate() {

  }

  findProduct(id) {
    const { products } = this.props;

    return findInArrayByPropVal(products, "id", id);
  }

  render() {
    const { classes, products } = this.props;
    const { reservations } = this.state;

    return (
      <div className={ classes.root }>
        <UserHeader title="Mijn reservaties" />
        <List className={ classes.list }>
          { reservations && reservations.length > 0 ? 
              reservations.map((reservation, reservationIndex) => (
              <div key={reservation.id} className={classes.reservations}>
                  <ListItem>
                    {
                      reservation.collectOnDate &&
                        <ReactMoment locale="nl-be" format="dddd DD/MM/YYYY">
                          { reservation.collectOnDate }
                        </ReactMoment>
                    }
                    { reservation.products && reservation.products.length > 0 ? 
                        <List>
                          {
                            reservation.products.map((product, productIndex) => (
                              <ListItem key={ product.id }>
                                <ListItemText>
                                  { this.findProduct(product.id).name }
                                </ListItemText>
                                <ListItemText>
                                  { product.amount }
                                </ListItemText>
                              </ListItem>
                            ))
                          }
                        </List> :
                        <ListItemText>Geen producten gevonden</ListItemText>
                    }
                  </ListItem>
              </div>
              )) :
              <ListItem>
                  <ListItemText>Geen reservaties gevonden</ListItemText>
              </ListItem>
          }
        </List>
        <UserFooter />
      </div>
    );
  }
}

export default withCustomersOnly()(withStyles(styles)(
  props => (
    <AuthContext.Consumer>
      { 
        auth => <UserReservationsPage { ...props } auth={ auth } />
      }
    </AuthContext.Consumer>
  )
));