import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";

import { toast } from "react-toastify";

import AuthContext from "../context/auth";

import { firebase } from "../firebase";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  header: {
    boxShadow: "none",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  title: {
    flexGrow: 1,
    fontFamily: "calligraffitiregular", // "shorelines_script_boldregular" 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: "bold",
    color: "black"
  },
  textField: {
    /*left: '50%',
        transform: 'translate(-50%, 0)'*/
    width: "100%;"
  },
  formButton: {
    display: "block",
    border: "none",
    width: "100%",
    padding: 0,
    background: "none"
  }
});

class SignInPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      validEmail: false,
      validPassword: false
    };
  }

  handleChange = name => event => {
    let validEmail = this.state.validEmail;
    let validPassword = this.state.validPassword;

    let val = event.target.value;

    if (name === "email") {
      validEmail = val && val !== "";
    } else if (name === "password") {
      validPassword = val && val !== "";
    }

    this.setState(
      {
        [name]: val,
        validEmail: validEmail,
        validPassword: validPassword
      },
      () => {}
    );
  };

  handleSignIn = event => {
    event.preventDefault();

    const toastId = toast("Aanmelden...", {
      autoClose: false
    });

    firebase.auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((test) => {
        console.info(test);
        toast.update(toastId, {
          render: "Welkom terug",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      });
    return true;
  };

  render() {
    const { classes } = this.props;
    const { validEmail, validPassword } = this.state;

    return (
      <AuthContext.Consumer>
        {auth =>
          auth && auth.user && auth.isCrew ? (
            <Redirect to={routes.CONTROLROOM} />
          ) : (
            <div className={classes.root}>
              <AppBar
                position="fixed"
                color="default"
                className={classes.header}
              >
                <Toolbar>
                  <Typography
                    variant="title"
                    color="inherit"
                    className={classes.title}
                  >
                    aanmelden
                  </Typography>
                </Toolbar>
              </AppBar>
              <List>
                <form onSubmit={this.handleSignIn}>
                  <ListItem>
                    <TextField
                      required
                      id="email"
                      label="E-mailadres"
                      className={classes.textField}
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      margin="normal"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      required
                      id="password"
                      label="Wachtwoord"
                      type="password"
                      className={classes.textField}
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      margin="normal"
                    />
                  </ListItem>
                  {validEmail && validPassword && (
                    <button
                      id="verify-button"
                      type="submit"
                      className={classes.formButton}
                    >
                      <ListItem button className={classes.button}>
                        <CheckIcon
                          color="primary"
                          className={classes.leftIcon}
                        />
                        <ListItemText primary="Aanmelden" />
                      </ListItem>
                    </button>
                  )}
                </form>
              </List>
            </div>
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(SignInPage);
