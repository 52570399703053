import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { WEEK_DAY_HOUR_HEIGHT } from '../constants/styles';

import ReactMoment from 'react-moment';

const styles = theme => ({
    hour: {
        display: 'block',
        position: 'sticky',
        top: 80,
        height: WEEK_DAY_HOUR_HEIGHT + 9,
        marginTop: -8,
        marginRight: 8,
        background: 'white',
        color: '#aaa'
    }
});

class WeekDayHours extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  hourToText(hour) {
    let text;

    if(hour < 10) {
        return `0${hour}:00`;
    } else {
        return `${hour}:00`;
    }
  }

  render() {
    const { classes, beginHour, endHour } = this.props;

    let hours = [];

    for(let hour = beginHour; hour <= endHour; hour++) {
        hours.push(
            <div key={ hour } className={ classes.hour }>
                { this.hourToText(hour) }
            </div>
        );
    }

    return (
      <div className={ classes.hours }>
        { hours }
      </div>
    );
  }
}

export default withStyles(styles)(WeekDayHours);
