import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import Zoom from '@material-ui/core/Zoom';

function Transition(props) {
  return <Zoom {...props} />;
}

const styles = theme => ({
  title: {
    fontFamily: 'Lumberjack Inline Rough',
    fontSize: 20
  },
  text: {

  },
  progress: {
      display: 'block',
      textAlign: 'center'
  }
});

class Error extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    if(this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { classes, onClose, description, ...other } = this.props;

    return (
      <Dialog TransitionComponent={ Transition } onClose={ this.handleClose } disableBackdropClick={ true } aria-labelledby="Laden..." {...other}>
        <DialogTitle>
          <Typography className={ classes.title }>
            Er ging iets fout...
          </Typography>
        </DialogTitle>
        <List>
          <ListItem>
            <ListItemText>
              <Typography className={ classes.text } variant="subheading" id="simple-modal-description">
                  { description }
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem button className={ classes.button } onClick={ this.handleClose }>
            <CloseIcon color="secondary" className={ classes.leftIcon } />
            <ListItemText primary="Sluiten" />
          </ListItem>
        </List>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Error);