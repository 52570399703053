import React from "react";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

import config from "../env";

const bugsnagClient = bugsnag({
  appVersion: process.env.REACT_APP_VERSION,
  ...config.bugsnag
});

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin("react");

const withBugsnag = Component => {
  class WithBugsnag extends React.Component {
    componentDidMount() {
      //bugsnagClient.notify(new Error("Test error"));
    }

    render() {
      return (
        <ErrorBoundary>
          <Component {...this.props} bugsnagClient={bugsnagClient} />
        </ErrorBoundary>
      );
    }
  }
  return WithBugsnag;
};

export default withBugsnag;
