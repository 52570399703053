import React, { Component } from "react";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide
} from "@material-ui/core";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { parseProductFields } from "../utils";

import ProductFields from "../components/ProductFields";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  }
});

class AddProduct extends Component {
  constructor(props) {
    super(props);

    this.state = { name: "", price: 0 };
  }

  componentDidMount() {}

  handleUpdate = (product) => {
    this.setState({ ...product });
  };

  handleAdd = () => {
    const { shop } = this.props;

    try {
      const toAdd = parseProductFields(this.state);

      const toastId = toast("Wijzigingen bewaren...", {
        autoClose: false
      });

      const { name, price } = toAdd;

      store
        .addProduct(shop.id, name, price)
        .then(() => {
          toast.update(toastId, {
            render: "Wijzigingen bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });

          this.handleClose();
        })
        .catch((error) => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    } catch (error) {
      console.warn(error);
      if (error) {
        toast.error(error.toString());
      }
    }
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    const { classes, open, onClose, product, ...other } = this.props;

    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle>
            <Typography className={classes.title}>
              Product toevoegen
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <ProductFields product={this.state} onUpdate={this.handleUpdate} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Annuleren
            </Button>
            <Button onClick={this.handleAdd} color="primary">
              Toevoegen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AddProduct));
