import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import TodayIcon from "@material-ui/icons/Today";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import StoreIcon from "@material-ui/icons/Store";
import ToysIcon from "@material-ui/icons/Toys";
import FaceIcon from "@material-ui/icons/Face";
import PeopleIcon from "@material-ui/icons/People";
import ExploreIcon from "@material-ui/icons/Explore";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { withStyles } from "@material-ui/core/styles";

import * as routes from "../constants/routes";
import * as urls from "../constants/urls";

import { auth } from "../firebase";

const styles = (theme) => ({
  logo: {
    width: "100%",
    height: 50,
    margin: "10px 0",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%",
    backgroundImage: 'url("/img/logo.png")'
  },
  list: {},
  link: {
    textDecoration: "none"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  hidden: {
    display: "none"
  }
});

class TemporaryDrawer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOpen = () => {
    this.props.onOpen();
  };
  handleClose = () => {
    this.props.onClose();
  };

  handleSignOut = () => {
    auth.doSignOut();
  };

  render() {
    const { classes, open, barbers } = this.props;

    const drawer = (
      <div
        tabIndex={0}
        role="button"
        onClick={this.handleClose}
        onKeyDown={this.handleClose}
      >
        <Link to={routes.CONTROLROOM} className={classes.link}>
          <div className={`${classes.logo} AppDrawerLogo`} />
        </Link>
        <div className={classes.list}>
          <List>
            <Link to={routes.CONTROLROOM} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Nu" className="AppDrawerText" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            {barbers && barbers.length > 1 && (
              <Link to={routes.SHOP_SCHEDULE} className={classes.link}>
                <ListItem button className={classes.button}>
                  <ListItemIcon className="AppDrawerIcon">
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText primary="Agenda" className="AppDrawerText" />
                </ListItem>
              </Link>
            )}
            <Link to={routes.SCHEDULE} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary="Mijn agenda" className="AppDrawerText" />
              </ListItem>
            </Link>
            <Link to={routes.WEEK_SCHEDULE} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Weekoverzicht"
                  className="AppDrawerText"
                />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link to={routes.CUSTOMERS} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Klanten" className="AppDrawerText" />
              </ListItem>
            </Link>
            <Link to={routes.AVAILABILITY} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Beschikbaarheid"
                  className="AppDrawerText"
                />
              </ListItem>
            </Link>
            <Link to={routes.SERVICES} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <ToysIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Behandelingen"
                  className="AppDrawerText"
                />
              </ListItem>
            </Link>
            <Link to={routes.PRODUCTS} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <ShoppingBasketIcon />
                </ListItemIcon>
                <ListItemText primary="Producten" className="AppDrawerText" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link to={routes.SHOP} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Mijn zaak" className="AppDrawerText" />
              </ListItem>
            </Link>
            <Link to={routes.EARNINGS} className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Inkomsten" className="AppDrawerText" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <a href={urls.FEEDBACK} target="_blank" className={classes.link}>
              <ListItem button className={classes.button}>
                <ListItemIcon className="AppDrawerIcon">
                  <FeedbackIcon />
                </ListItemIcon>
                <ListItemText primary="Feedback" className="AppDrawerText" />
              </ListItem>
            </a>
            <ListItem
              button
              className={classes.button}
              onClick={this.handleSignOut}
            >
              <ListItemIcon className={`${classes.hidden} AppDrawerIcon`}>
                <ExitToAppIcon color="secondary" />
              </ListItemIcon>
              <div className={classes.center}>
                <Button
                  onClick={this.handleSignOut}
                  color="secondary"
                  className="AppDrawerText"
                >
                  Afmelden
                </Button>
              </div>
            </ListItem>
          </List>
        </div>
      </div>
    );

    return (
      <div className="AppDrawer">
        <Hidden smUp implementation="css">
          <Drawer
            container={this.props.container}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={this.handleClose}
            classes={{
              paper: "AppDrawer"
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: "AppDrawer"
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TemporaryDrawer);

/*
<Link to={routes.RESERVATIONS} className={classes.link}>
  <ListItem button className={classes.button}>
    <ListItemIcon className="AppDrawerIcon">
      <ShopIcon />
    </ListItemIcon>
    <ListItemText primary="Reservaties" className="AppDrawerText" />
  </ListItem>
</Link>
*/
/*
<Link to={routes.LANDING} className={classes.link}>
  <ListItem button className={classes.button}>
    <ListItemIcon className="AppDrawerIcon">
      <ExploreIcon />
    </ListItemIcon>
    <ListItemText
      primary="Mijn webpagina"
      className="AppDrawerText"
    />
  </ListItem>
</Link>
*/
