import React, { Component } from "react";

import { Link } from "react-router-dom";

import DayHeader from "../components/DayHeader";
import Day from "../components/Day";
import DayFooter from "../components/DayFooter";

import Button from "@material-ui/core/Button";
import TodayIcon from "@material-ui/icons/Today";

import DaysPreview from "../components/DaysPreview";

import * as modals from "../constants/modals";
import * as routes from "../constants/routes";

import { withStyles } from "@material-ui/core/styles";

import {
  getAvailableSlots,
  jsDateToDateString,
  findInArrayByPropVal
} from "../utils";

import Moment from "moment";

import withCustomersOnly from "../components/withCustomersOnly";


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  preview: {
    zIndex: 101,
    position: "sticky",
    top: 56,
    padding: "15px 0 0 0",
    fontFamily: "Lumberjack Inline Rough",
    lineHeight: "26px",
    textAlign: "center",
    backgroundColor: "white",
    color: "#666"
  },
  selectProps: {
    position: "relative",
    margin: 0,
    padding: 0,
    minHeight: "calc(100vh - 106px)",
    backgroundColor: theme.palette.background.paper
  },
  selectPropsText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
  }
});

class DayPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleDateChange(newDate) {
    const { shop } = this.props;

    shop
      .update({ date: newDate })
      .then(() => {
        
      })
      .catch(error => {
        console.warn(error);
      });
  }

  handleBookSlotOpen = slot => {
    const { openModal } = this.props;
    const { date, begin, duration } = slot;

    openModal(modals.BOOK_SLOT, {
      date,
      begin,
      duration
    });
  };

  parseDate = date => {
    const { info } = this.props;

    const dateDiff = Moment(date).diff(undefined, "day");
    const maxDaysInAdvanceToBook =
      info && info.maxDaysInAdvanceToBook ? info.maxDaysInAdvanceToBook : 30;

    if (dateDiff < 0) {
      return new Date();
    } else if (dateDiff > maxDaysInAdvanceToBook) {
      return Moment()
        .add(maxDaysInAdvanceToBook + 1, "day")
        .toDate();
    } else {
      return date;
    }
  };

  render() {
    const {
      classes,
      shop,
      info,
      barbers,
      services,
      availability,
      openModal
    } = this.props;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);
    const service = findInArrayByPropVal(services, "id", shop.serviceId);

    const dateAsString = jsDateToDateString(shop.date);
    const availabilityForDate = availability
      ? availability[dateAsString]
        ? { ... availability[dateAsString]}
        : {}
      : undefined;
    if (availabilityForDate && Moment(shop.date).isSame(undefined, "day")) {
      //if(Moment(this.state.date).isSame(undefined, 'day')) {
      const midnight = Moment().startOf("day");
      const diff = Moment().diff(midnight, "minutes");
      const correctedDiff = Math.ceil(diff / 15) * 15;

      // Only if there is nothing else planned from the very beginning of the day (e.g. full day holiday)
      if (
        availabilityForDate[0] === undefined ||
        availabilityForDate[0] < correctedDiff
      ) {
        availabilityForDate[0] = correctedDiff;
      }
    }

    const dateDiff = Moment(shop.date).diff(undefined, "day");

    const maxDaysInAdvanceToBook =
      info && info.maxDaysInAdvanceToBook ? info.maxDaysInAdvanceToBook : 30;

    const canGoBack = Moment(shop.date).isAfter();
    const canGoForward = dateDiff < maxDaysInAdvanceToBook;
    const isDateValid = dateDiff >= 0 && dateDiff <= maxDaysInAdvanceToBook;
    const minDate = new Date();
    const maxDate = Moment().add(maxDaysInAdvanceToBook + 1, "days");

    let beginHour, endHour;

    let slots;

    if (isDateValid) {
      if (barber && barber.bookingHours && availabilityForDate) {
        const day = shop.date.getDay();
        const bookingHours = barber.bookingHours[day];

        slots = getAvailableSlots(
          availabilityForDate,
          bookingHours,
          service ? service.duration : 20
        );

        const hours = Object.keys(slots);
        if (hours.length > 0) {
          beginHour = Math.min(...hours);
          endHour = Math.max(...hours);
        } else {
          beginHour = 0;
          endHour = -1;
        }
      }
    } else {
      slots = [];
    }

    // Preview

    let daysPreviewBegin = shop.date;
    let daysPreviewEnd = Moment(shop.date)
      .add(6, "days")
      .toDate();

    if (!Moment().isSame(Moment(shop.date), "day")) {
      const dateDiff = Moment(shop.date).diff(undefined, "day");

      if (dateDiff >= maxDaysInAdvanceToBook - 3) {
        const diff = maxDaysInAdvanceToBook - dateDiff;

        daysPreviewBegin = Moment(shop.date)
          .add(-6 + diff, "days")
          .toDate();
        daysPreviewEnd = Moment(shop.date)
          .add(diff, "days")
          .toDate();
      } else {
        const diff = Math.min(dateDiff, 2);

        daysPreviewBegin = Moment(shop.date)
          .add(-diff - 1, "days")
          .toDate();
        daysPreviewEnd = Moment(shop.date)
          .add(6 - diff - 1, "days")
          .toDate();
      }
    }

    // Footer

    const leftButtons = [
      <Link to={routes.HOME} className={classes.link}>
        <Button color="secondary" aria-label="month">
          <TodayIcon />
        </Button>
      </Link>
    ];

    return (
      <div className={classes.root}>
        <DayHeader
          shop={shop}
          onDateChanged={this.handleDateChange}
          parseDate={this.parseDate}
          canGoBack={canGoBack}
          canGoForward={canGoForward}
          minDate={minDate}
          maxDate={maxDate}
        />
        <div className={classes.preview}>
          <DaysPreview
            shop={shop}
            barbers={barbers}
            services={services}
            availability={availability}
            beginDate={daysPreviewBegin}
            endDate={daysPreviewEnd}
            selectedDate={shop.date}
            onDateClick={newDate => {
              this.handleDateChange(this.parseDate(newDate));
            }}
          />
        </div>
        {shop && shop.serviceId && shop.barberId ? (
          <Day
            date={shop.date}
            beginHour={beginHour}
            endHour={endHour}
            slots={slots}
            handleBookSlotClick={this.handleBookSlotOpen}
            stickyTop={100}
          />
        ) : (
          <div className={classes.selectProps}>
            <p className={classes.selectPropsText}>
              Selecteer onderaan het type bezoek en een{" "}
              {info && info.profession && info.profession.singular
                ? info.profession.singular
                : "barber"}{" "}
              om beschikbare uren te tonen.
            </p>
          </div>
        )}
        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          services={services}
          openModal={openModal}
          leftButtons={leftButtons}
        />
      </div>
    );
  }
}

//const authCondition = (authUser) => !!authUser;

//export default withAuthorization(authCondition)(withStyles(styles)(DayPage));

export default withCustomersOnly()(withStyles(styles)(DayPage));
