import React, { PureComponent } from "react";
import Popover from "@material-ui/core/Popover";

import Month from "./Month";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  preview: {
    marginTop: 64
  }
});

class MonthPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleDateChange(newDate) {
    const { shop } = this.props;
    shop.update({ date: newDate });
    this.handleClose(newDate);
  }

  handleClose = (date) => {
    const { requestAvailability, onClose } = this.props;

    requestAvailability(date); // Refresh date because range could have been changed while scrolling
    onClose(date);
  }

  render() {
    const { anchor, shop, barbers, services, availability, requestAvailability, classes } = this.props;

    const open = Boolean(anchor);

    return (
      <Popover
        anchorEl={anchor}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        open={open}
        onClose={(event) => { this.handleClose() }}
        PaperProps={{
          style: {
            width: 360,
            height: 360
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.7)"
          }
        }}
      >
        <Month
          shop={shop}
          barbers={barbers}
          services={services}
          availability={availability}
          selectedDate={shop.date}
          handleDateChange={this.handleDateChange}
          requestAvailability={requestAvailability}
        />
      </Popover>
    );
  }
}

export default withStyles(styles)(MonthPopup);
