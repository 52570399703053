import { encodingExists } from "iconv-lite";
import { CssBaseline } from "@material-ui/core";

import debounce from "lodash/debounce";
import { parseNumber } from "libphonenumber-js";
import { parseOneAddress } from "email-addresses";

import isURL from "validator/lib/isURL";

const getAvailableSlotsForPeriod = (
  availability,
  begin,
  end,
  duration,
  spacing
) => {
  let slots = {};

  let minutesAvailable = 0;
  let minute = 0;

  while (minute <= end) {
    if (minutesAvailable === duration) {
      let hour = Math.floor((minute - minutesAvailable) / 60);
      if (slots[hour] === undefined) {
        slots[hour] = [];
      }

      slots[hour].push({
        begin: minute - minutesAvailable,
        duration: duration,
        type: "available"
      });

      if (spacing) {
        minutesAvailable -= spacing;
      } else {
        minutesAvailable = 0;
      }
    }

    if (availability === undefined || availability[minute] === undefined) {
      if (minute < begin) {
        minutesAvailable = 0;
      } else {
        minutesAvailable++;
      }

      minute++;
    } else {
      minutesAvailable = 0;

      //Fix for this below: minute += availability[minute];

      const slotEnd = minute + availability[minute];
      let continueToNextMinute = true;
      minute++;

      while (continueToNextMinute) {
        //console.info('continueToNextMinute:', minute);
        // No next slot found
        if (availability[minute] === undefined) {
          // ... and still in this slot
          if (minute < slotEnd) {
            minute++;
            // ... if not in this slot anymore, go back to main loop
          } else {
            continueToNextMinute = false;
          }
          // Next slot found
        } else {
          const nextSlotEnd = minute + availability[minute];
          if (nextSlotEnd > slotEnd) {
            // Do nothing and go back to big loop,
            // because slot that's coming up ends after this slot
            continueToNextMinute = false;
          } else {
            minute++;
          }
        }
      }
    }
  }

  return slots;
};

export const getAvailableSlots = (availability, periods, duration, spacing) => {
  let slots = {};

  if (periods) {
    periods.forEach((period) => {
      const periodSlots = getAvailableSlotsForPeriod(
        availability,
        period.begin,
        period.end,
        duration,
        spacing
      );

      for (let hour in periodSlots) {
        if (slots[hour] === undefined) {
          slots[hour] = periodSlots[hour];
        } else {
          slots[hour].push(...periodSlots[hour]);
        }
      }
    });

    for (let hour in slots) {
      slots[hour].sort((slotA, slotB) => slotA.begin - slotB.begin);
    }
  }

  return slots;
};

export const mergeSlots = (...slotsArr) => {
  let mergedSlots = {};

  for (let slots of slotsArr) {
    for (let hour in slots) {
      if (mergedSlots[hour] === undefined) {
        mergedSlots[hour] = [];
      }

      mergedSlots[hour].push(...slots[hour]);
    }
  }

  // Sort merged slots
  Object.keys(mergedSlots).forEach((hour) => {
    mergedSlots[hour].sort((slotA, slotB) => slotA.begin - slotB.begin);
  });

  return mergedSlots;
};

export const getBeginAndEndHourFromDayHours = (dayHours) => {
  let beginHour, endHour;

  for (let i = 0; i < dayHours.length; i++) {
    const period = dayHours[i];
    const _beginHour = Math.floor(period.begin / 60);
    const _endHour = Math.floor((period.end - 1) / 60);

    if (beginHour === undefined || _beginHour < beginHour) {
      beginHour = _beginHour;
    }
    if (endHour === undefined || _endHour > endHour) {
      endHour = _endHour;
    }
  }

  return {
    beginHour,
    endHour
  };
};

export const minutesToTime = (timeInMinutes) => {
  const timeInMinutesAsInt = parseInt(timeInMinutes);

  let time = "";

  const hours = Math.floor(timeInMinutesAsInt / 60);
  const minutes = timeInMinutesAsInt % 60;

  if (hours < 10) {
    time += `0${hours}`;
  } else {
    time += `${hours}`;
  }

  time += ":";

  if (minutes < 10) {
    time += `0${minutes}`;
  } else {
    time += `${minutes}`;
  }

  return time;
};

export const timeToMinutes = (time) => {
  const hoursAndMinutes = time.split(":");

  if (hoursAndMinutes.length === 2) {
    const hours = parseInt(hoursAndMinutes[0]);
    const minutes = parseInt(hoursAndMinutes[1]);

    return hours * 60 + minutes;
  }

  return 0;
};

export const jsDateToDateString = (jsDate) => {
  return jsDate.toISOString().substr(0, 10);
};

export const jsDateToDateTimeString = (jsDate) => {
  return (
    jsDate
      .toISOString()
      .substr(0, 19)
      .replace(/-/g, "")
      .replace(/:/g, "") + "Z"
  );
};

export const dateStringToJSDate = (dateString) => {
  return new Date(`${dateString}T00:00:00Z`);
};

export const isValidDate = (jsDate) => {
  if (Object.prototype.toString.call(jsDate) === "[object Date]") {
    // it is a date
    if (isNaN(jsDate.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
};

export const generateUUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

export const orderArray = (arr, desc, field) => {
  if (arr) {
    const compare = (a, b) => {
      if (a && b) {
        if (a[field] < b[field]) return desc ? 1 : -1;
        if (a[field] > b[field]) return desc ? -1 : 1;
      }

      return 0;
    };

    arr.sort(compare);
  }

  return arr;
};

export const pickRandomElementFromArray = (arr) => {
  if (arr && arr.length > 0) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
};

export const findInArrayByPropVal = (arr, prop, val) => {
  const findByPropVal = (element) => {
    return element && element[prop] === val;
  };

  if (arr) {
    return arr.find(findByPropVal);
  }
};

export const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getScrollOffset = () => {
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
  return {
    x: supportPageOffset
      ? window.pageXOffset
      : isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft,
    y: supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop
  };
};

export const onScroll = (cb) => {
  window.addEventListener(
    "scroll",
    debounce(() => {
      // lodash debounce method.
      cb(getScrollOffset());
    }, 300)
  ); //ms
};

export const areEqualShallow = (a, b) => {
  for (var key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (var key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

export const formatToXDecimals = (number, decimals) => {
  return `${number.toLocaleString("nl-BE", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })}`;
};

export const formatToTwoDecimals = (number) => {
  return formatToXDecimals(number, 2);
};

export const roundToTwoDecimals = (number) => {
  return parseFloat(Math.round(number * 100) / 100).toFixed(2);
};

export const formatSearchInput = (searchInput) => {
  if (searchInput) {
    const words = searchInput.toLowerCase().match(/\S+/g) || [];
    return words.join(" ");
  }

  return "";
};

export const isEmpty = (val) => {
  return val === null || val === undefined || val === "";
};

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const beautifyPhone = (phone) => {
  if (phone && phone.length === 12) {
    return `${phone.substr(0, 3)} ${phone.substr(3, 3)} ${phone.substr(
      6,
      2
    )} ${phone.substr(8, 2)} ${phone.substr(10, 2)}`;
  }

  return phone;
};

const parseBelgianNumber = (phone, allowLandline) => {
  const parsedPhone = parseNumber(phone, "BE");
  if (
    parsedPhone &&
    parsedPhone.country === "BE" &&
    parsedPhone.phone &&
    (allowLandline || parsedPhone.phone.length === 9)
  ) {
    return `+32${parsedPhone.phone}`;
  }
};

const parseDutchNumber = (phone, allowLandline) => {
  const parsedPhone = parseNumber(phone, "NL");
  if (
    parsedPhone &&
    parsedPhone.country === "NL" &&
    parsedPhone.phone &&
    (allowLandline || parsedPhone.phone.length === 9)
  ) {
    return `+31${parsedPhone.phone}`;
  }
};

export const parsePhone = (phone, allowLandline) => {
  if (
    phone &&
    phone.length >= 10 &&
    (phone.substr(0, 2) === "32" || phone.substr(0, 2) === "31")
  ) {
    phone = `+${phone}`;
  }

  let parsedPhone = parseBelgianNumber(phone, allowLandline);
  if (parsedPhone === undefined) {
    parsedPhone = parseDutchNumber(phone, allowLandline);
  }

  return parsedPhone;
};

export const parseEmail = (email) => {
  if (email) {
    const parsedEmail = parseOneAddress({ input: email, rejectTLD: true });
    if (parsedEmail && parsedEmail.address) {
      return parsedEmail.address;
    }
  }
};

export const generateAuthorizedLink = (authUser, shopId, link, params) => {
  return new Promise((resolve, reject) => {
    if (authUser) {
      authUser
        .getIdToken()
        .then((token) => {
          if (token) {
            let url = `${link}?token=${token}&shopId=${shopId}`;

            if (params) {
              for (const key in params) {
                url += `&${encodeURIComponent(key)}=${encodeURIComponent(
                  params[key]
                )}`;
              }
            }

            resolve(url);
          } else {
            reject("Geen geldig token gevonden voor deze gebruiker");
          }
        })
        .catch((error) => {
          console.warn(error);
          reject("Fout bij het ophalen van het token voor deze gebruiker");
        });
    } else {
      reject("Geen gemachtigde gebruiker gevonden");
    }
  });
};

export const parseServiceFields = (service) => {
  if (service === undefined || service === null) {
    throw new Error("service is not defined");
  }

  const { name, color, duration, price } = service;

  if (name === undefined || name === null) {
    throw new Error("name is not defined");
  }
  if (color === undefined || color === null) {
    throw new Error("color is not defined");
  }
  if (duration === undefined || duration === null) {
    throw new Error("duration is not defined");
  }
  if (price === undefined || price === null) {
    throw new Error("price is not defined");
  }

  const cleanName = name.trim();
  if (cleanName.length === 0) {
    throw new Error("Naam is leeg");
  }
  const cleanColor = color.trim();
  if (cleanColor.length === 0) {
    throw new Error("Kleur is leeg");
  }
  const parsedDuration = parseFloat(duration);
  if (isNaN(parsedDuration)) {
    throw new Error("Duurtijd heeft een ongeldige waarde");
  }
  if (parsedDuration <= 0) {
    throw new Error("Duurtijd moet groter dan 0 zijn");
  }
  if (parsedDuration % 1 !== 0) {
    throw new Error("Duurtijd mag geen kommagetal zijn");
  }
  const parsedPrice = parseFloat(price);
  if (isNaN(parsedPrice)) {
    throw new Error("Prijs heeft een ongeldige waarde");
  }
  if (parsedPrice < 0) {
    throw new Error("Prijs moet groter dan of gelijk aan 0 zijn");
  }

  return {
    name: cleanName,
    color: cleanColor,
    duration: parseInt(parsedDuration),
    price: parsedPrice
  };
};

export const parseProductFields = (product) => {
  if (product === undefined || product === null) {
    throw new Error("product is not defined");
  }

  const { name, color, duration, price } = product;

  if (name === undefined || name === null) {
    throw new Error("name is not defined");
  }
  if (price === undefined || price === null) {
    throw new Error("price is not defined");
  }

  const cleanName = name.trim();
  if (cleanName.length === 0) {
    throw new Error("Naam is leeg");
  }
  const parsedPrice = parseFloat(price);
  if (isNaN(parsedPrice)) {
    throw new Error("Prijs heeft een ongeldige waarde");
  }
  if (parsedPrice < 0) {
    throw new Error("Prijs moet groter dan of gelijk aan 0 zijn");
  }

  return {
    name: cleanName,
    price: parsedPrice
  };
};

export const parseBarberFields = (barber) => {
  if (barber === undefined || barber === null) {
    throw new Error("barber is not defined");
  }

  const { name, emailAddress, phoneNumber, imageFilename } = barber;

  if (name === undefined || name === null) {
    throw new Error("name is not defined");
  }
  const cleanName = name.trim();
  if (cleanName.length === 0) {
    throw new Error("Naam is leeg");
  }

  const parsedFields = { name: cleanName };

  if (emailAddress) {
    const parsedEmailAddress = parseEmail(emailAddress);
    if (parsedEmailAddress === undefined) {
      throw new Error("E-mailadres is niet geldig");
    }
    parsedFields.emailAddress = parsedEmailAddress;
  } else {
    parsedFields.emailAddress = "";
  }

  if (phoneNumber) {
    const parsedPhoneNumber = parsePhone(phoneNumber, true);
    if (parsedPhoneNumber === undefined) {
      throw new Error("Telefoonnummer is niet geldig");
    }
    parsedFields.phoneNumber = parsedPhoneNumber;
  } else {
    parsedFields.phoneNumber = "";
  }

  if (imageFilename) {
    parsedFields.imageFilename = imageFilename;
  } else {
    parsedFields.imageFilename = "";
  }

  return parsedFields;
};

export const parseShopFields = (shop) => {
  if (shop === undefined || shop === null) {
    throw new Error("shop is not defined");
  }

  const {
    name,
    tagline,
    story,
    website,
    address,
    maxDaysInAdvanceToBook,
    maxHoursInAdvanceToCancel,
    profession,
    social,
    assets
  } = shop;

  if (name === undefined || name === null) {
    throw new Error("name is not defined");
  }
  const cleanName = name.trim();
  if (cleanName.length === 0) {
    throw new Error("Naam is leeg");
  }

  const parsedFields = { name: cleanName };

  if (tagline) {
    parsedFields.tagline = tagline;
  } else {
    parsedFields.tagline = "";
  }

  if (story) {
    parsedFields.story = story.filter((paragraph) => paragraph !== "");
  } else {
    parsedFields.story = [];
  }

  if (website) {
    if (!isURL(website)) {
      throw new Error("Website is niet geldig");
    }

    parsedFields.website = website;
  } else {
    parsedFields.website = "";
  }

  if (address) {
    parsedFields.address = {
      street: address.street ? address.street : "",
      number: address.number ? address.number : "",
      box: address.box ? address.box : "",
      zip: address.zip ? address.zip : "",
      city: address.city ? address.city : ""
    };
  } else {
    parsedFields.address = {};
  }

  if (maxDaysInAdvanceToBook !== undefined) {
    const parsedMaxDaysInAdvanceToBook = parseFloat(maxDaysInAdvanceToBook);
    if (isNaN(parsedMaxDaysInAdvanceToBook)) {
      throw new Error("Het aantal dagen is ongeldig");
    }
    if (parsedMaxDaysInAdvanceToBook < 1) {
      throw new Error("Het aantal dagen moet groter zijn dan één");
    }
    if (parsedMaxDaysInAdvanceToBook > 120) {
      throw new Error("Het aantal dagen moet kleiner zijn dan 120");
    }

    parsedFields.maxDaysInAdvanceToBook = parsedMaxDaysInAdvanceToBook;
  }

  if (maxHoursInAdvanceToCancel !== undefined) {
    const parsedMaxHoursInAdvanceToCancel = parseFloat(
      maxHoursInAdvanceToCancel
    );
    if (isNaN(parsedMaxHoursInAdvanceToCancel)) {
      throw new Error("Het aantal uren is ongeldig");
    }
    if (parsedMaxHoursInAdvanceToCancel < 1) {
      throw new Error("Het aantal uren moet groter zijn dan één");
    }
    if (parsedMaxHoursInAdvanceToCancel > 12960) {
      throw new Error("Het aantal uren moet kleiner zijn dan 96");
    }

    parsedFields.maxHoursInAdvanceToCancel = parsedMaxHoursInAdvanceToCancel;
  }

  if (profession) {
    parsedFields.profession = {
      singular: profession.singular ? profession.singular : "",
      plural: profession.plural ? profession.plural : ""
    };
  } else {
    parsedFields.profession = {};
  }

  parsedFields.social = [];
  if (social) {
    social.forEach((channel) => {
      if (channel.url === "") {
        // Do nothing
      } else if (!isURL(channel.url)) {
        throw new Error(
          `Website voor sociaal media kanaal '${channel.name}' is niet geldig`
        );
      } else {
        parsedFields.social.push(channel);
      }
    });
  }

  if (assets) {
    if (
      assets.logoFilename === undefined ||
      assets.logoFilename === null ||
      assets.logoFilename === ""
    ) {
      throw new Error("Logo mag niet leeg zijn");
    }
    if (
      assets.launchFilename === undefined ||
      assets.launchFilename === null ||
      assets.launchFilename === ""
    ) {
      throw new Error("Primaire achtergrond mag niet leeg zijn");
    }
    if (
      assets.storyFilename === undefined ||
      assets.storyFilename === null ||
      assets.storyFilename === ""
    ) {
      throw new Error("Secundaire achtergrond mag niet leeg zijn");
    }

    parsedFields.assets = {
      logoFilename: assets.logoFilename,
      launchFilename: assets.launchFilename,
      storyFilename: assets.storyFilename
    };
  }

  return parsedFields;
};

export const filterNotRemoved = (arr) => {
  return arr ? arr.filter((item) => !item || !item.isRemoved) : undefined;
};

export const splitTextInParagraphs = (text) => {
  return text
    .replace(/\n\r/g, "\n")
    .replace(/\r/g, "\n")
    .split(/\n{1,}/g);
};

export const getGoogleMapsUrl = (query) => {
  return `https://maps.google.com/maps?q=${encodeURIComponent(
    query
  )}&output=embed`;
  /*return `https://maps.google.com/maps?q=${encodeURIComponent(
    query
  )}&t=&z=13&ie=UTF8&iwloc=&output=embed`;*/
};

export const getGoogleMapsUrlForShop = (name, address) => {
  const addressAsString = addressToString(address);

  let query = "";

  if (addressAsString) {
    query += addressAsString;
  } else if (name) {
    query += `${name} `;
  }

  if (query.length > 0) {
    return getGoogleMapsUrl(query);
  }
};

export const addressToString = (address) => {
  if (address) {
    const { street, number, box, zip, city } = address;
    return `${street} ${number} ${box}, ${zip} ${city}`;
  }
};
