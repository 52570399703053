import React from "react";
import { withRouter, Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import AuthContext from "../context/auth";

import Loading from "./Loading";

const withCrewAuthorization = () => Component => {
  class WithCrewAuthorization extends React.Component {
    componentDidMount() {}

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth && auth.ready ? (
              auth.shopId ? (
                auth.user ? (
                  auth.isCrew ? (
                    <Component {...this.props} auth={auth} />
                  ) : (
                    <Redirect to={routes.USER} />
                  )
                ) : (
                  <Redirect to={routes.SIGN_IN} />
                )
              ) : (
                <Redirect to={routes.SIGN_IN} />
              )
            ) : (
              <Loading />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithCrewAuthorization);
};

export default withCrewAuthorization;
