import React from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import { List, ListItem, TextField } from "@material-ui/core";
import { compareObjects, getGoogleMapsUrlForShop } from "../utils";

const styles = (theme) => ({
  list: {
    padding: 0
  },
  field: {
    padding: "16px 0"
  },
  street: {
    flexGrow: 2,
    margin: 2
  },
  number: {
    width: 50,
    margin: 2
  },
  box: {
    width: 50,
    margin: 2
  },
  zip: {
    width: 100,
    margin: 2
  },
  city: {
    flexGrow: 1,
    margin: 2
  },
  map: {
    width: "100%"
  }
});

class EditAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      street: "",
      number: "",
      box: "",
      zip: "",
      city: ""
    };
  }

  componentDidMount() {
    const { address } = this.props;

    if (address) {
      this.setState({ ...address });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { address } = this.props;
    const prevAddress = prevProps.address;

    if (!compareObjects(address, prevAddress)) {
      this.setState({ ...address });
    }
  }

  componentWillUnmount() {}

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  handleBlur = (name) => (event) => {
    const { onChange } = this.props;

    onChange(this.state);
  };

  getGoogleMapsUrl() {
    const { info, address } = this.props;

    if (info) {
      return getGoogleMapsUrlForShop(info.name, address);
    }
  }

  render() {
    const { classes, showMaps } = this.props;
    const { street, number, box, zip, city } = this.state;

    const googleMapsUrl = showMaps ? this.getGoogleMapsUrl() : undefined;

    return (
      <List className={classes.list}>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="street"
            label="Straat"
            fullWidth
            value={street}
            className={classes.street}
            onChange={this.handleChange("street")}
            onBlur={this.handleBlur("street")}
          />
          <TextField
            margin="dense"
            id="number"
            label="Nr."
            value={number}
            className={classes.number}
            onChange={this.handleChange("number")}
            onBlur={this.handleBlur("number")}
          />
          <TextField
            margin="dense"
            id="box"
            label="Bus"
            value={box}
            className={classes.box}
            onChange={this.handleChange("box")}
            onBlur={this.handleBlur("box")}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="zip"
            label="Postcode"
            value={zip}
            className={classes.zip}
            onChange={this.handleChange("zip")}
            onBlur={this.handleBlur("zip")}
          />
          <TextField
            margin="dense"
            id="city"
            label="Gemeente of stad"
            fullWidth
            value={city}
            className={classes.city}
            onChange={this.handleChange("city")}
            onBlur={this.handleBlur("city")}
          />
        </ListItem>
        {googleMapsUrl && (
          <ListItem className={classes.field}>
            <iframe
              className={classes.map}
              src={googleMapsUrl}
              width="400"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditAddress);
