import React, { PureComponent } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Redirect } from 'react-router-dom';

import * as routes from '../constants/routes';

import { withStyles } from '@material-ui/core/styles';
import ListPopup from './ListPopup';

import { store } from '../firebase';

const styles = theme => ({
  
});

const RESERVE_PRODUCT = 'reserve_product';
const ALL_PRODUCTS = 'all_products';

class Reserve extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    //this.handleOptionClick = this.handleOptionClick.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleOptionClick = (type) => {
    const { shop, userId, productId } = this.props;

    this.setState({
      type
    });

    switch(type) {
      case RESERVE_PRODUCT:
        store.addReservation(shop.id, userId, false, [ { id: productId, amount: 1 } ]).then(() => {

        }, (error) => {
          console.warn(error);
        });
        break;
      case ALL_PRODUCTS:
        
        break;
    }

    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { anchor, classes } = this.props;
    const { type } = this.state;

    const items = [
        {
            id: RESERVE_PRODUCT,
            component: 'Reserveren'
        }/*,
        {
            id: ALL_PRODUCTS,
            name: 'Alle producten'
        }*/
    ];

    if(type === ALL_PRODUCTS) {
      return (
        <Redirect to={routes.PRODUCTS} />
      );
    }

    return (
      <ListPopup anchor={ anchor } items={ items } onClick={ this.handleOptionClick } onClose={ this.handleClose } />
    );
  }
}

export default withStyles(styles)(Reserve);
