import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  ListSubheader,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { minutesToTime } from "../utils";

import * as days from "../constants/days";

const styles = theme => ({
  day: {
    minHeight: 210
  }
});

class ViewDayHours extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPeriodText(period) {
    const beginTime = minutesToTime(period.begin);
    const endTime = minutesToTime(period.end);

    return `${beginTime} - ${endTime}`;
  }

  render() {
    const { dayHours, classes } = this.props;

    return (
      <div>
        {dayHours &&
          days.ALL.map(day => (
            <div key={day.name}>
              <ListSubheader>{day.label}</ListSubheader>
              <div className={classes.day}>
                {dayHours[day.index] && dayHours[day.index].length > 0 ? (
                  dayHours[day.index].map(period => (
                    <ListItem key={period.begin}>
                      <ListItemText primary={this.getPeriodText(period)} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="Gesloten" />
                  </ListItem>
                )}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(ViewDayHours);
