import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  Typography,
  TextField,
  Button,
  Slide
} from "@material-ui/core";

import { CirclePicker } from "react-color";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  field: {
    padding: "16px 0"
  },
  colorContainer: {
    margin: "8px 0 4px 0"
  },
  colorLabel: {
    paddingBottom: 5
  },
  colorDescription: {
    paddingTop: 5
  }
});

class ServiceFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleNameChange = (event) => {
    const value = event.target.value;
    const { service, onUpdate } = this.props;

    onUpdate({ ...service, name: value });
  };

  handleDurationChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    const { service, onUpdate } = this.props;

    onUpdate({ ...service, duration: value });
  };

  handlePriceChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    const { service, onUpdate } = this.props;

    onUpdate({ ...service, price: value });
  };

  handleColorChange = (color, event) => {
    const { service, onUpdate } = this.props;

    onUpdate({ ...service, color: color.hex });
  };

  render() {
    const { classes, open, onClose, service, ...other } = this.props;
    const { name, color, duration, price } = service;

    return (
      <List className={classes.content}>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="name"
            label="Naam"
            helperText="De naam van deze behandeling"
            fullWidth
            value={name}
            onChange={this.handleNameChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="price"
            label="Prijs"
            type="number"
            helperText="De prijs van deze behandeling"
            fullWidth
            value={`${price}`}
            onChange={this.handlePriceChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="price"
            label="Duurtijd"
            type="number"
            helperText="De duurtijd van deze behandeling"
            fullWidth
            value={`${duration}`}
            onChange={this.handleDurationChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <div className={classes.colorContainer}>
            <Typography variant="caption" className={classes.colorLabel}>
              Kleur
            </Typography>
            <CirclePicker
              color={color}
              width={400}
              onChange={this.handleColorChange}
            />
            <Typography variant="caption" className={classes.colorDescription}>
              Het kleur van deze behandeling in de agenda
            </Typography>
          </div>
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(ServiceFields);
