import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import { List, ListItem, TextField } from "@material-ui/core";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  field: {
    padding: "16px 0"
  }
});

class ProductFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleNameChange = (event) => {
    const value = event.target.value;
    const { product, onUpdate } = this.props;

    onUpdate({ ...product, name: value });
  };

  handlePriceChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    const { product, onUpdate } = this.props;

    onUpdate({ ...product, price: value });
  };

  render() {
    const { classes, open, onClose, product, ...other } = this.props;
    const { name, price } = product;

    return (
      <List className={classes.content}>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="name"
            label="Naam"
            helperText="De naam van dit product"
            fullWidth
            value={name}
            onChange={this.handleNameChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="price"
            label="Prijs"
            type="number"
            helperText="De prijs van dit product"
            fullWidth
            value={`${price}`}
            onChange={this.handlePriceChange}
          />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(ProductFields);
