import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import 'moment-timezone';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  title: {
    fontFamily: 'Lumberjack Inline Rough',
    fontSize: 20
  },
  text: {

  }
});

class Overlap extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleConfirm = () => {
    this.props.onConfirm();
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, open, onClose, title, description, ...other } = this.props;

    return (
      <Dialog fullScreen TransitionComponent={ Transition } open={ open } onClose={ this.handleClose } aria-labelledby="Afspraak overlapt">
        <DialogTitle>
          <Typography className={ classes.title }>
            { title }
          </Typography>
        </DialogTitle>
        <List>
          <ListItem>
            <ListItemText>
              <Typography className={ classes.text } variant="subheading" id="simple-modal-description">
                { description }
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem button className={ classes.button } onClick={ this.handleConfirm }>
            <CheckIcon color="primary" className={ classes.leftIcon } />
            <ListItemText primary="Bevestigen" />
          </ListItem>
          <ListItem button className={ classes.button } onClick={ this.handleClose }>
            <CloseIcon color="secondary" className={ classes.leftIcon } />
            <ListItemText primary="Annuleren" />
          </ListItem>
        </List>
      </Dialog>
    );
  }
}

Overlap.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  phone: PropTypes.string,
  name: PropTypes.string,
};

export default withMobileDialog()(withStyles(styles)(Overlap));