import React, { Component } from "react";
import PropTypes from "prop-types";

import AnonymousCustomer from "../components/AnonymousCustomer";
import VerifiedCustomer from "../components/VerifiedCustomer";
import Loading from "./Loading";
import Error from "./Error";
import Overlap from "./Overlap";
import Search from "../components/Search";

import AuthContext from "../context/auth";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { jsDateToDateString } from "../utils";
import { store } from "../firebase";

import Moment from "react-moment";

import Confirmation from "./Confirmation";
import ControlSpacing from "../components/ControlSpacing";

import {
  AVAILABILITY_NOT_BETWEEN_BOOKING_HOURS_ERROR,
  AVAILABILITY_HOLIDAY_ERROR,
  AVAILABILITY_OVERLAP_ERROR
} from "../constants/errors";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  text: {},
  searchCustomer: {
    display: "block"
  },
  notConfirmedItem: {
    padding: "3px 16px !important",
    textAlign: "center",
    backgroundColor: "#f44336"
  },
  notConfirmedText: {
    color: "rgba(255, 255, 255, 0.9)"
  }
});

class BookSlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: { name: "", phone: "", email: "", isVerified: false },
      showCrewCustomer: false,
      isCrewCustomerDisabled: false,
      loading: {
        open: false
      },
      error: {
        open: false
      },
      confirmation: {
        open: false
      },
      overlap: {
        open: false
      }
    };

    this.handleBookSlotConfirm = this.handleBookSlotConfirm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;

    // Automatically book slot on new customer sign in
    /*if (
      this.verifiedCustomerSignedIn &&
      auth &&
      auth.customer &&
      auth.customer.isVerified
    ) {
      this.handleBookSlotConfirm();
      this.verifiedCustomerSignedIn = false;
    }*/
  }

  handleCustomerChange = customer => {
    this.setState({ customer });
  };

  handleSearchCreate = inputValue => {
    this.setState({
      customer: {
        id: `id_${new Date().getTime()}`,
        customerId: undefined,
        name: inputValue,
        phone: "",
        email: "",
        isVerified: inputValue ? true : false
      },
      selectedCustomer: null,
      showCrewCustomer: true,
      isCrewCustomerDisabled: false
    });
  };

  handleSearchSelect = (id, label, fields) => {
    const { shop } = this.props;

    if (id && fields && fields.name) {
      let customer = {
        id: `id_${new Date().getTime()}`,
        customerId: id,
        name: fields.name,
        phone: "",
        email: "",
        isVerified: true
      };

      if (fields.phone) {
        customer.phone = fields.phone;
      }

      store
        .getCustomerInfo(shop.id, id)
        .then(info => {
          if (info) {
            if (info.email) {
              customer.email = info.email;
            }
          }

          this.setState({
            customer,
            selectedCustomer: { ...customer },
            showCrewCustomer: true,
            isCrewCustomerDisabled: true
          });
        })
        .catch(error => {
          console.warn(error);

          this.setState({
            customer,
            selectedCustomer: { ...customer },
            showCrewCustomer: true,
            isCrewCustomerDisabled: true
          });
        });
    }
  };

  handleSearchClear = () => {
    const customer = {
      id: `id_${new Date().getTime()}`,
      customerId: undefined,
      name: "",
      phone: "",
      email: "",
      isVerified: false
    };

    this.setState({
      customer,
      selectedCustomer: null,
      showCrewCustomer: false
    });
  };

  handleDurationChange = duration => {
    this.setState({ duration });
  };

  handleBookSlotConfirm = ignoreAvailabilityCheck => {
    this.setState({
      loading: {
        open: true,
        description: "Klantgegevens bewaren..."
      }
    });

    this.saveCustomerDetails()
      .then(() => {
        this.requestSlot(ignoreAvailabilityCheck);
      })
      .catch(error => {
        this.setState({
          loading: {
            open: false
          },
          error: {
            open: true,
            description: `Er ging iets fout: ${error.toString()}`
          }
        });
      });
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleConfirmationClose = () => {
    this.setState({ confirmation: { open: false } });
    this.handleClose();
  };

  handleErrorClose = () => {
    this.setState({ error: { open: false } });
  };

  handleOverlapConfirm = () => {
    this.setState({ overlap: { open: false } }, () => {
      this.handleBookSlotConfirm(true);
    });
  };

  handleOverlapClose = () => {
    this.setState({ overlap: { open: false } }, () => {
      this.handleClose();
    });
  };

  saveCustomerDetails = () => {
    const { auth, shop } = this.props;
    const { customer, selectedCustomer } = this.state;

    return new Promise((resolve, reject) => {
      if (auth && auth.customer) {
        const promises = [];

        if (customer.name !== auth.customer.name) {
          promises.push(auth.updateName(customer.name));
        }
        if (customer.email !== auth.customer.email) {
          promises.push(auth.updateEmail(shop.id, customer.email));
        }

        return Promise.all(promises)
          .then(resolve)
          .catch(reject);
      } else {
        console.info(customer, selectedCustomer);

        if (selectedCustomer && selectedCustomer.email !== customer.email) {
          return store
            .updateCustomer(
              shop.id,
              auth.user.uid,
              auth.isCrew === true,
              customer.customerId,
              { email: customer.email },
              taskId => {
                console.log(taskId);
              }
            )
            .then(resolve)
            .catch(reject);
        } else {
          return resolve();
        }
      }
    });
  };

  requestSlot = ignoreAvailabilityCheck => {
    const { customer } = this.state;

    const {
      auth,
      shop,
      date,
      begin,
      duration,
      barberId,
      onConfirm
    } = this.props;

    const dateAsString = jsDateToDateString(date);

    this.setState({
      loading: {
        open: true,
        description: "Versturen van je aanvraag..."
      },
      confirmation: {
        open: false,
        phone: customer.phone,
        name: customer.name,
        email: customer.email,
        date: date.toISOString(),
        begin: begin,
        duration: duration
      }
    });

    store
      .addBooking(
        shop.id,
        barberId ? barberId : shop.barberId,
        shop.serviceId,
        dateAsString,
        begin,
        this.state.duration,
        customer.phone,
        customer.name,
        customer.email,
        customer.customerId,
        auth.user.uid,
        auth.isCrew === true,
        ignoreAvailabilityCheck === true,
        taskId => {
          console.log(taskId);
          let loading = this.state.loading;
          loading.description = "Verifiëren en verwerken van je aanvraag...";
          this.setState({
            loading: loading
          });
        }
      )
      .then(data => {
        console.log("Document successfully updated!", data);

        let confirmation = this.state.confirmation;
        if (data && data.slotId) {
          confirmation.slotId = data.slotId;
        }
        confirmation.open = true;

        this.setState(
          {
            loading: {
              open: false
            },
            confirmation: confirmation,
            customer: {
              id: `id_${new Date().getTime()}`,
              customerId: undefined,
              name: "",
              phone: "",
              email: "",
              isVerified: false
            }
          },
          () => {}
        );
      })
      .catch(error => {
        console.info(error);

        let newState = {
          loading: {
            open: false
          }
        };

        if (error) {
          switch (error.name) {
            case AVAILABILITY_NOT_BETWEEN_BOOKING_HOURS_ERROR:
              if (auth.isCrew) {
                newState.overlap = {
                  open: true,
                  title: "Buiten boekingsuren",
                  description:
                    "Deze afspraak valt buiten de boekingsuren. Ben je zeker dat je deze afspraak wilt bevestigen?"
                };
              } else {
                newState.error = {
                  open: true,
                  description: error.text
                };
              }
              break;
            case AVAILABILITY_HOLIDAY_ERROR:
              if (auth.isCrew) {
                newState.overlap = {
                  open: true,
                  title: "Vakantie gepland",
                  description:
                    "Je hebt vakantie op het gekozen tijdstip. Ben je zeker dat je deze afspraak wilt bevestigen?"
                };
              } else {
                newState.error = {
                  open: true,
                  description: error.text
                };
              }
              break;
            case AVAILABILITY_OVERLAP_ERROR:
              if (auth.isCrew) {
                newState.overlap = {
                  open: true,
                  title: "Afspraak overlapt",
                  description:
                    "Er is reeds een afspraak gevonden op het gekozen tijdstip. Ben je zeker dat je deze afspraak wilt bevestigen?"
                };
              } else {
                newState.error = {
                  open: true,
                  description: error.text
                };
              }
              break;
            default:
              newState.error = {
                open: true,
                description: error.text
              };
              break;
          }
        } else {
          newState.error = {
            open: true,
            description: "Er is een onbekende fout opgetreden"
          };
        }

        this.setState(newState);
      });
  };

  render() {
    const {
      classes,
      open,
      onClose,
      date,
      begin,
      duration,
      phone,
      name,
      auth,
      shop,
      info,
      bugsnagClient,
      ...other
    } = this.props;
    const {
      confirmation,
      loading,
      error,
      overlap,
      showCrewCustomer,
      isCrewCustomerDisabled
    } = this.state;

    let customer;
    //if (auth && auth.isCrew) {
    customer = this.state.customer;
    /*} else {
      customer = auth.customer;
    }*/

    let beginHour = Math.floor(begin / 60);
    if (beginHour < 10) {
      beginHour = `0${beginHour}`;
    }
    let beginMinutes = begin % 60;
    if (beginMinutes < 10) {
      beginMinutes = `0${beginMinutes}`;
    }
    const time = `${beginHour}:${beginMinutes}`;

    return (
      <div>
        <Loading open={loading.open} description={loading.description} />
        <Error
          open={error.open}
          description={error.description}
          onClose={this.handleErrorClose}
        />
        <Confirmation
          open={confirmation.open}
          onClose={this.handleConfirmationClose}
          shop={shop}
          slotId={confirmation.slotId}
          info={info}
          date={confirmation.date}
          begin={confirmation.begin}
          duration={confirmation.duration}
          phone={confirmation.phone}
          name={confirmation.name}
        />
        {auth && auth.isCrew && (
          <Overlap
            open={overlap.open}
            title={overlap.title}
            description={overlap.description}
            onConfirm={this.handleOverlapConfirm}
            onClose={this.handleOverlapClose}
          />
        )}
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          onEntering={this.onEntering}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="Een afspraak maken"
        >
          <DialogTitle>
            <Typography className={classes.title}>
              Een afspraak maken
            </Typography>
          </DialogTitle>
          <List>
            <ListItem>
              <ListItemText>
                <Typography
                  className={classes.text}
                  variant="subheading"
                  id="simple-modal-description"
                >
                  <span>Bevestig je afspraak op </span>
                  <strong>
                    <Moment locale="nl-be" format="dddd D MMMM">
                      {date}
                    </Moment>
                  </strong>
                  <span> om </span>
                  <strong>{time}</strong>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                className={classes.notConfirmedItem}
                classes={{ primary: classes.notConfirmedText }}
                primary="Je afspraak is nog niet bevestigd"
              />
            </ListItem>
            {auth && auth.isCrew ? (
              <div>
                <ListItem className={classes.searchCustomer}>
                  <Search
                    shop={shop}
                    collection="customers"
                    collectionLabel="Klanten"
                    itemLabel="Klant"
                    canCreateItem={true}
                    onCreate={this.handleSearchCreate}
                    onSelect={this.handleSearchSelect}
                    onClear={this.handleSearchClear}
                    renderLabel={item => {
                      let label = "";

                      if (item) {
                        if (item.fields) {
                          label = item.fields.name;
                          if (item.fields.phone) {
                            label += ` (${item.fields.phone})`;
                          }
                        } else if (item.label) {
                          label = item.label;
                        }
                      }

                      return label;
                    }}
                  />
                </ListItem>
                {showCrewCustomer && (
                  <AnonymousCustomer
                    customer={customer}
                    disabled={isCrewCustomerDisabled}
                    onChange={this.handleCustomerChange}
                  />
                )}
                <ListItem>
                  <ListItemText>Duurtijd van de afspraak</ListItemText>
                  <ControlSpacing
                    spacing={duration}
                    min={Math.floor(duration / 2)}
                    max={duration * 2}
                    handleSpacingChange={this.handleDurationChange}
                  />
                </ListItem>
              </div>
            ) : (
              <VerifiedCustomer
                shop={shop}
                onChange={this.handleCustomerChange}
                showUpdateButtons={false}
                bugsnagClient={bugsnagClient}
              />
            )}
            {customer && customer.isVerified ? (
              <ListItem
                button
                className={classes.button}
                onClick={this.handleBookSlotConfirm}
              >
                <CheckIcon color="primary" className={classes.leftIcon} />
                <ListItemText primary="Afspraak bevestigen" />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem
              button
              className={classes.button}
              onClick={this.handleClose}
            >
              <CloseIcon color="secondary" className={classes.leftIcon} />
              <ListItemText primary="Sluiten" />
            </ListItem>
          </List>
        </Dialog>
      </div>
    );
  }
}

BookSlot.propTypes = {
  classes: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  phone: PropTypes.string,
  name: PropTypes.string
};

export default withMobileDialog()(
  withStyles(styles)(props => (
    <AuthContext.Consumer>
      {auth => <BookSlot {...props} auth={auth} />}
    </AuthContext.Consumer>
  ))
);
//export default withMobileDialog()(withStyles(styles)(BookSlot));
