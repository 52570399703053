import React, { Component } from "react";

import ReactMoment from "react-moment";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

import { findInArrayByPropVal } from "../utils";

import withCrewAuthorization from '../components/withCrewAuthorization';

import CrewHeader from "../components/CrewHeader";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  reservations: {
    minHeight: 'calc(100vh - 114px)'
  }
});

class ReservationsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { shop } = this.props;

    this.unsubscribeShopReservations = store.onGetShopReservations(
      shop.id,
      reservations => {
        this.setState({ reservations });
      }
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeShopReservations) {
      this.unsubscribeShopReservations();
      this.unsubscribeShopReservations = undefined;
    }
  }

  componentDidUpdate() {}

  findProduct(id) {
    const { products } = this.props;

    return findInArrayByPropVal(products, "id", id);
  }

  render() {
    const { shop, toggleDrawer, classes } = this.props;
    const { reservations } = this.state;

    return (
      <div className={classes.root}>
        <CrewHeader title="Reservaties" toggleDrawer={ toggleDrawer } />
        <List className={ classes.reservations }>
          {reservations &&
            Object.keys(reservations).map(date => (
              <div key={date}>
                <ListSubheader>
                  <ReactMoment locale="nl-be" format="dddd DD/MM/YYYY">
                    {date}
                  </ReactMoment>
                </ListSubheader>
                {reservations[date].map((reservation, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      {reservation.data && reservation.data.name && (
                        <strong>{reservation.data.name}</strong>
                      )}
                    </ListItemText>
                    {reservation.products && reservation.products.length > 0 && (
                      <List>
                        {reservation.products.map((product, productIndex) => (
                          <ListItem key={product.id}>
                            <ListItemText>
                              {this.findProduct(product.id).name}
                            </ListItemText>
                            <ListItemText>{product.amount}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </ListItem>
                ))}
              </div>
            ))}
          <ListSubheader />
        </List>
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(ReservationsPage));
