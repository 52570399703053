import React, { Component } from "react";

import { List, ListItem, ListSubheader } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import withCrewAuthorization from "../components/withCrewAuthorization";

import CrewHeader from "../components/CrewHeader";
import Search from "../components/Search";
import Customer from "../components/Customer";

import Slots from "../components/Slots";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  customers: {
    minHeight: "calc(100vh - 114px)"
  },
  blockItem: {
    display: "block"
  }
});

class CustomersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  handleSearchCreate = inputValue => {};

  handleSearchSelect = (id, label, fields) => {
    this.setState({
      customerId: id
    });
  };

  handleSearchClear = () => {
    this.setState({
      customerId: null
    });

    if (this.clearSearch) {
      this.clearSearch();
    }
  };

  render() {
    const { shop, products, toggleDrawer, classes } = this.props;
    const { customerId } = this.state;

    return (
      <div className={classes.root}>
        <CrewHeader title="Klanten" toggleDrawer={toggleDrawer} />
        <List className={classes.customers}>
          <ListItem className={classes.blockItem}>
            <Search
              shop={shop}
              collection="customers"
              collectionLabel="Klanten"
              itemLabel="Klant"
              canCreateItem={false}
              onCreate={this.handleSearchCreate}
              onSelect={this.handleSearchSelect}
              onClear={this.handleSearchClear}
              setClear={clear => (this.clearSearch = clear)}
              renderLabel={item => {
                let label = "";

                if (item) {
                  if (item.fields) {
                    label = item.fields.name;
                    if (item.fields.phone) {
                      label += ` (${item.fields.phone})`;
                    }
                  } else if (item.label) {
                    label = item.label;
                  }
                }

                return label;
              }}
            />
          </ListItem>
          {customerId && (
            <div>
              <ListSubheader className={classes.blockItem}>Info</ListSubheader>
              <ListItem className={classes.blockItem}>
                <Customer
                  shop={shop}
                  id={customerId}
                  onClear={this.handleSearchClear}
                />
              </ListItem>
              <ListSubheader className={classes.blockItem}>
                Afspraken
              </ListSubheader>
              <Slots shop={shop} customerId={customerId} products={products} />
            </div>
          )}
        </List>
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(CustomersPage));
