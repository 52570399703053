import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';

import { findInArrayByPropVal } from '../utils';

import InputLabel from '@material-ui/core/InputLabel';

import { withStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;

const styles = theme => ({
    products: {
        width: '100%'
    },
    productsTitle: {
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        textTransform: 'uppercase'
    },
    productsSelect: {
        width: '100%',
        marginTop: 10
    }
});

class Products extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSelectedProductsChange = this.handleSelectedProductsChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleSelectedProductsChange(selectedProducts, change) {
    const selected = selectedProducts.map((item) => {
        return {
            id: item.value
        };
    });

    this.props.onChange(selected, change);
  }

  render() {
    const { classes, title, available, selected } = this.props;

    let availableProducts, selectedProducts;

    if(available) {
        availableProducts = available.map((product) => {
            return {
                value: product.id,
                label: product.name
            };
        });
    }
    if(selected) {
        selectedProducts = selected.map((product) => {
            const found = findInArrayByPropVal(available, 'id', product.id);

            return {
                value: product.id,
                label: found && found.name ? found.name : '(unknown)'
            };
        });
    }

    return (
        <div className={ classes.products }>
            <InputLabel shrink htmlFor="products" className={ classes.productsTitle }>{ title }</InputLabel>
            <ReactSelect
                inputProps={{
                    name: 'products',
                    id: 'products'
                }}
                value={ selectedProducts }
                onChange={ this.handleSelectedProductsChange }
                options={ availableProducts }
                className={ classes.productsSelect }
                isMulti="true"
                isSearchable="true"
                placeholder="Selecteer een product..."
                noOptionsMessage={inputValue => "Geen producten gevonden."}
            />
        </div>
    );
  }
}

export default withStyles(styles)(Products);
