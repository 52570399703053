import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BlockIcon from "@material-ui/icons/Block";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import TodayIcon from "@material-ui/icons/Today";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";

import Moment from "react-moment";
import { Typography } from "@material-ui/core";

import { beautifyPhone } from "../utils";

const styles = theme => ({
  disabledSlot: {
    opacity: 1
  },
  smallSlot: {
    margin: 1,
    padding: "5px 10px"
  },
  bookedSlot: {
    background: "rgba(200, 200, 200, 0.1)"
  },
  slotStyle: {
    position: "absolute",
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  slotLine: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 2,
    height: "100%",
    background: "black",
    content: `''`
  },
  slotBackground: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    background: "black",
    opacity: 0.1,
    content: `''`
  },
  statusIcon: {
    marginTop: -2,
    marginLeft: 4,
    verticalAlign: "middle",
    fontSize: 16
  },
  check: {
    color: "green"
  },
  customer: {
    position: "relative",
    zIndex: 10,
    padding: 0
  },
  button: {
    zIndex: 10
  },
  smallTime: {
    fontSize: 14
  },
  smallCustomerName: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 14,
    fontWeight: "bold",
    color: "black"
  },
  smallPhone: {
    fontSize: 12
  },
  smallButton: {
    marginRight: 0
  },
  smallIcon: {
    fontSize: 20
  },
  customerName: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20,
    fontWeight: "bold",
    color: "black"
  }
});

class AppointmentSlot extends PureComponent {
  constructor() {
    super();

    this.state = {};
  }

  getSlot() {
    let slot = {};

    for (let key in this.props) {
      if (
        key !== "classes" &&
        this.props[key] !== undefined &&
        typeof this.props[key] !== "function"
      ) {
        slot[key] = this.props[key];
      }
    }

    return slot;
  }

  render() {
    const {
      id,
      size,
      color,
      slotTime,
      data,
      handleSlotClick,
      handleRemoveSlotClick,
      disabled,
      classes
    } = this.props;

    const styles = {
      background: color ? color : "black"
    };

    return (
      <ListItem
        button
        disabled={disabled}
        onClick={handleSlotClick}
        classes={{
          root:
            size === "small"
              ? `${classes.smallSlot} ${classes.bookedSlot}`
              : "",
          disabled: classes.disabledSlot
        }}
      >
        <div className={classes.slotStyle}>
          <div className={classes.slotBackground} style={styles} />
          <div className={classes.slotLine} style={styles} />
        </div>
        {size !== "small" && (
          <ListItemIcon>
            <TodayIcon className={classes.today} />
          </ListItemIcon>
        )}
        <ListItemText className={classes.customer}>
          <Typography className={size === "small" ? classes.smallTime : ""}>
            {`${slotTime}`}
            {data && (
              <span>
                {data.isNoShow ? (
                  <BlockIcon className={classes.statusIcon} />
                ) : (
                  <span>
                    {data.payment && (
                      <span>
                        {data.payment.method &&
                          (data.payment.method === "cash" ? (
                            <EuroSymbolIcon className={classes.statusIcon} />
                          ) : data.payment.method === "bancontact" ? (
                            <CreditCardIcon className={classes.statusIcon} />
                          ) : data.payment.method === "cadeaubon" ? (
                            <CardGiftcardIcon className={classes.statusIcon} />
                          ) : (
                            <HelpOutlineIcon className={classes.statusIcon} />
                          ))}
                        {data.payment.coupon && (
                          <CardGiftcardIcon className={classes.statusIcon} />
                        )}
                      </span>
                    )}
                  </span>
                )}
              </span>
            )}
          </Typography>
          {data && data.customer && (
            <div>
              <Typography
                className={
                  size === "small"
                    ? classes.smallCustomerName
                    : classes.customerName
                }
              >{`${data.customer.name}`}</Typography>
              {data.customer.phone && size !== "small" && (
                <Typography
                  className={size === "small" ? classes.smallPhone : ""}
                >{`${beautifyPhone(data.customer.phone)}`}</Typography>
              )}
            </div>
          )}
        </ListItemText>
        {!disabled && (
          <ListItemIcon
            className={`${classes.button} ${
              size === "small" ? classes.smallButton : ""
            }`}
            onClick={handleRemoveSlotClick}
          >
            <DeleteIcon className={size === "small" ? classes.smallIcon : ""} />
          </ListItemIcon>
        )}
      </ListItem>
    );
  }
}

export default withStyles(styles)(AppointmentSlot);
