import React, { Component } from "react";
import PropTypes from "prop-types";

import { store } from "../firebase";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Moment from "react-moment";

import { filterNotRemoved } from "../utils";

const styles = (theme) => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontFamily: "calligraffitiregular", //'shorelines_script_boldregular',
    fontSize: 32,
    color: "black"
  },
  service: {
    fontSize: 16,
    padding: "20px 0"
  }
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class SelectService extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onEntering = this.onEntering.bind(this);
    this.handleServiceConfirm = this.handleServiceConfirm.bind(this);
  }

  componentDidMount() {
    const { shop } = this.props;

    this.unsubscribeShopServices = store.onGetShopServices(
      shop.id,
      (services) => {
        this.setState({ services: services });
      }
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeShopServices) {
      this.unsubscribeShopServices();
      this.unsubscribeShopServices = undefined;
    }
  }

  onEntering() {
    const { shop } = this.props;
    if (shop) {
      this.setState({ serviceId: shop.serviceId });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  handleServiceChange(serviceId) {
    //this.setState({ serviceId : serviceId });

    const { shop } = this.props;
    shop.update({ serviceId: serviceId });
    this.handleClose();
  }

  handleServiceConfirm() {
    const { shop } = this.props;
    const { serviceId } = this.state;

    shop.update({ serviceId: serviceId });

    this.handleClose();
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  /*handleGoToLanding = () => {
    window.location.replace("/");
  };*/

  render() {
    const { classes, open, onClose, shop, ...other } = this.props;
    const { services, serviceId } = this.state;

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        onEntering={this.onEntering}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="Type kiezen"
        shop={shop}
      >
        <DialogTitle onClose={this.handleClose}>
          <div className={classes.dialogTitle}>
            <span className={classes.title}>Afspraak kiezen</span>
          </div>
        </DialogTitle>
        {services && services.length > 0 ? (
          <List>
            <ListItem>
              <ListItemText>
                Kies hieronder het soort afspraak dat je wil maken. Je kan dit
                steeds wijzigen onderaan de agenda.
              </ListItemText>
            </ListItem>
            {filterNotRemoved(services).map((service) => (
              <ListItem
                key={service.id}
                onClick={() => {
                  this.handleServiceChange(service.id);
                }}
                dense
                button
              >
                <ListItemText
                  primary={<strong>{service.name}</strong>}
                  className={classes.service}
                />
                <ListItemSecondaryAction>
                  <Radio
                    onClick={() => {
                      this.handleServiceChange(service.id);
                    }}
                    checked={serviceId === service.id}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemText>Er werden geen types gevonden</ListItemText>
            </ListItem>
          </List>
        )}
      </Dialog>
    );
  }
}

SelectService.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  serviceId: PropTypes.string
};

export default withMobileDialog()(withStyles(styles)(SelectService));

/*<IconButton
  aria-label="Sluiten"
  color="secondary"
  onClick={this.handleGoToLanding}
>
  <CloseIcon />
</IconButton>
*/
