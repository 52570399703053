import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import ReactMoment from "react-moment";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import MonthPopup from "../components/MonthPopup";

import { jsDateToDateString, dateStringToJSDate } from "../utils";

const styles = theme => ({
  header: {
    boxShadow: "none",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  title: {
    flex: 1,
    marginTop: "8px",
    marginBottom: "-4px",
    textTransform: "lowercase",
    textAlign: "center"
  },
  day: {
    fontFamily: "calligraffitiregular", //'shorelines_script_boldregular' 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: "bold",
    color: "black"
  },
  picker: {
    display: "flex",
    flexWrap: "wrap"
  },
  dateField: {
    left: "50%",
    transform: "translate(-50%, 0)",
    marginTop: -7,
    marginLeft: 33,
    marginRight: theme.spacing.unit,
    width: 140
  },
  resize: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 16,
    textTransform: "uppercase",
    color: "#f50057"
  },
  hidden: {
    visibility: "hidden"
  }
});

class DayHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
    //this.handleDateBlur = this.handleDateBlur.bind(this);
    this.previousDay = this.previousDay.bind(this);
    this.nextDay = this.nextDay.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  handleDateChange(date) {
    const { shop } = this.props;

    if (date) {
      let jsDate = date.add(2, "hours").toDate(); // GMT+2

      if (dateStringToJSDate(shop.date) !== jsDate) {
        this.changeDate(jsDate);
      }
    }
  }

  previousDay() {
    return this.increaseDate(-1);
  }

  nextDay() {
    return this.increaseDate(1);
  }

  increaseDate(increment) {
    const { shop } = this.props;
    let newDate = new Date(shop.date);
    newDate.setDate(newDate.getDate() + increment);
    this.changeDate(newDate);
  }

  changeDate(date) {
    const { parseDate, onDateChanged } = this.props;

    if (parseDate) {
      date = parseDate(date);
    }

    onDateChanged(date);
  }

  handleMonthPopupOpen = event => {
    this.setState({ monthAnchor: event.currentTarget });
  };

  handleMonthPopupClose = (date) => {
    const { shop, onDateChanged, requestAvailability } = this.props;

    this.setState({ monthAnchor: null });

    if(date && date !== shop.date) {
      onDateChanged(date);
    }
  };

  render() {
    const {
      classes,
      shop,
      barbers,
      services,
      availability,
      canGoBack,
      canGoForward,
      minDate,
      maxDate,
      hasMonthPopup,
      requestAvailability,
      className
    } = this.props;
    const { monthAnchor } = this.state;

    return (
      <div>
        {hasMonthPopup && (
          <MonthPopup
            anchor={monthAnchor}
            shop={shop}
            barbers={barbers}
            services={services}
            availability={availability}
            requestAvailability={requestAvailability}
            onClose={this.handleMonthPopupClose}
          />
        )}
        <AppBar
          position="fixed"
          color="default"
          className={`${classes.header} ${className ? className : ""}`}
        >
          <Toolbar>
            <IconButton
              className={canGoBack ? undefined : classes.hidden}
              color="secondary"
              aria-label="Vorige"
              onClick={this.previousDay}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
            >
              <ReactMoment
                onClick={this.handleMonthPopupOpen}
                className={classes.day}
                locale="nl-be"
                format="dddd"
              >
                {shop.date}
              </ReactMoment>
              <form className={classes.picker} noValidate>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="nl-be">
                  <DatePicker
                    color="secondary"
                    id="date"
                    value={jsDateToDateString(shop.date)}
                    className={classes.dateField}
                    onChange={this.handleDateChange}
                    //onBlur={this.handleDateBlur}
                    format="DD/MM/YYYY"
                    autoOk={true}
                    showTodayButton={true}
                    okLabel="OK"
                    cancelLabel="Annuleren"
                    todayLabel="Vandaag"
                    minDate={minDate}
                    maxDate={maxDate}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.resize
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>
              </form>
            </Typography>
            <IconButton
              className={canGoForward ? undefined : classes.hidden}
              color="secondary"
              aria-label="Volgende"
              onClick={this.nextDay}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(DayHeader);
