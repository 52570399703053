import React, { PureComponent } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from "@material-ui/core/styles";

import Search from "../components/Search";

const styles = theme => ({});

class Customers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleSearchCreate = inputValue => {};

  handleSearchSelect = (id, label, fields) => {
    this.props.onSelect(id, label, fields);
    this.handleClose();
  };

  handleSearchClear = () => {};

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { anchor, shop, omitItems, classes } = this.props;

    const open = Boolean(anchor);

    return (
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={this.handleClose}
        PaperProps={{
          style: {
            overflow: "visible",
            width: "80%",
            maxWidth: 500,
            //margin: 25
          }
        }}
        MenuListProps={{
          style: {
            justifyContent: "center",
            padding: 0
          }
        }}
        BackdropProps={{ style: { background: "rgba(0,0,0,0.5)" } }}
      >
        <Search
          shop={shop}
          collection="customers"
          collectionLabel="Klanten"
          itemLabel="Klant"
          canCreateItem={false}
          onCreate={this.handleSearchCreate}
          onSelect={this.handleSearchSelect}
          onClear={this.handleSearchClear}
          omitItems={omitItems}
          className={classes.search}
          renderLabel={item => {
            let label = "";

            if (item) {
              if (item.fields) {
                label = item.fields.name;
                if (item.fields.phone) {
                  label += ` (${item.fields.phone})`;
                }
              } else if (item.label) {
                label = item.label;
              }
            }

            return label;
          }}
        />
      </Menu>
    );
  }
}

export default withStyles(styles)(Customers);
