import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import ReactMoment from 'react-moment';

const styles = theme => ({
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: '10px 0'
    },
    dayName: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    },
    dayNumber: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        width: 30,
        height: 30,
        borderRadius: 15,
        margin: '0 5px',
        background: 'black',
        color: 'white'
    }
});

class WeekDayHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    const { classes, date } = this.props;

    return (
      <div className={ classes.header }>
        <div className={ classes.dayName }>
            <ReactMoment locale="nl-be" format="ddd">
                { date }
            </ReactMoment>
        </div>
        <div className={ classes.dayNumber }>
            <ReactMoment locale="nl-be" format="D">
                { date }
            </ReactMoment>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(WeekDayHeader);
