import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import { Helmet } from "react-helmet";

import AuthContext from "../context/auth";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";

import { withStyles } from "@material-ui/core/styles";

import {
  minutesToTime,
  makeCancelable,
  filterNotRemoved,
  formatToTwoDecimals,
  compareObjects,
  getGoogleMapsUrlForShop,
  orderArray
} from "../utils";

import { storage } from "../firebase";

import withShop from "./withShop";

const BLOCK_BACKGROUND_IMAGE_1 = "/img/background_2.jpg";
const BLOCK_BACKGROUND_IMAGE_2 = "/img/background_3.jpg";

const LOGO_IMAGE_FALLBACK = "/img/logo.png";
const LAUNCH_IMAGE_FALLBACK = "/img/background.jpg";
const STORY_IMAGE_FALLBACK = "/img/background_2.jpg";

const styles = (theme) => ({
  container: {
    position: "absolute",
    top: 0,
    width: "100%"
  },
  menuButton: {
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    color: "#ccc"
  },
  block: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    backgroundColor: "white",
    color: "black"
  },
  fixedHeightBlock: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
    color: "black"
  },
  imageBlock: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    color: "white",
    "&::before": {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      content: `''`
    }
  },
  fixedHeightImageBlock: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    color: "white",
    "&::before": {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      content: `''`
    }
  },
  content: {
    zIndex: 10,
    position: "relative",
    margin: 60,
    textAlign: "center"
  },
  smallContent: {
    zIndex: 10,
    position: "relative",
    margin: 20,
    textAlign: "center"
  },
  fixedContent: {
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  title: {
    margin: "0 20px 70px 20px",
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 50,
    textAlign: "center",
    textShadow: "rgba(0, 0, 0, 0.4) 0px 0px 16px"
  },
  subtitle: {
    margin: "10px",
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 30,
    textAlign: "center",
    textTransform: "capitalize"
  },
  subsubtitle: {
    margin: "10px",
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase"
  },
  subsubsubtitle: {
    margin: "10px",
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 16,
    textAlign: "center",
    textTransform: "uppercase"
  },
  written: {
    marginBottom: 0,
    fontFamily: "shorelines_script_boldregular",
    fontSize: 25,
    textAlign: "center",
    textShadow: "rgba(0, 0, 0, 0.4) 0px 0px 16px"
  },
  logoImg: {
    width: "100vw",
    height: 150,
    margin: "20px 0 40px 0",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%"
  },
  tagline: {
    margin: 20,
    textAlign: "center"
  },
  go: {
    display: "block",
    left: "50%",
    transform: "translateX(-50%)",
    margin: "40px 0",
    borderRadius: 3,
    backgroundColor: "#e91e63",
    color: "white"
  },
  link: {
    textDecoration: "none"
  },
  barbers: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  barber: {
    minWidth: 250,
    margin: 50
  },
  barberImg: {
    display: "inline-block",
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover"
  },
  day: {
    display: "flex",
    marginTop: 25
  },
  hours: {
    flexGrow: 1,
    fontWeight: "bold",
    textAlign: "right"
  },
  hour: {
    display: "block"
  },
  story: {
    display: "inline-block",
    maxWidth: 600
  },
  part: {
    margin: "40px 0"
  },
  provider: {
    margin: 15
  },
  providerImage: {
    height: 30
  },
  email: {
    textTransform: "lowercase",
    textDecoration: "none",
    color: "#3f51b5"
  },
  callToAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200
  },
  services: {
    margin: 50
  },
  service: {
    margin: "20px 0"
  },
  address: {
    margin: "10px 0"
  },
  map: {
    width: "100%",
    maxWidth: 600
  },
  appLogo: {
    height: 50,
    margin: 10
  },
  appLinks: {
    fontSize: 12,
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.9)"
  },
  appLink: {
    display: "inline-block",
    margin: 10,
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.8)"
  }
});

const DAYS = [
  { name: "Maandag", index: 1 },
  { name: "Dinsdag", index: 2 },
  { name: "Woensdag", index: 3 },
  { name: "Donderdag", index: 4 },
  { name: "Vrijdag", index: 5 },
  { name: "Zaterdag", index: 6 },
  { name: "Zondag", index: 0 }
];

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshShopImages();
    this.refreshBarberImages();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { info, barbers } = this.props;

    const prevInfo = prevProps.info;
    const prevBarbers = prevProps.barbers;

    const assetFilenames =
      info && info.assets ? Object.values(info.assets) : undefined;
    const prevAssetFilenames =
      prevInfo && prevInfo.assets ? Object.values(prevInfo.assets) : undefined;

    const barberImageFilenames = barbers
      ? barbers.map((barber) => barber.imageFilename)
      : undefined;
    const prevBarberImageFilenames = prevBarbers
      ? prevBarbers.map((barber) => barber.imageFilename)
      : undefined;

    if (!compareObjects(assetFilenames, prevAssetFilenames)) {
      this.refreshShopImages();
    }
    if (!compareObjects(barberImageFilenames, prevBarberImageFilenames)) {
      this.refreshBarberImages();
    }
  }

  refreshShopImages() {
    const { shop, info } = this.props;

    if (shop && shop.id && info && info.assets) {
      storage
        .getShopImages(shop.id, info.assets)
        .then((shopImages) => {
          this.setState({ shopImages });
        })
        .catch((error) => {
          this.setState({ shopImages: null });

          console.warn(error);
        });
    } else {
      this.setState({ shopImages: null });
    }
  }

  refreshBarberImages() {
    const { shop, barbers } = this.props;

    if (shop && shop.id && barbers) {
      storage
        .getBarberImages(
          shop.id,
          barbers
            .map((barber) => barber.imageFilename)
            .filter((filename) => filename !== undefined)
        )
        .then((barberImages) => {
          this.setState({ barberImages });
        })
        .catch((error) => {
          this.setState({ barberImages: null });

          console.warn(error);
        });
    } else {
      this.setState({ barberImages: null });
    }
  }

  obfuscateEmail(email) {
    return window.obfuscateVal(email);
  }

  getGoogleMapsUrl() {
    const { info } = this.props;

    if (info) {
      return getGoogleMapsUrlForShop(info.name, info.address);
    }
  }

  render() {
    const {
      classes,
      shop,
      info,
      barbers,
      services,
      toggleDrawer,
      auth
    } = this.props;
    const { shopImages, barberImages } = this.state;

    /*if (shop && shop.barberId && shop.serviceId) {
      return <Redirect to="/home" />;
    }*/

    let title = "Boek je Kapper";
    let description = "Boek hier je afspraak.";
    let url = "https://app.boekjekapper.be/shops";
    if (info) {
      if (info.name) {
        title = info.name;
      }
      if (info.story) {
        description = info.story.join("\n");
      }
      if (info.website) {
        url = info.website;
      }
    }

    const filteredServices = filterNotRemoved(services);

    const googleMapsUrl = this.getGoogleMapsUrl();

    return (
      <div>
        {info && (
          <div className={classes.container}>
            <Helmet>
              <title>{title}</title>
              <meta charSet="utf-8" />
              <meta name="title" content={title} />
              <meta name="description" content={description} />
              <meta
                name="image"
                content={shopImages ? shopImages.launch : LAUNCH_IMAGE_FALLBACK}
              />
              <link rel="canonical" href={url} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={url} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta
                property="og:image"
                content={shopImages ? shopImages.launch : LAUNCH_IMAGE_FALLBACK}
              />
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content={url} />
              <meta property="twitter:title" content={title} />
              <meta property="twitter:description" content={description} />
              <meta
                property="twitter:image"
                content={shopImages ? shopImages.launch : LAUNCH_IMAGE_FALLBACK}
              />
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "image": ["${
                      shopImages ? shopImages.logo : LOGO_IMAGE_FALLBACK
                    }", "${
                  shopImages ? shopImages.launch : LAUNCH_IMAGE_FALLBACK
                }", "${shopImages ? shopImages.story : STORY_IMAGE_FALLBACK}"],
                    "@id": "${url}",
                    "name": "${title}",
                    "url": "${url}",
                    "acceptsReservations": "True"
                  }
                `}
              </script>
            </Helmet>
            {auth && auth.isCrew && (
              <IconButton
                className={`AppDrawerToggle ${classes.menuButton}`}
                color="inherit"
                aria-label="Menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            )}
            <div
              className={classes.fixedHeightImageBlock}
              style={{
                backgroundImage: `url("${
                  shopImages ? shopImages.launch : LAUNCH_IMAGE_FALLBACK
                }")`
              }}
            >
              <div className={classes.fixedContent}>
                <h2 className={classes.written}>welkom bij</h2>
                {shopImages && shopImages.logo ? (
                  <div
                    className={classes.logoImg}
                    style={{
                      backgroundImage: `url("${shopImages.logo}")`
                    }}
                  />
                ) : (
                  <h1 className={classes.title}>{info.name}</h1>
                )}

                {info.tagline && (
                  <p className={classes.tagline}>{info.tagline}</p>
                )}
                <Link to={routes.HOME} className={classes.link}>
                  <Button variant="contained" className={classes.go}>
                    Maak een afspraak
                  </Button>
                </Link>
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.content}>
                {barbers && barbers.length > 1 && (
                  <h2 className={classes.subtitle}>
                    {info && info.profession && info.profession.plural
                      ? info.profession.plural
                      : "Barbers"}
                  </h2>
                )}
                <div className={classes.barbers}>
                  {barbers &&
                    orderArray(barbers, false, "order").map((barber) => (
                      <div key={barber.id} className={classes.barber}>
                        {barberImages && barberImages[barber.imageFilename] && (
                          <div
                            className={classes.barberImg}
                            style={{
                              backgroundImage: `url("${
                                barberImages[barber.imageFilename]
                              }")`
                            }}
                          />
                        )}
                        <h3 className={classes.subsubtitle}>{barber.name}</h3>
                        {barber.phoneNumber && (
                          <h4 className={classes.subsubsubtitle}>
                            {barber.phoneNumber.split("").map((char, index) => (
                              <span key={index}>{char}</span>
                            ))}
                          </h4>
                        )}
                        {barber.emailAddress && (
                          <h4 className={classes.subsubsubtitle}>
                            <a
                              className={classes.email}
                              href={`javascript:window.revealEmail('${this.obfuscateEmail(
                                barber.emailAddress
                              )}');`}
                            >
                              {barber.emailAddress
                                .split("")
                                .map((char, index) => (
                                  <span key={index}>{char}</span>
                                ))}
                            </a>
                          </h4>
                        )}
                        {barber.openingHours &&
                          DAYS.map((day) => (
                            <div key={day.index} className={classes.day}>
                              <div>{day.name}</div>
                              <div className={classes.hours}>
                                {barber.openingHours[day.index]
                                  ? barber.openingHours[day.index].map(
                                      (openingHours, index) => (
                                        <div
                                          key={index}
                                          className={classes.hour}
                                        >
                                          {minutesToTime(openingHours.begin)}
                                          {" - "}
                                          {minutesToTime(openingHours.end)}
                                        </div>
                                      )
                                    )
                                  : "Gesloten"}
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
              <div
                className={classes.imageBlock}
                style={{
                  backgroundImage: `url("${BLOCK_BACKGROUND_IMAGE_1}")`
                }}
              >
                <div className={classes.callToAction}>
                  <Link to={routes.HOME} className={classes.link}>
                    <Button variant="contained" className={classes.go}>
                      Maak een afspraak
                    </Button>
                  </Link>
                </div>
              </div>
              <div className={classes.block}>
                <div className={classes.content}>
                  {filteredServices && filteredServices.length > 1 && (
                    <h2 className={classes.subtitle}>Behandelingen</h2>
                  )}
                  <div className={classes.services}>
                    {filteredServices &&
                      filteredServices.map((service) => (
                        <div key={service.id} className={classes.service}>
                          <h3 className={classes.subsubtitle}>
                            {service.name}
                          </h3>
                          {service.price && (
                            <h4 className={classes.subsubsubtitle}>
                              {`€ ${formatToTwoDecimals(service.price)}`}
                            </h4>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classes.imageBlock}
              style={{
                backgroundImage: `url("${
                  shopImages ? shopImages.story : STORY_IMAGE_FALLBACK
                }")`
              }}
            >
              <div className={classes.content}>
                <div className={classes.story}>
                  {info.story &&
                    info.story.map((part, index) => (
                      <p key={index} className={classes.part}>
                        {part}
                      </p>
                    ))}
                </div>
                <div className={classes.social}>
                  {info.social &&
                    info.social.map((provider, index) => (
                      <a
                        key={index}
                        className={classes.provider}
                        href={provider.url}
                        target="_blank"
                      >
                        <img
                          className={classes.providerImage}
                          src={`/img/social/${provider.name}.png`}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </div>
            <div className={classes.block}>
              <div className={classes.content}>
                <h2 className={classes.subtitle}>Praktisch</h2>
                {info.address && (
                  <div className={classes.address}>
                    <div>{`${info.address.street} ${info.address.number} ${info.address.box}`}</div>
                    <div>{`${info.address.zip} ${info.address.city}`}</div>
                  </div>
                )}
                {googleMapsUrl && (
                  <iframe
                    className={classes.map}
                    src={googleMapsUrl}
                    width="400"
                    height="300"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                )}
                <Link to={routes.HOME} className={classes.link}>
                  <Button variant="contained" className={classes.go}>
                    Maak een afspraak
                  </Button>
                </Link>
              </div>
            </div>
            <div
              className={classes.imageBlock}
              style={{ backgroundImage: `url("${BLOCK_BACKGROUND_IMAGE_2}")` }}
            >
              <div className={classes.smallContent}>
                <img className={classes.appLogo} src="/img/logo_white.png" />
                <div className={classes.appLinks}>
                  <a
                    className={`${classes.appLink} blockOnMobile`}
                    href="https://boekjekapper.be"
                    target="_blank"
                  >
                    Meer Info
                  </a>
                  <span className="hideOnMobile">&bull;</span>
                  <a
                    className={`${classes.appLink} blockOnMobile`}
                    href="https://boekjekapper.be/algemene-voorwaarden"
                    target="_blank"
                  >
                    Algemene Voorwaarden
                  </a>
                  <span className="hideOnMobile">&bull;</span>
                  <a
                    className={`${classes.appLink} blockOnMobile`}
                    href="https://boekjekapper.be/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <AuthContext.Consumer>
    {(auth) => <Landing {...props} auth={auth} />}
  </AuthContext.Consumer>
));

// https://developers.google.com/search/docs/data-types/local-business
//"telephone": "+12122459600",
/*"geo": {
  "@type": "GeoCoordinates",
  "latitude": 40.761293,
  "longitude": -73.982294
},*/
/*"address": {
  "@type": "PostalAddress",
  "streetAddress": "148 W 51st St",
  "addressLocality": "New York",
  "addressRegion": "NY",
  "postalCode": "10019",
  "addressCountry": "US"
},*/
/*openingHoursSpecification: [
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": ["Monday", "Tuesday"],
    "opens": "11:30",
    "closes": "22:00"
  },
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": ["Wednesday", "Thursday", "Friday"],
    "opens": "11:30",
    "closes": "23:00"
  },
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": "Saturday",
    "opens": "16:00",
    "closes": "23:00"
  },
  {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": "Sunday",
    "opens": "16:00",
    "closes": "22:00"
  }
],*/
