import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import { List, TextField, ListItem, Typography } from "@material-ui/core";

import EditAddress from "./EditAddress";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  field: {
    padding: "16px 0"
  },
  addressContainer: {
    width: "100%",
    margin: "8px 0 4px 0"
  },
  addressLabel: {
    paddingBottom: 5
  },
  addressDescription: {
    paddingTop: 5
  }
});

class ShopGeneralInfoFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleTextChange = (name) => (event) => {
    let value = event.target.value;
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, [name]: value });
  };

  handleAddressChange = (address) => {
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, address });
  };

  render() {
    const { classes, open, onClose, shop, info, ...other } = this.props;
    const { name, website, address } = info;

    return (
      <div className={classes.content}>
        <List>
          <ListItem className={classes.field}>
            <TextField
              margin="dense"
              id="name"
              label="Naam"
              helperText="Wat is de naam van je zaak?"
              fullWidth
              value={name}
              onChange={this.handleTextChange("name")}
            />
          </ListItem>
          <ListItem className={classes.field}>
            <TextField
              margin="dense"
              id="website"
              label="Website"
              helperText="Wat is de website van je zaak?"
              fullWidth
              value={website}
              onChange={this.handleTextChange("website")}
            />
          </ListItem>
          <ListItem className={classes.field}>
            <div className={classes.addressContainer}>
              <Typography variant="caption" className={classes.addressLabel}>
                Adres
              </Typography>
              <EditAddress
                info={info}
                address={address}
                onChange={this.handleAddressChange}
                showMaps={true}
              />
            </div>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(ShopGeneralInfoFields);
