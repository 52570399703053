import React, { Component } from "react";

import BookSlot from "./BookSlot";
import Confirmation from "./Confirmation";
import Loading from "./Loading";
import Error from "./Error";
import SelectBarber from "./SelectBarber";
import SelectService from "./SelectService";
import SelectSlot from "./SelectSlot";
import VerifyPhone from "./VerifyPhone";
import AddHoliday from "./AddHoliday";
import EditSlot from "./EditSlot";
import AddService from "./AddService";
import EditService from "./EditService";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import EditBarber from "./EditBarber";
import EditShop from "./EditShop";

import * as modals from "../constants/modals";

class Conductor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, current, closeModal, ...other } = this.props;

    if (!open) {
      return null;
    }

    switch (current) {
      case modals.BOOK_SLOT:
        return <BookSlot open={open} onClose={closeModal} {...other} />;

      case modals.CONFIRMATION:
        return <Confirmation open={open} onClose={closeModal} {...other} />;

      case modals.LOADING:
        return <Loading open={open} onClose={closeModal} {...other} />;

      case modals.ERROR:
        return <Error open={open} onClose={closeModal} {...other} />;

      case modals.SELECT_BARBER:
        return <SelectBarber open={open} onClose={closeModal} {...other} />;

      case modals.SELECT_SERVICE:
        return <SelectService open={open} onClose={closeModal} {...other} />;

      case modals.SELECT_SLOT:
        return <SelectSlot open={open} onClose={closeModal} {...other} />;

      case modals.VERIFY_PHONE:
        return <VerifyPhone open={open} onClose={closeModal} {...other} />;

      case modals.ADD_HOLIDAY:
        return <AddHoliday open={open} onClose={closeModal} {...other} />;

      case modals.EDIT_SLOT:
        return <EditSlot open={open} onClose={closeModal} {...other} />;

      case modals.ADD_SERVICE:
        return <AddService open={open} onClose={closeModal} {...other} />;

      case modals.EDIT_SERVICE:
        return <EditService open={open} onClose={closeModal} {...other} />;

      case modals.ADD_PRODUCT:
        return <AddProduct open={open} onClose={closeModal} {...other} />;

      case modals.EDIT_PRODUCT:
        return <EditProduct open={open} onClose={closeModal} {...other} />;

      case modals.EDIT_BARBER:
        return <EditBarber open={open} onClose={closeModal} {...other} />;

      case modals.EDIT_SHOP:
        return <EditShop open={open} onClose={closeModal} {...other} />;

      default:
        return null;
    }
  }
}

export default Conductor;
