import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import { pickRandomElementFromArray } from "../utils";

const quotes = [
  "Being a barber is about taking care of the people.",
  "Barbershop is not a hobby, it's a lifestyle.",
  "You don't ever ask a barber whether you need a haircut.",
  "Your barber always knows everything that goes on in the town, doesn't he?",
  "To make a fine gentleman, several trades are required, but chiefly a barber.",
  "Asking for financial advice from a financial planner is like asking a barber if you need a hair cut.",
  "Keep calm and never piss off a barber.",
  "A barber is the only person whose conversation you can follow, even though he talks over your head.",
  "Love is in the hair.",
  "Life isn't perfect but your hair can be.",
  "Your hair is 99% of your selfies.",
  "Great hair doesn't happen by chance, it happens by appointment."
];

const styles = theme => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh"
  },
  quote: {
    position: "absolute",
    bottom: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxWidth: 400,
    textAlign: "center"
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: pickRandomElementFromArray(quotes)
    };
  }

  render() {
    const { classes, ...other } = this.props;

    const { quote } = this.state;

    return (
      <div className={classes.container}>
        <p className={classes.quote}>{quote}</p>
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Loading);
