import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { minutesToTime, timeToMinutes } from "../utils";

import * as days from "../constants/days";

const styles = theme => ({
  day: {
    minHeight: 210
  },
  hours: {
    marginLeft: 20
  },
  hour: {
    marginLeft: 20,
    marginRight: 20
  },
  center: {
    display: "flex",
    justifyContent: "center"
  }
});

class EditDayHours extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    };
  }

  componentDidMount() {
    const { dayHours } = this.props;

    const newState = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    };

    if (dayHours) {
      days.ALL.forEach(day => {
        if (dayHours[day.index]) {
          const newDay = this.sortHours(dayHours[day.index]);

          newDay.forEach(hours => {
            hours.error = hours.begin >= hours.end;
          });

          newState[day.name] = newDay;
        }
      });
    }

    this.setState(newState);
  }

  sortHours(hours) {
    return hours.sort((a, b) => a.begin - b.begin);
  }

  handleAddHoursClick = dayName => {
    //this.setState(state => {
    const day = this.state[dayName];

    const newHours = { begin: 600, end: 1080 };

    let max = -1;
    day.forEach(hours => {
      if (hours.end > max) {
        max = hours.end;
      }
    });
    if (max >= 0) {
      newHours.begin = max;
      newHours.end = max + 60;
    }

    this.updateDay(dayName, day.concat(newHours));

    //const newState = {};
    //newState[dayName] = day.concat(newHours);

    //return newState;
    //});
  };

  handleRemoveHoursClick = (dayName, hoursIndex) => {
    //this.setState(state => {
    const day = [].concat(this.state[dayName]);
    day.splice(hoursIndex, 1);

    this.updateDay(dayName, day);

    //const newState = {};
    //newState[dayName] = day;

    //return newState;
    //});
  };

  handleBeginHourChange = (dayName, hoursIndex, event) => {
    const day = [].concat(this.state[dayName]);
    try {
      day[hoursIndex].begin = timeToMinutes(event.target.value);

      this.updateDay(dayName, day);
    } catch (error) {
      console.warn(error);
    }
  };

  handleBeginHourBlur(dayName, hoursIndex, event) {
    let day = [].concat(this.state[dayName]);

    if (day[hoursIndex].begin >= day[hoursIndex].end) {
      day[hoursIndex].end = day[hoursIndex].begin;
      day[hoursIndex].error = true;
    } else {
      day[hoursIndex].error = false;
    }

    // Sort hours
    day = this.sortHours(day);

    // Check endHours
    for (let i = 0; i < day.length; i++) {
      let hours = day[i];
      if (i < day.length - 1) {
        const nextHours = day[i + 1];
        if (nextHours.begin < hours.end) {
          hours.end = nextHours.begin;
        }
      }

      if (hours.begin >= hours.end) {
        hours.error = true;
      } else {
        hours.error = false;
      }
    }

    this.updateDay(dayName, day);
  }

  handleEndHourChange = (dayName, hoursIndex, event) => {
    const day = [].concat(this.state[dayName]);
    try {
      day[hoursIndex].end = timeToMinutes(event.target.value);

      this.updateDay(dayName, day);
    } catch (error) {
      console.warn(error);
    }
  };

  handleEndHourBlur(dayName, hoursIndex, event) {
    const day = [].concat(this.state[dayName]);

    if (day[hoursIndex].end <= day[hoursIndex].begin) {
      day[hoursIndex].begin = day[hoursIndex].end;
      day[hoursIndex].error = true;
    } else {
      day[hoursIndex].error = false;
    }

    // Check endHours
    for (let i = hoursIndex; i < day.length; i++) {
      let hours = day[i];
      if (hours.begin >= hours.end) {
        hours.end = hours.begin;
        hours.error = true;
      } else {
        hours.error = false;
      }

      if (i < day.length - 1) {
        let nextHours = day[i + 1];
        if (nextHours.begin < hours.end) {
          nextHours.begin = hours.end;
        }
      }
    }

    this.updateDay(dayName, day);
  }

  updateDay(dayName, day) {
    const { onChange } = this.props;

    const newState = {};
    newState[dayName] = day;
    this.setState(newState, () => {
      if (onChange) {
        const dayHours = {};

        days.ALL.forEach(day => {
          const dayState = this.state[day.name];
          if (dayState && dayState.length > 0) {
            dayHours[day.index] = [];

            dayState.forEach(hours => {
              dayHours[day.index].push({
                begin: hours.begin,
                end: hours.end
              });
            });
          }
        });

        onChange(dayHours);
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <List>
          {days.ALL.map((day, index) => (
            <div key={day.name}>
              <ListSubheader className={classes.sectionHeader}>
                {day.label}
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      this.handleAddHoursClick(day.name);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListSubheader>
              <div className={classes.day}>
                {this.state[day.name].map((hours, hoursIndex) => (
                  <ListItem key={hoursIndex} className={classes.hours}>
                    <TextField
                      label="Van"
                      type="time"
                      value={minutesToTime(hours.begin)}
                      className={classes.hour}
                      error={hours.error}
                      onChange={event =>
                        this.handleBeginHourChange(day.name, hoursIndex, event)
                      }
                      onBlur={event =>
                        this.handleBeginHourBlur(day.name, hoursIndex, event)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                    />
                    <TextField
                      label="Tot"
                      type="time"
                      value={minutesToTime(hours.end)}
                      className={classes.hour}
                      error={hours.error}
                      onChange={event =>
                        this.handleEndHourChange(day.name, hoursIndex, event)
                      }
                      onBlur={event =>
                        this.handleEndHourBlur(day.name, hoursIndex, event)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          this.handleRemoveHoursClick(day.name, hoursIndex);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </div>
            </div>
          ))}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(EditDayHours);
