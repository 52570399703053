import React, { Component } from "react";

import { Redirect, Link } from "react-router-dom";

import AsyncSelect from "react-select/lib/Async";

import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

import * as routes from "../constants/routes";

const styles = theme => ({
  loading: {
    position: "absolute",
    zIndex: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  spinner: {
    //color: "rgba(255, 255, 255, 0.8)"
  },
  search: {}
});

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectValue: null,
      inputValue: ''
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    const { setClear } = this.props;

    if(setClear) {
      setClear(this.clear);
    }
  }

  clear() {
    this.setState({ selectValue: null });
  }

  loadOptions(inputValue, callback) {
    const { shop, collection, omitItems, renderLabel, itemLabel, canCreateItem } = this.props;

    inputValue = (inputValue ? inputValue.trim() : '');

    let staticItems = [];

    if(canCreateItem) {
      staticItems.push({
        type: "create",
        value: "create",
        label: `Maak een nieuwe ${itemLabel} '${inputValue}' aan...`
      });
    }

    setTimeout(() => {
      store.searchShop(shop.id, inputValue, collection).then(
        items => {
          const formattedItems = items.map(item => {
            return {
              value: item.id,
              label: renderLabel ? renderLabel(item) : item.label,
              fields: item.fields
            };
          });

          let filteredItems;
          if(omitItems) {
            filteredItems = formattedItems.filter((item) => {
              return omitItems.indexOf(item.value) < 0;
            });
          } else {
            filteredItems = formattedItems;
          }
          
          /*if(formattedItems.length === 0) {
            callback(staticItems);
          } else {
            callback(formattedItems);
          }*/

          callback([...filteredItems, ...staticItems]);
        },
        error => {
          console.warn(error);
        }
      );
    }, 0);

    this.setState({ inputValue });
  }

  handleChange(input, change) {
    const { onCreate, onSelect, onClear } = this.props;

    if (change) {
      switch (change.action) {
        case "select-option":
          if (input) {
            if (input.type === "create" && onCreate) {
              onCreate(this.state.inputValue);
            } else if (input.value && onSelect) {
              onSelect(input.value, input.label, input.fields);
            }
          }
          break;
        case "clear":
          if (onClear) {
            onClear();
          }
          break;
      }
    }

    this.setState({ selectValue: input });
  }

  render() {
    const { classes, collectionLabel, itemLabel, shop } = this.props;
    const { loading, selectValue } = this.state;

    return (
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner} />
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.search}>
              <AsyncSelect
                cacheOptions
                loadOptions={this.loadOptions}
                defaultOptions
                value={selectValue}
                isSearchable="true"
                isClearable="true"
                onChange={this.handleChange}
                placeholder={`Zoek een ${itemLabel}...`}
                loadingMessage={inputValue =>
                  `Laden...`
                }
                noOptionsMessage={inputValue =>
                  `Geen ${collectionLabel} gevonden.`
                }
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 255, 255, 0.9)"
                  }),
                  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    if(data && data.type === "create") {
                      return {
                        ... styles,
                        fontWeight: 900
                      };
                    }

                    return styles;
                  },
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex:999
                  })
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Search);
