import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import withShop from "../components/withShop";

import Landing from "../components/Landing";

const styles = (theme) => ({});

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, shop, info, barbers, services, toggleDrawer } = this.props;

    return (
      <Landing
        shop={shop}
        info={info}
        barbers={barbers}
        services={services}
        toggleDrawer={toggleDrawer}
      />
    );
  }
}

export default withShop()(withStyles(styles)(LandingPage));
