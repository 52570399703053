import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import TimelapseIcon from "@material-ui/icons/Timelapse";

import SelectAmount from "./SelectAmount";

const styles = (theme) => ({});

const DEFAULT_SPACING_MIN = 5;
const DEFAULT_SPACING_MAX = 55;
const DEFAULT_SPACING_STEP = 5;

class ControlSpacing extends Component {
  constructor(props) {
    super(props);

    const spacing = parseInt(props.spacing);
    this.state = {
      spacing: isNaN(spacing) ? 10 : spacing
    };

    this.handleSpacingChange = this.handleSpacingChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleSpacingChange(spacing) {
    this.setState({ spacing });

    if (this.props.handleSpacingChange) {
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout);
      }

      this.changeTimeout = setTimeout(() => {
        this.props.handleSpacingChange(spacing);
      }, 500);
    }
  }

  render() {
    const { classes, showIcon, step, min, max } = this.props;
    const { spacing } = this.state;

    return (
      <SelectAmount
        icon={showIcon ? <TimelapseIcon /> : undefined}
        unit="min."
        amount={spacing}
        step={step === undefined ? DEFAULT_SPACING_STEP : step}
        min={min === undefined ? DEFAULT_SPACING_MIN : min}
        max={max === undefined ? DEFAULT_SPACING_MAX : max}
        onChange={this.handleSpacingChange}
      />
    );
  }
}

export default withStyles(styles)(ControlSpacing);
