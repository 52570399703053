import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import MonthBar from "./MonthBar";

const styles = theme => ({
  header: {
    boxShadow: "none",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  title: {
    flex: 1,
    marginTop: "8px",
    marginBottom: "-4px",
    textAlign: "center",
    fontFamily: "calligraffitiregular", //"shorelines_script_boldregular"
  },
  titleText: {},
  titleDate: {
    margin: "0 5px",
    fontWeight: "bold"
  },
  week: {
    fontFamily: "calligraffitiregular", //"shorelines_script_boldregular" 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: "bold",
    color: "black"
  },
  picker: {
    display: "flex",
    flexWrap: "wrap"
  },
  dateField: {
    left: "50%",
    transform: "translate(-50%, 0)",
    marginTop: -7,
    marginLeft: 38,
    marginRight: theme.spacing.unit,
    width: 140
  },
  resize: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 14,
    textTransform: "uppercase",
    color: "#f50057"
  },
  hidden: {
    visibility: "hidden"
  }
});

class MonthHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      classes,
      date,
      onDateChanged,
      canGoBack,
      canGoForward,
      className
    } = this.props;

    return (
      <AppBar
        position="fixed"
        color="default"
        className={`${classes.header} ${className ? className : ""}`}
      >
        <MonthBar
          date={date}
          onDateChanged={onDateChanged}
          canGoBack={canGoBack}
          canGoForward={canGoForward}
        />
      </AppBar>
    );
  }
}

export default withStyles(styles)(MonthHeader);
