import React from "react";
import { withRouter, Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import AuthContext from "../context/auth";

import Loading from "./Loading";

const withCustomersOnly = () => Component => {
  class WithCustomersOnly extends React.Component {
    componentDidMount() {}

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth &&
            auth.ready ?
            (auth.shopId ? (
              auth.user && auth.isCrew ? (
                <Redirect to={routes.CONTROLROOM} />
              ) : (
                <Component {...this.props} auth={auth} />
              )
            ) : (
              <Redirect to={routes.SELECT_SHOP_EMPTY} />
            )) : (
              <Loading />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithCustomersOnly);
};

export default withCustomersOnly;
