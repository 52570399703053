import React, { PureComponent } from "react";

import Moment from "moment";
import ReactMoment from "react-moment";

import { withStyles } from "@material-ui/core/styles";

import AvailabilityFade from "./AvailabilityFade";

import {
  getAvailableSlots,
  getBeginAndEndHourFromDayHours,
  jsDateToDateString
} from "../utils";

const MIN_PREVIEW_SIZE = 34;
const BAR_WIDTH = 5;

const styles = theme => ({
  container: {
    display: "inline-block",
    overflow: "hidden",
    position: "relative",
    border: "2px solid #eee",
    borderRadius: 7,
    //boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.2)",
    cursor: "pointer"
  },
  disabled: {
    opacity: 0.3,
    filter: "grayscale(100%)"
  },
  bar: {
    position: "absolute",
    left: 0
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: BAR_WIDTH,
    height: "100%"
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    filter: "blur(8px)",
    opacity: 0.2
  },
  text: {
    zIndex: 10,
    width: "100%",
    textAlign: "center",
    color: "#333"
  },
  fadedText: {
    color: "#888"
  },
  selectedText: {
    fontWeight: "bold",
    color: "#222"
  },
  day: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 10,
    lineHeight: "12px"
  },
  date: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 18,
    lineHeight: "20px"
  }
});

class DayPreview extends PureComponent {
  constructor() {
    super();

    this.handlePreviewClick = this.handlePreviewClick.bind(this);
  }

  handlePreviewClick() {
    const { date, onDateClick } = this.props;

    if (onDateClick) {
      onDateClick(date);
    }
  }

  isToday() {
    const { date } = this.props;

    return Moment(date).isSame(new Date(), "day");
  }

  isFuture() {
    const { date } = this.props;

    return jsDateToDateString(date) > jsDateToDateString(new Date());
  }

  render() {
    const {
      date,
      beginHour,
      endHour,
      bookingHours,
      availability,
      duration,
      isSelected,
      isEnabled,
      classes
    } = this.props;

    const isToday = this.isToday();
    const isFuture = this.isFuture();

    let availableSlots = [],
      openingHour = 0,
      closingHour = 0;

    if (bookingHours) {
      availableSlots = getAvailableSlots(
        availability,
        bookingHours,
        duration ? duration : 30
      );

      const openingAndClosingHour = getBeginAndEndHourFromDayHours(
        bookingHours
      );
      openingHour = openingAndClosingHour.beginHour;
      closingHour = openingAndClosingHour.endHour;
    }

    let previewAvailability = {};
    if (
      beginHour !== undefined &&
      endHour !== undefined &&
      beginHour <= endHour
    ) {
      for (let hour = beginHour; hour <= endHour; hour++) {
        if (hour < openingHour || hour > closingHour) {
          previewAvailability[hour] = -1;
        } else if (availableSlots[hour] === undefined) {
          previewAvailability[hour] = 0;
        } else {
          previewAvailability[hour] = availableSlots[hour].length;
        }
      }
    }

    let previewSize = 0, hourLineHeight = 0;
    if (previewAvailability) {
      const hourCount = Object.keys(previewAvailability).length;
      hourLineHeight = Math.ceil(MIN_PREVIEW_SIZE / hourCount);
      previewSize = hourLineHeight * hourCount;
    }

    return (
      <div
        className={`${classes.container} ${isEnabled ? "" : classes.disabled}`}
        style={{
          width: previewSize,
          height: previewSize,
          borderColor: isSelected ? "#2196f3" : "#eee"
        }}
        onClick={isEnabled ? this.handlePreviewClick : undefined}
      >
        <div className={classes.bar}>
          <AvailabilityFade
            width={BAR_WIDTH}
            height={hourLineHeight}
            availability={previewAvailability}
          />
        </div>
        <div
          className={classes.content}
          style={{
            width: previewSize - BAR_WIDTH
          }}
        >
          <div className={classes.background}>
            <AvailabilityFade
              width={previewSize - BAR_WIDTH}
              height={hourLineHeight}
              availability={previewAvailability}
            />
          </div>
          <div
            className={`${
              isToday ? classes.selectedText : isFuture ? "" : classes.fadedText
            } ${classes.text}`}
          >
            <div className={classes.day}>
              <ReactMoment locale="nl-be" format="ddd">
                {date}
              </ReactMoment>
            </div>
            <div className={classes.date}>
              <ReactMoment locale="nl-be" format="D">
                {date}
              </ReactMoment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DayPreview);
