import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import Slot from './Slot';
import AddBookingForHour from './AddBookingForHour';

import { WEEK_DAY_HOUR_HEIGHT } from '../constants/styles';

const styles = theme => ({
  hour: {
    zIndex: 100,
    top: 56,
    paddingTop: '20px',
    paddingBottom: '10px',
    fontFamily: 'Lumberjack Inline Rough',
    lineHeight: '26px',
    textAlign: 'center',
    backgroundColor: 'white',
    color: '#666'
  },
  line: {
    display: 'inline-block',
    width: '75px',
    height: '1px',
    margin: '0 10px',
    verticalAlign: 'middle',
    backgroundColor: '#aaa'
  },
  fullLine: {
    display: 'block',
    width: '100%',
    height: '1px',
    margin: '0',
    verticalAlign: 'middle',
    backgroundColor: '#ccc'
  },
  addBooking: {

  },
  slotsWithFixedHeight: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: WEEK_DAY_HOUR_HEIGHT
  }
});

class Hour extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { barberId, date, hideHourTitle, fixedHourHeight, addBookingPerHourButton, size, slots, services, stickyTop, classes } = this.props;

    const hour = parseInt(this.props.hour);

    let hourText;
    if(hour < 10) {
      hourText = '0' + hour + ':00';
    } else {
      hourText = hour + ':00';
    }

    const begin = hour * 60;

    const hourStyle = {};
    if(stickyTop !== undefined) {
      const stickyTopAsInt = parseInt(stickyTop);
      if(!isNaN(stickyTop)) {
        hourStyle.top = stickyTop;
      }
    }

    return (
      <div>
        { 
          hideHourTitle ?
            <div className={ classes.fullLine }></div>
            :
            <ListSubheader className={ classes.hour } style={hourStyle}>
              <div className={ classes.line }></div>
                { hourText }
              <div className={ classes.line }></div>
            </ListSubheader>
        }
        {
          addBookingPerHourButton && <AddBookingForHour date={ date } begin={ begin } barberId={ barberId } handleBookSlotClick={ this.props.handleBookSlotClick } size={ size } />
        }
        <div className={ fixedHourHeight ? classes.slotsWithFixedHeight : classes.slots }>
          {
            slots && slots.map((slot, index) => 
              <Slot 
                key={ index } 
                id={ slot.id } 
                begin={ slot.begin } 
                duration={ slot.duration } 
                type={ slot.type } 
                data={ slot.data } 
                services={ services }
                handleSlotClick={ this.props.handleSlotClick } 
                handleSlotOptionsClick={ this.props.handleSlotOptionsClick } 
                handleBookSlotClick={ this.props.handleBookSlotClick } 
                handleRemoveSlotClick={ this.props.handleRemoveSlotClick }
                size={ size }
              />)
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Hour);
