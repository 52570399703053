import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  TextField,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

import SelectAmount from "./SelectAmount";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  avatar: {
    borderRadius: 5
  },
  field: {
    padding: "16px 0"
  },
  professionItem: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  professionFields: {
    display: "flex",
    width: "100%"
  },
  spacing: {
    width: 20
  }
});

class ShopAppointmentInfoFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleAmountChange = (name) => (amount) => {
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, [name]: amount });
  };

  handleProfessionChange = (variant) => (event) => {
    const value = event.target.value;
    const { info, onUpdate } = this.props;

    onUpdate({ ...info, profession: { ...info.profession, [variant]: value } });
  };

  render() {
    const { classes, open, onClose, shop, info, ...other } = this.props;
    const {
      maxDaysInAdvanceToBook,
      maxHoursInAdvanceToCancel,
      profession
    } = info;

    return (
      <div className={classes.content}>
        <List>
          <ListItem className={classes.field}>
            <ListItemText primary="Hoeveel dagen in de toekomst mogen je klanten afspraken maken?" />
            <SelectAmount
              amount={maxDaysInAdvanceToBook}
              min={1}
              max={120}
              onChange={this.handleAmountChange("maxDaysInAdvanceToBook")}
            />
          </ListItem>
          <ListItem className={classes.field}>
            <ListItemText primary="Tot hoeveel uren op voorhand kunnen je klanten hun afspraak annuleren?" />
            <SelectAmount
              amount={maxHoursInAdvanceToCancel}
              min={1}
              max={96}
              onChange={this.handleAmountChange("maxHoursInAdvanceToCancel")}
            />
          </ListItem>
          <ListItem
            className={classNames(classes.professionItem, classes.field)}
          >
            <Typography variant="caption">
              Wat is je functiebenaming?
            </Typography>
            <div className={classes.professionFields}>
              <TextField
                margin="dense"
                id="singular"
                label="Enkelvoud"
                helperText="Bijvoorbeeld 'barber'"
                fullWidth
                value={profession.singular}
                onChange={this.handleProfessionChange("singular")}
              />
              <div className={classes.spacing}></div>
              <TextField
                margin="dense"
                id="plural"
                label="Meervoud"
                helperText="Bijvoorbeeld 'barbers'"
                fullWidth
                value={profession.plural}
                onChange={this.handleProfessionChange("plural")}
              />
            </div>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(ShopAppointmentInfoFields);
