import React from "react";
import { withRouter, Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import AuthContext from "../context/auth";

import Loading from "./Loading";

const withShop = () => Component => {
  class WithShop extends React.Component {
    componentDidMount() {}

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth && auth.ready ? (
              auth.shopId ? (
                <Component {...this.props} auth={auth} />
              ) : (
                <Redirect to={routes.SELECT_SHOP_EMPTY} />
              )
            ) : (
              <Loading />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithShop);
};

export default withShop;
