import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import Moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import * as routes from "../constants/routes";

import MonthHeader from "../components/MonthHeader";
import DaysPreviewMatrix from "../components/DaysPreviewMatrix";
import DayFooter from "../components/DayFooter";

import withCustomersOnly from "../components/withCustomersOnly";

const styles = theme => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 106px)",
    [theme.breakpoints.up("600")]: {
      minHeight: "calc(100vh - 112px)"
    }
  }
});

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.isDateValid = this.isDateValid.bind(this);
  }

  componentDidMount() {
    const { date } = this.props.shop;
    this.handleMonthChange(date);
  }

  componentWillUnmount() {}

  handleMonthChange(date) {
    const { shop, requestAvailability } = this.props;

    const beginOfMonthDate = Moment(date)
      .startOf("month")
      .startOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift
    const endOfMonthDate = Moment(date)
      .endOf("month")
      .endOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift

    this.setState({ date, beginOfMonthDate, endOfMonthDate });

    requestAvailability(beginOfMonthDate, endOfMonthDate);
  }

  handleDateChange(newDate) {
    const { shop } = this.props;

    if (this.isDateValid(newDate)) {
      this.setState({ redirect: routes.DAY }, () => {
        shop.update({ date: newDate });
      });
    }
  }

  isDateValid(date) {
    const { info } = this.props;

    const dateDiff = Moment(date).diff(undefined, "day");
    const maxDaysInAdvanceToBook =
      info && info.maxDaysInAdvanceToBook ? info.maxDaysInAdvanceToBook : 30;

    return dateDiff >= 0 && dateDiff <= maxDaysInAdvanceToBook;
  }

  render() {
    const {
      shop,
      info,
      barbers,
      services,
      availability,
      openModal,
      classes
    } = this.props;

    const { date, beginOfMonthDate, endOfMonthDate, redirect } = this.state;

    // Redirect

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    // Render

    const maxDaysInAdvanceToBook =
      info && info.maxDaysInAdvanceToBook ? info.maxDaysInAdvanceToBook : 30;

    const dateDiff = Moment(endOfMonthDate).diff(undefined, "day");

    const canGoBack = Moment(beginOfMonthDate).isAfter();
    const canGoForward = dateDiff < maxDaysInAdvanceToBook;

    return (
      <div className={classes.root}>
        <MonthHeader
          date={date}
          canGoBack={canGoBack}
          canGoForward={canGoForward}
          onDateChanged={this.handleMonthChange}
        />
        <div className={classes.content}>
          <DaysPreviewMatrix
            shop={shop}
            barbers={barbers}
            services={services}
            availability={availability}
            beginDate={beginOfMonthDate}
            endDate={endOfMonthDate}
            selectedDate={shop.date}
            handleDateChange={this.handleDateChange}
            isDayEnabled={this.isDateValid}
          />
        </div>

        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          services={services}
          openModal={openModal}
        />
      </div>
    );
  }
}

export default withCustomersOnly()(withStyles(styles)(HomePage));
