import React, { Component } from "react";
import PropTypes from "prop-types";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Moment from "react-moment";

import * as urls from "../constants/urls";

const styles = theme => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  text: {},
  textField: {
    left: "50%",
    transform: "translate(-50%, 0)"
  },
  link: {
    display: "block",
    marginTop: 20,
    marginBottom: 40,
    textDecoration: "none"
  },
  centeredButton: {
    textAlign: "center"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class VerifyPhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: ""
    };

    this.handleVerifyPhoneConfirm = this.handleVerifyPhoneConfirm.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onEntering() {}

  componentDidUpdate(prevProps, prevState) {}

  handleSubmit = (event) => {
    event.preventDefault();
  }

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      () => {}
    );
  };

  handleVerifyPhoneConfirm(event) {
    this.handleClose(event, this.state.code);
  }

  handleClose = (event, code) => {
    this.setState({ code: "" }, () => {
      this.props.onClose(code);
    });
  };

  render() {
    const { classes, open, onClose, phone, ...other } = this.props;
    const { code } = this.state;

    return (
      <Dialog
        fullScreen
        onEntering={this.onEntering}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="Telefoonnummer verifiëren"
        {...other}
      >
        <DialogTitle>
          <Typography
            className={classes.title}
            variant="caption"
            id="simple-modal-description"
          >
            Telefoonnummer verifiëren
          </Typography>
        </DialogTitle>
        <form ref="form" className={classes.container} autoComplete="off" onSubmit={this.handleSubmit}>
          <List>
            <ListItem>
              <ListItemText>
                <Typography
                  className={classes.text}
                  variant="subheading"
                  id="simple-modal-description"
                >
                  Er werd een SMS met een code gestuurd naar {phone}
                  <br />
                  Vul hieronder de code in die je ontvangen hebt om je GSM
                  nummer te verifiëren
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <TextField
                required
                id="code"
                label="Code"
                autoComplete="one-time-code"
                className={classes.textField}
                value={code}
                onChange={this.handleChange("code")}
                margin="normal"
              />
            </ListItem>
            <a className={classes.link} href={urls.FEEDBACK} target="_blank">
              <ListItem button className={classes.centeredButton}>
                <ListItemText primary="Geen verificatiecode ontvangen?" />
              </ListItem>
            </a>
            <ListItem
              button
              className={classes.button}
              onClick={this.handleVerifyPhoneConfirm}
            >
              <CheckIcon color="primary" className={classes.leftIcon} />
              <ListItemText primary="Bevestigingscode verifiëren" />
            </ListItem>
            <ListItem
              button
              className={classes.button}
              onClick={this.handleClose}
            >
              <CloseIcon color="secondary" className={classes.leftIcon} />
              <ListItemText primary="Annuleren" />
            </ListItem>
          </List>
        </form>
      </Dialog>
    );
  }
}

VerifyPhone.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  phone: PropTypes.string
};
//onChange={ setBarberId(barber.id) }

export default withMobileDialog()(withStyles(styles)(VerifyPhone));
