import React, { Component } from "react";
import PropTypes from "prop-types";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import TextField from "@material-ui/core/TextField";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import BlockIcon from "@material-ui/icons/Block";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import ReactSelect from "react-select";

import { toast } from "react-toastify";

import Products from "../components/Products";
import UploadImage from "../components/UploadImage";

import {
  findInArrayByPropVal,
  minutesToTime,
  beautifyPhone,
  filterNotRemoved
} from "../utils";

import { store, storage } from "../firebase";

import ReactMoment from "react-moment";

import LazyLoad from "react-lazyload";

function Transition(props) {
  return <Slide {...props} />;
}

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  amountsItem: {
    paddingTop: 0
  },
  purchasedProducts: {
    width: "100%"
  },
  purchasedProductsTitle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "uppercase"
  },
  purchasedProductsSelect: {
    width: "100%",
    marginTop: 10
  },
  smoothList: {
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch"
  },
  textField: {
    width: "100%"
  },
  spacingBetweenTextFields: {
    width: 50
  },
  formControl: {
    width: "100%"
  },
  uploadButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    cursor: "pointer"
  },
  hairdos: {
    marginTop: 20
  },
  hairdo: {
    margin: "0 16px 20px 16px"
  },
  timestamp: {
    display: "block",
    marginBottom: 10,
    fontFamily: "Lumberjack Inline Rough",
    fontWeight: "bold"
  },
  picture: {
    width: "100%"
  }
});

class EditSlot extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { shop, id } = this.props;

    if (shop.id && id) {
      this.refreshSlot(shop.id, id);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeSlotInfo) {
      this.unsubscribeSlotInfo();
      this.unsubscribeSlotInfo = undefined;
    }
    if (this.unsubscribeCustomerInfo) {
      this.unsubscribeCustomerInfo();
      this.unsubscribeCustomerInfo = undefined;
    }
    if (this.unsubscribeCustomerHairdos) {
      this.unsubscribeCustomerHairdos();
      this.unsubscribeCustomerHairdos = undefined;
    }
  }

  componentDidUpdate() {
    /*const { shop, id } = this.props;

    if(shop.id && id && id !== this.state.id) {
      this.refreshSlot(shop.id, id);
    }*/
  }

  refreshSlot(shopId, slotId) {
    if (this.unsubscribeSlotInfo) {
      this.unsubscribeSlotInfo();
      this.unsubscribeSlotInfo = undefined;
    }

    this.unsubscribeSlotInfo = store.onGetSlotInfo(shopId, slotId, (info) => {
      this.setState({ id: slotId, slot: info, hairdos: undefined });
      this.refreshCustomer(shopId, info.customerId);
      this.refreshHairdos(shopId, info.customerId);

      if (info) {
        if (info.isNoShow) {
          store
            .setSlotPaymentAndCouponAmount(shopId, slotId)
            .then(() => {})
            .catch((error) => {
              console.warn(error);
            });
        } else if (
          info.paymentAmount === "" ||
          info.paymentAmount === null ||
          isNaN(info.paymentAmount)
        ) {
          const paymentAmount = this.calculateAmountToPay(
            info.serviceId,
            info.purchasedProducts,
            info.couponAmount
          );

          store
            .setSlotPaymentAndCouponAmount(shopId, slotId, paymentAmount)
            .then(() => {})
            .catch((error) => {
              console.warn(error);
            });
          // Legacy paymentMethod
        } else if (info.paymentMethod === "cadeaubon") {
          store
            .setSlotPayment(shopId, slotId, undefined, 0, info.paymentAmount)
            .then(() => {})
            .catch((error) => {
              console.warn(error);
            });
        }
      }
    });
  }

  refreshCustomer(shopId, customerId) {
    if (this.unsubscribeCustomerInfo) {
      this.unsubscribeCustomerInfo();
      this.unsubscribeCustomerInfo = undefined;
    }

    this.unsubscribeCustomerInfo = store.onGetCustomerInfo(
      shopId,
      customerId,
      (info) => {
        if (info) {
          this.setState({ customer: info });
        }
      }
    );
  }

  refreshHairdos(shopId, customerId) {
    if (this.unsubscribeCustomerHairdos) {
      this.unsubscribeCustomerHairdos();
      this.unsubscribeCustomerHairdos = undefined;
    }

    this.unsubscribeCustomerHairdos = store.onGetCustomerHairdos(
      shopId,
      customerId,
      (hairdos) => {
        this.setState({ hairdos });

        storage
          .getHairdoImages(
            shopId,
            customerId,
            hairdos.map((hairdo) => hairdo.filename)
          )
          .then((hairdoImages) => {
            this.setState({ hairdoImages });
          })
          .catch((error) => {
            console.warn(error);
          });
      }
    );
  }

  calculateAmountToPay(serviceId, purchasedProducts, couponAmount) {
    let totalPaymentAmount = 0;

    // Service
    if (serviceId) {
      const service = this.findService(serviceId);

      if (service && service.price) {
        totalPaymentAmount += service.price;
      }
    }

    // Purchased products
    if (purchasedProducts) {
      purchasedProducts.map((product) => {
        const found = this.findProduct(product.id);
        if (found && found.price) {
          totalPaymentAmount += found.price;
        }

        return product;
      });
    }

    // Coupon
    if (couponAmount) {
      totalPaymentAmount -= couponAmount;
    }

    return totalPaymentAmount;
  }

  isPaymentAmountCorrect = () => {
    const { slot } = this.state;

    const paymentAmount = this.calculateAmountToPay(
      slot.serviceId,
      slot.purchasedProducts,
      slot.couponAmount
    );

    return slot.paymentAmount === paymentAmount;
  };

  handleTotalAmountToPayClick = () => {
    const { shop, id } = this.props;
    const { slot } = this.state;

    const paymentAmount = this.calculateAmountToPay(
      slot.serviceId,
      slot.purchasedProducts,
      slot.couponAmount
    );

    this.setState({
      slot: {
        ...slot,
        paymentAmount
      }
    });

    store
      .setSlotPaymentAndCouponAmount(
        shop.id,
        id,
        paymentAmount,
        slot.couponAmount
      )
      .then(() => {})
      .catch((error) => {
        if (error) {
          toast.error(error.message);
        }
      });
  };

  handleUploadSuccess = (filename) => {
    const { shop } = this.props;
    const { slot } = this.state;

    store
      .addHairdo(shop.id, shop.barberId, slot.customerId, filename)
      .then(() => {})
      .catch((error) => {
        if (error) {
          toast.error(error.message);
        }
      });
  };

  handleUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  handleChange = (event) => {
    const { shop, id } = this.props;
    const { slot } = this.state;

    switch (event.target.name) {
      case "paymentMethod":
        store
          .setSlotPaymentMethod(shop.id, id, event.target.value)
          .then(() => {})
          .catch((error) => {
            if (error) {
              toast.error(error.message);
            }
          });
        break;
      case "paymentAmount":
        let newPaymentAmount = parseInt(event.target.value);
        if (isNaN(newPaymentAmount)) {
          newPaymentAmount = 0;
        }
        this.setState({ slot: { ...slot, paymentAmount: newPaymentAmount } });
        break;
      case "couponAmount":
        let newCouponAmount = parseInt(event.target.value);
        if (isNaN(newCouponAmount)) {
          newCouponAmount = 0;
        }

        const paymentAmount = isNaN(slot.paymentAmount)
          ? 0
          : slot.paymentAmount;
        const couponAmount = isNaN(slot.couponAmount) ? 0 : slot.couponAmount;

        const updatedPaymentAmount =
          paymentAmount - (newCouponAmount - couponAmount);

        this.setState({
          slot: {
            ...slot,
            paymentAmount: updatedPaymentAmount,
            couponAmount: newCouponAmount
          }
        });
        break;
      case "isNoShow":
        store
          .setSlotIsNoShow(shop.id, id, event.target.checked)
          .then(() => {})
          .catch((error) => {
            if (error) {
              toast.error(error.message);
            }
          });
    }
  };

  handleBlur = (event) => {
    const { shop, id } = this.props;
    const { slot } = this.state;

    //this.setState({ [event.target.name]: event.target.value });
    switch (event.target.name) {
      case "paymentAmount":
      case "couponAmount":
        store
          .setSlotPaymentAndCouponAmount(
            shop.id,
            id,
            slot.paymentAmount,
            slot.couponAmount
          )
          .then(() => {})
          .catch((error) => {
            if (error) {
              toast.error(error.message);
            }
          });
        break;
    }
  };

  handlePurchasedProductsChange = (purchasedProducts, change) => {
    const { shop, id } = this.props;
    const { slot } = this.state;

    const purchasedProductsWithPrice = purchasedProducts.map((product) => {
      const found = this.findProduct(product.id);
      if (found && found.price) {
        product.price = found.price;
      }

      return product;
    });

    store
      .setSlotPurchasedProducts(shop.id, id, purchasedProductsWithPrice)
      .then(() => {})
      .catch((error) => {
        if (error) {
          toast.error(error.message);
        }
      });

    let newPaymentAmount = this.calculateAmountToPay(
      slot.serviceId,
      purchasedProducts,
      slot.couponAmount
    );

    store
      .setSlotPaymentAndCouponAmount(
        shop.id,
        id,
        newPaymentAmount,
        slot.couponAmount
      )
      .then(() => {})
      .catch((error) => {
        if (error) {
          toast.error(error.message);
        }
      });
  };

  handleAppliedProductsChange = (appliedProducts, change) => {
    const { shop, id } = this.props;

    store
      .setSlotAppliedProducts(shop.id, id, appliedProducts)
      .then(() => {})
      .catch((error) => {
        if (error) {
          toast.error(error.message);
        }
      });
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }

    if (this.unsubscribeSlotInfo) {
      this.unsubscribeSlotInfo();
      this.unsubscribeSlotInfo = undefined;
    }
  };

  findService = (id) => {
    const { services } = this.props;

    return findInArrayByPropVal(services, "id", id);
  };

  findProduct = (id) => {
    const { products } = this.props;

    return findInArrayByPropVal(products, "id", id);
  };

  render() {
    const { classes, shop, products, open, onClose, ...other } = this.props;
    const { slot, customer, hairdos, hairdoImages } = this.state;

    const availableProducts = filterNotRemoved(products);

    let paymentMethod = "";
    let paymentAmount = 0;
    let couponAmount = 0;
    let isNoShow = "";
    let hairdoRef, service;

    if (slot) {
      if (slot.paymentMethod) {
        paymentMethod = slot.paymentMethod;
      }
      if (slot.paymentAmount) {
        paymentAmount = slot.paymentAmount;
      }
      if (slot.couponAmount) {
        couponAmount = slot.couponAmount;
      }
      if (slot.isNoShow) {
        isNoShow = slot.isNoShow;
      }
      if (slot.customerId) {
        hairdoRef = storage.getHairdosRef(shop.id, slot.customerId);
      }
      if (slot.serviceId) {
        service = this.findService(slot.serviceId);
      }
    }

    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={this.handleClose}
          disableBackdropClick={true}
          scroll="body"
          aria-labelledby="Afspraak"
        >
          <DialogTitle>
            <Typography className={classes.title}>
              {customer
                ? `${customer.name}${
                    customer.phone ? ` (${beautifyPhone(customer.phone)})` : ""
                  }`
                : "Afspraak"}
            </Typography>
            <Typography variant="overline">
              {slot && slot.date && (
                <span>
                  <ReactMoment format="dddd D MMMM">{slot.date}</ReactMoment>,{" "}
                </span>
              )}
              {slot && slot.begin && <span>{minutesToTime(slot.begin)}, </span>}
              {service && service.name && <span>{service.name}</span>}
            </Typography>
            {customer && customer.email && (
              <Typography variant="overline">{customer.email}</Typography>
            )}
          </DialogTitle>
          {slot && (
            <List>
              <ListItem>
                {isNoShow ? <BlockIcon /> : <HourglassEmptyIcon />}
                <ListItemText primary="No Show" />
                <ListItemSecondaryAction>
                  <Switch
                    checked={isNoShow}
                    onChange={this.handleChange}
                    inputProps={{
                      name: "isNoShow",
                      id: "isNoShow"
                    }}
                    value="true"
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              {!isNoShow && (
                <div>
                  <ListItem>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor="paymentMethod">
                        BETAALMIDDEL
                      </InputLabel>
                      <Select
                        value={paymentMethod}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "paymentMethod",
                          id: "paymentMethod"
                        }}
                      >
                        <MenuItem value="">
                          <em>Onbekend</em>
                        </MenuItem>
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="bancontact">Bancontact</MenuItem>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <span>
                          Totaal te betalen bedrag:{" "}
                          <strong>
                            €
                            {this.calculateAmountToPay(
                              slot.serviceId,
                              slot.purchasedProducts
                            )}
                          </strong>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction></ListItemSecondaryAction>
                  </ListItem>
                  <ListItem className={classes.amountsItem}>
                    <TextField
                      label="BEDRAG BETAALD MET CADEAUBON"
                      value={`${couponAmount}`}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      inputProps={{
                        name: "couponAmount",
                        id: "couponAmount"
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      className={classes.textField}
                      margin="normal"
                    />
                    <div className={classes.spacingBetweenTextFields}></div>
                    <TextField
                      error={!this.isPaymentAmountCorrect()}
                      label="CONTANT BETAALD BEDRAG"
                      value={`${paymentAmount}`}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      inputProps={{
                        name: "paymentAmount",
                        id: "paymentAmount"
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="number"
                      className={classes.textField}
                      margin="normal"
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Products
                      title="Gebruikte Producten"
                      available={availableProducts}
                      selected={slot.appliedProducts}
                      onChange={this.handleAppliedProductsChange}
                    />
                  </ListItem>
                  <ListItem>
                    <Products
                      title="Aangekochte Producten"
                      available={availableProducts}
                      selected={slot.purchasedProducts}
                      onChange={this.handlePurchasedProductsChange}
                    />
                  </ListItem>
                  <Divider />
                </div>
              )}
              <ListItem
                button
                className={classes.button}
                onClick={this.handleClose}
              >
                <CloseIcon color="secondary" className={classes.leftIcon} />
                <ListItemText primary="Sluiten" />
              </ListItem>
              <Divider />
              {hairdoRef && (
                <div>
                  <UploadImage
                    uploadRef={hairdoRef}
                    onSuccess={this.handleUploadSuccess}
                    onError={this.handleUploadError}
                  />
                  <Divider />
                </div>
              )}
              <div className={classes.hairdos}>
                {hairdos &&
                  hairdos.map((hairdo, index) => (
                    <div key={index} className={classes.hairdo}>
                      {hairdo && hairdo.timestamp && (
                        <div>
                          <ReactMoment
                            className={classes.timestamp}
                            locale="nl-be"
                            format="dddd D MMMM"
                          >
                            {hairdo.timestamp.toDate()}
                          </ReactMoment>
                          {hairdoImages && hairdoImages[hairdo.filename] && (
                            <img
                              src={hairdoImages[hairdo.filename]}
                              className={classes.picture}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </List>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EditSlot));

// https://github.com/willmcpo/body-scroll-lock
/* <LazyLoad scroll>
                        <img src={ hairdo.url } className={ classes.picture } />
                      </LazyLoad> */
