import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
    showAvailability: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 50
    },
    text: {
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.54)'
    },
});

class ControlShowAvailability extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
        showAvailability: props.showAvailability === undefined ? true : props.showAvailability,
    };
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
    
  }

  handleChange = event => {
    const showAvailability = event.target.checked;
    this.setState({ showAvailability });

    if(this.props.handleShowAvailabilityChange) {
        this.props.handleShowAvailabilityChange(showAvailability);
    }
  };

  render() {
    const { classes } = this.props;
    const { showAvailability } = this.state;

    return (
      <div className={ classes.showAvailability }>
        <FormGroup row>
            <FormControlLabel
                control={
                    <Switch
                        checked={ showAvailability }
                        onChange={ this.handleChange }
                        inputProps={{
                            name: 'showAvailability',
                            id: 'showAvailability'
                        }}
                        value="true"
                        color="primary"
                    />
                }
                label="Beschikbaarheid"
                classes={{
                    label: classes.text
                }}
            />
        </FormGroup>
      </div>
    );
  }
}

export default withStyles(styles)(ControlShowAvailability);