import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import CircularProgress from '@material-ui/core/CircularProgress';

import WeekDayHeader from "./WeekDayHeader";
import WeekDayHours from "./WeekDayHours";
import Day from "./Day";

const styles = theme => ({
  week: {
    width: "100%",
    minHeight: "calc(100vh - 114px)",
    paddingTop: 0,
    textAlign: "center",
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  hours: {
    position: "relative",
    display: "inline-block",
    paddingTop: 50,
    verticalAlign: "top"
  },
  day: {
    position: "relative",
    display: "inline-block",
    width: "18%",
    verticalAlign: "top"
  },
  header: {
    position: "sticky",
    top: 64,
    zIndex: 100,
    backgroundColor: "white"
  }
});

class Week extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      classes,
      days,
      beginHour,
      endHour,
      services,
      handleSlotClick,
      handleBookSlotClick,
      handleRemoveSlotClick
    } = this.props;

    return (
      <div className={classes.week}>
        <div className={classes.hours}>
          <WeekDayHours beginHour={beginHour} endHour={endHour} />
        </div>
        {days && days.length > 0 ? (
          days.map((day, index) => (
            <div key={index} className={classes.day}>
              <div className={classes.header}>
                <WeekDayHeader date={day.date} />
              </div>
              <Day
                date={day.date}
                beginHour={beginHour}
                endHour={endHour}
                slots={day.slots}
                services={services}
                handleSlotClick={handleSlotClick}
                handleBookSlotClick={handleBookSlotClick}
                handleRemoveSlotClick={handleRemoveSlotClick}
                hideHourTitle="true"
                fixedHourHeight="true"
                size="small"
                renderEmpty="true"
              />
            </div>
          ))
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Week);
