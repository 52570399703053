import React, { Component } from "react";

import AuthContext from "../context/auth";

import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";

import Slots from "../components/Slots";

import List from "@material-ui/core/List";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

import withCustomersOnly from "../components/withCustomersOnly";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  list: {
    minHeight: "calc(100vh - 122px)",
    backgroundColor: theme.palette.background.paper
  }
});

class UserSlotsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { classes, auth, shop, info, products } = this.props;

    return (
      <div className={classes.root}>
        <UserHeader title="Mijn afspraken" />
        <List className={classes.list}>
          {auth && auth.customer && (
            <Slots
              shop={shop}
              info={info}
              products={products}
              userId={auth.customer.userId}
              customerId={auth.customer.customerId}
              showAddToCalendar={true}
            />
          )}
        </List>
        <UserFooter />
      </div>
    );
  }
}

export default withCustomersOnly()(
  withStyles(styles)(props => (
    <AuthContext.Consumer>
      {auth => <UserSlotsPage {...props} auth={auth} />}
    </AuthContext.Consumer>
  ))
);
