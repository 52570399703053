import React, { Component } from "react";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide
} from "@material-ui/core";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { parseBarberFields } from "../utils";

import BarberFields from "../components/BarberFields";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20,
    textTransform: "capitalize"
  }
});

class EditBarber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      emailAddress: "",
      phoneNumber: "",
      imageFilename: ""
    };
  }

  componentDidMount() {
    const { barber } = this.props;
    if (barber) {
      this.setState({ ...barber });
    } else {
      this.setState({});
    }
  }

  handleUpdate = (barber) => {
    this.setState({ ...barber });
  };

  handleSave = () => {
    const { shop } = this.props;
    const { id } = this.state;

    try {
      const toUpdate = parseBarberFields(this.state);

      console.log(shop, id, toUpdate);
      const toastId = toast("Wijzigingen bewaren...", {
        autoClose: false
      });

      const { name, emailAddress, phoneNumber, imageFilename } = toUpdate;

      store
        .updateBarber(shop.id, id, name, emailAddress, phoneNumber, imageFilename)
        .then(() => {
          toast.update(toastId, {
            render: "Wijzigingen bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });

          this.handleClose();
        })
        .catch((error) => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    } catch (error) {
      console.warn(error);
      if (error) {
        toast.error(error.toString());
      }
    }
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    const { classes, open, onClose, info, shop, barber, ...other } = this.props;

    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle>
            <Typography className={classes.title}>
              {info && info.profession && info.profession.singular
                ? info.profession.singular
                : "Barber"}{" "}
              aanpassen
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <BarberFields
              shop={shop}
              barber={this.state}
              onUpdate={this.handleUpdate}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Annuleren
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Bewaren
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EditBarber));
