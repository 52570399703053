import React, { PureComponent } from "react";

import Moment from "moment";

import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from "@material-ui/core/styles";

import DaysPreview from "./DaysPreview";

const styles = theme => ({
  daysPreviewContainer: {
    display: "block",
    textAlign: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  daysPreviewMatrix: {
    display: "inline-block"
  },
  daysPreviewLine: {
    display: "block",
    margin: "2px 0 2px 0",
    textAlign: "right"
  },
  daysPreviewContent: {
    display: "inline-block"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

class Month extends PureComponent {
  constructor() {
    super();
  }

  render() {
    const {
      shop,
      barbers,
      services,
      availability,
      beginDate,
      endDate,
      selectedDate,
      handleDateChange,
      isDayEnabled,
      classes
    } = this.props;

    const daysPreviewLines = [];

    if (beginDate && endDate && beginDate <= endDate) {
      const endOfFirstWeek = Moment(beginDate)
        .add((7 - beginDate.getDay()) % 7, "days")
        .toDate();
      daysPreviewLines.push({
        begin: beginDate,
        end: endOfFirstWeek
      });

      let date = Moment(endOfFirstWeek)
        .add(1, "days")
        .toDate();

      while (date <= endDate) {
        daysPreviewLines.push({
          begin: date,
          end: Moment(date)
            .add(6, "days")
            .toDate()
        });

        date = Moment(date)
          .add(7, "days")
          .toDate();
      }
    }

    return (
      <div className={classes.daysPreviewContainer}>
        <div className={classes.daysPreviewMatrix}>
          {availability && daysPreviewLines && daysPreviewLines.length > 0 ? (
            daysPreviewLines.map((line, index) => (
              <div key={index} className={classes.daysPreviewLine}>
                <div className={classes.daysPreviewContent}>
                  <DaysPreview
                    shop={shop}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    beginDate={line.begin}
                    endDate={line.end}
                    selectedDate={selectedDate}
                    onDateClick={newDate => {
                      handleDateChange(newDate);
                    }}
                    isDayEnabled={isDayEnabled}
                  />
                </div>
              </div>
            ))
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Month);
