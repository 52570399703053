import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar
} from "@material-ui/core";

import { toast } from "react-toastify";

import { storage } from "../firebase";

import UploadImage from "./UploadImage";

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  field: {
    padding: "16px 0"
  }
});

class BarberFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshBarberImage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { barber } = this.props;
    const prevBarber = prevProps.barber;

    const imageFilename = barber ? barber.imageFilename : undefined;
    const prevImageFilename = prevBarber ? prevBarber.imageFilename : undefined;

    if (imageFilename !== prevImageFilename) {
      this.refreshBarberImage();
    }
  }

  refreshBarberImage() {
    const { shop, barber } = this.props;

    if (shop && shop.id && barber && barber.imageFilename) {
      console.log("here");
      storage
        .getBarberImage(shop.id, barber.imageFilename)
        .then((barberImageUrl) => {
          this.setState({ barberImageUrl });
        })
        .catch((error) => {
          this.setState({ barberImageUrl: null });
          console.warn(error);
        });
    } else {
      this.setState({ barberImageUrl: null });
    }
  }

  handleNameChange = (event) => {
    const value = event.target.value;
    const { barber, onUpdate } = this.props;

    onUpdate({ ...barber, name: value });
  };

  handleEmailAddressChange = (event) => {
    const value = event.target.value;
    const { barber, onUpdate } = this.props;

    onUpdate({ ...barber, emailAddress: value });
  };

  handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const { barber, onUpdate } = this.props;

    onUpdate({ ...barber, phoneNumber: value });
  };

  handleImageUploadSuccess = (filename) => {
    const { barber, onUpdate } = this.props;

    onUpdate({ ...barber, imageFilename: filename });
  };

  handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  render() {
    const { classes, open, onClose, shop, barber, ...other } = this.props;
    const { name, emailAddress, phoneNumber, barberImage } = barber;
    const { barberImageUrl } = this.state;

    return (
      <List className={classes.content}>
        <ListItem className={classes.field}>
          <ListItemAvatar>
            <Avatar alt={name} src={barberImageUrl} />
          </ListItemAvatar>
          <ListItemText
            primary="Je foto"
            secondary="Toon je klanten wie je bent."
          />
          <ListItemSecondaryAction>
            {shop && shop.id && barber && barber.id && (
              <UploadImage
                label=""
                uploadRef={storage.getBarbersRef(shop.id, barber.id)}
                onSuccess={this.handleImageUploadSuccess}
                onError={this.handleImageUploadError}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="name"
            label="Naam"
            helperText="Hoe spreken je klanten je aan?"
            fullWidth
            value={name}
            onChange={this.handleNameChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="email"
            label="E-mailadres"
            helperText="Op welk e-mailadres kunnen je klanten je bereiken?"
            fullWidth
            value={emailAddress}
            onChange={this.handleEmailAddressChange}
          />
        </ListItem>
        <ListItem className={classes.field}>
          <TextField
            margin="dense"
            id="phone"
            label="Telefoonnummer"
            helperText="Op welk telefoonnummer kunnen je klanten je bereiken?"
            fullWidth
            value={phoneNumber}
            onChange={this.handlePhoneNumberChange}
          />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(BarberFields);
