export const BOOK_SLOT = "BOOK_SLOT";
export const CONFIRMATION = "CONFIRMATION";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SELECT_BARBER = "SELECT_BARBER";
export const SELECT_SERVICE = "SELECT_SERVICE";
export const SELECT_SLOT = "SELECT_SLOT";
export const VERIFY_PHONE = "VERIFY_PHONE";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const EDIT_SLOT = "EDIT_SLOT";
export const ADD_SERVICE = "ADD_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_BARBER = "EDIT_BARBER";
export const EDIT_SHOP = "EDIT_SHOP";
