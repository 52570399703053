export default {
  firebase: {
    apiKey: "AIzaSyDB_hjK0mRbyzUdXUWkB4DccT-E_VICTL4",
    authDomain: "barber-io-dev.firebaseapp.com",
    databaseURL: "https://barber-io-dev.firebaseio.com",
    projectId: "barber-io-dev",
    storageBucket: "barber-io-dev.appspot.com",
    messagingSenderId: "842605112658"
  },
  analytics: {
    trackingId: "UA-132351671-3"
  },
  functions: {
    app: "https://us-central1-barber-io-dev.cloudfunctions.net/app"
  },
  bugsnag: {
    apiKey: "1237d9534f0949ece5219d7e592c8c1a",
    releaseStage: "development"
  }
};
