import React, { Component } from "react";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";

import DayFooter from "../components/DayFooter";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import { jsDateToDateString, isValidDate, dateStringToJSDate } from "../utils";

import { store } from "../firebase";

import withCrewAuthorization from "../components/withCrewAuthorization";

import Moment from "moment";
import ReactMoment from "react-moment";

import CrewHeader from "../components/CrewHeader";

import DownloadReport from "../components/DownloadReport";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  earnings: {
    minHeight: "calc(100vh - 114px)",
    padding: 0
  },
  totalTitle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: 13,
    fontWeight: 800,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "uppercase"
  },
  totalDescription: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 13,
    fontWeight: 800,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  itemTitle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: 13,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "uppercase"
  },
  itemDescription: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 13,
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  subItemTitle: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 0 0 20px",
    fontSize: 11,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "uppercase"
  },
  subItemDescription: {
    color: "rgba(0, 0, 0, 0.47)",
    fontSize: 13,
    fontStyle: "italic",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  methods: {
    justifyContent: "center"
  },
  method: {
    minWidth: 50,
    height: 50,
    margin: "0 5px",
    padding: "0 15px",
    border: "1px solid #777",
    borderRadius: 50,
    textAlign: "center"
  },
  methodTitle: {
    overflow: "hidden",
    marginTop: 12,
    fontSize: 10,
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#777"
  },
  methodDescription: {
    marginTop: 2,
    fontSize: 12,
    fontWeight: "bold",
    color: "#777"
  }
});

class EarningsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      begin: Moment()
        .startOf("month")
        .add(2, "hours")
        .toDate(),
      end: Moment()
        .endOf("month")
        .toDate()
    };
    console.info(this.state.begin, this.state.end);

    this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    this.refreshEarnings();
  }

  componentDidUpdate(prevProps, prevState) {
    const { shop } = this.props;
    if (shop && prevProps.shop && shop.barberId !== prevProps.shop.barberId) {
      this.refreshEarnings();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeShopEarnings) {
      this.unsubscribeShopEarnings();
      this.unsubscribeShopEarnings = undefined;
    }
  }

  refreshEarnings() {
    const { shop } = this.props;
    const { begin, end } = this.state;

    const beginAsString = jsDateToDateString(begin);
    const endAsString = jsDateToDateString(end);

    if (this.unsubscribeShopEarnings) {
      this.unsubscribeShopEarnings();
      this.unsubscribeShopEarnings = undefined;
    }

    this.unsubscribeShopEarnings = store.onGetShopEarnings(
      shop.id,
      shop.barberId,
      beginAsString,
      endAsString,
      earnings => {
        this.setState({ earnings });
      }
    );
  }

  handleBeginDateChange(date) {
    if (date) {
      try {
        const beginDate = date.add(2, "hours").toDate(); // GMT+2
        if (isValidDate(beginDate)) {
          let newState = { begin: beginDate };

          if (beginDate > this.state.end) {
            newState.end = Moment(beginDate)
              .endOf("month")
              .add(2, "hours") // GMT+2
              .toDate();
          }

          this.setState(newState, () => {
            this.refreshEarnings();
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleEndDateChange(date) {
    if (date) {
      try {
        const endDate = date.add(2, "hours").toDate(); // GMT+2
        if (isValidDate(endDate)) {
          let newState = { end: endDate };

          if (endDate < this.state.begin) {
            newState.begin = Moment(endDate)
              .startOf("month")
              .add(2, "hours") // GMT+2
              .toDate();
          }

          this.setState(newState, () => {
            this.refreshEarnings();
          });
        }
      } catch (error) {
        console.warn(error);
      }
    }
  }

  render() {
    const {
      classes,
      shop,
      info,
      barbers,
      openModal,
      toggleDrawer
    } = this.props;
    const { begin, end, earnings } = this.state;

    const isValidDate = begin <= end;

    return (
      <div className={classes.root}>
        <CrewHeader title="Inkomsten" toggleDrawer={toggleDrawer} />
        <List className={classes.earnings}>
          <form className={classes.picker} noValidate>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="nl-be">
              <ListItem>
                <DatePicker
                  color="secondary"
                  id="begin"
                  label="VAN"
                  value={jsDateToDateString(begin)}
                  className={classes.dateField}
                  onChange={this.handleBeginDateChange}
                  format="dd D MMMM YYYY"
                  autoOk={true}
                  showTodayButton={true}
                  okLabel="OK"
                  cancelLabel="Annuleren"
                  todayLabel="Vandaag"
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.resize
                    }
                  }}
                />
              </ListItem>
              <ListItem>
                <DatePicker
                  color="secondary"
                  id="end"
                  label="TOT EN MET"
                  value={jsDateToDateString(end)}
                  className={classes.dateField}
                  onChange={this.handleEndDateChange}
                  format="dd D MMMM YYYY"
                  autoOk={true}
                  showTodayButton={true}
                  okLabel="OK"
                  cancelLabel="Annuleren"
                  todayLabel="Vandaag"
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.resize
                    }
                  }}
                />
              </ListItem>
            </MuiPickersUtilsProvider>
          </form>

          <Divider />
          
          <ListItem>
            <DownloadReport
              shop={shop}
              type="earnings"
              unit="slot"
              beginDate={jsDateToDateString(begin)}
              endDate={jsDateToDateString(end)}
              barberId={shop.barberId}
              caption="Afsprakenoverzicht downloaden (Excel)"
            />
          </ListItem>
          <ListItem>
            <DownloadReport
              shop={shop}
              type="earnings"
              unit="date"
              beginDate={jsDateToDateString(begin)}
              endDate={jsDateToDateString(end)}
              barberId={shop.barberId}
              caption="Inkomstenoverzicht downloaden (Excel)"
            />
          </ListItem>

          <Divider />

          {earnings && (
            <div>
              <ListItem>
                <div className={classes.totalTitle}>Totaal</div>
                <div className={classes.totalDescription}>
                  €{earnings.total.all}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.subItemTitle}>Afspraken</div>
                <div className={classes.subItemDescription}>
                  €{earnings.total.all - earnings.total.onlyProducts}
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.subItemTitle}>Producten</div>
                <div className={classes.subItemDescription}>
                  €{earnings.total.onlyProducts}
                </div>
              </ListItem>
              <ListItem className={classes.methods}>
                {earnings.total.methods &&
                  Object.keys(earnings.total.methods).map((method, index) => (
                    <div className={classes.method} key={index}>
                      <div className={classes.methodTitle}>{method}</div>
                      <div className={classes.methodDescription}>
                        €{earnings.total.methods[method]}
                      </div>
                    </div>
                  ))}
              </ListItem>
              <div>
                {earnings.days &&
                  Object.keys(earnings.days).map((date, index) => (
                    <div key={index}>
                      <Divider />
                      <ListItem>
                        <div className={classes.itemTitle}>
                          <ReactMoment locale="nl-be" format="dd DD/MM/YYYY">
                            {date}
                          </ReactMoment>
                        </div>
                        <div className={classes.itemDescription}>
                          €{earnings.days[date].total}
                        </div>
                      </ListItem>
                      <ListItem>
                        <div className={classes.subItemTitle}>Afspraken</div>
                        <div className={classes.subItemDescription}>
                          €
                          {earnings.days[date].total -
                            earnings.days[date].onlyProducts}
                        </div>
                      </ListItem>
                      <ListItem>
                        <div className={classes.subItemTitle}>Producten</div>
                        <div className={classes.subItemDescription}>
                          €{earnings.days[date].onlyProducts}
                        </div>
                      </ListItem>
                      <ListItem className={classes.methods}>
                        {earnings.days[date].methods &&
                          Object.keys(earnings.days[date].methods).map(
                            (method, index) => (
                              <div className={classes.method} key={index}>
                                <div className={classes.methodTitle}>
                                  {method}
                                </div>
                                <div className={classes.methodDescription}>
                                  €{earnings.days[date].methods[method]}
                                </div>
                              </div>
                            )
                          )}
                      </ListItem>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </List>
        <DayFooter
          shop={shop}
          info={info}
          barbers={barbers}
          hideService={true}
          hideCustomer={true}
          hideCrew={true}
          openModal={openModal}
        />
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(EarningsPage));
