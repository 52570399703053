import React from "react";

import { withStyles } from "@material-ui/core/styles";

import AuthorizedAppLink from "./AuthorizedAppLink";

const styles = theme => ({});

class DownloadReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      classes,
      shop,
      type,
      unit,
      beginDate,
      endDate,
      barberId,
      caption
    } = this.props;

    const params = { beginDate, endDate };
    if (unit) {
      params.unit = unit;
    }
    if (barberId) {
      params.barberId = barberId;
    }

    return (
      <AuthorizedAppLink
        shop={shop}
        endpoint={`reports/${type}`}
        params={params}
        text={caption}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(DownloadReport);
