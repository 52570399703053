import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import Moment from "moment";

/*import {
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';*/

import MenuIcon from "@material-ui/icons/Menu";

import NotificationCenter from "../components/NotificationCenter";

import Slot from "../components/Slot";
import Month from "../components/Month";

import DaysPreview from "../components/DaysPreview";

import { withStyles } from "@material-ui/core/styles";

import withCrewAuthorization from "../components/withCrewAuthorization";

import { store } from "../firebase";

import {
  jsDateToDateString,
  findInArrayByPropVal,
  getAvailableSlots
} from "../utils";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {},
  list: {
    margin: "0 auto"
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  text: {
    maxWidth: 600,
    margin: "0 auto"
  },
  slots: {
    minWidth: 300,
    marginTop: 20,
    marginLeft: 40
  },
  menuButton: {
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0
  },
  notificationCenter: {
    position: "fixed",
    zIndex: 100,
    top: 10,
    right: 10
  },
  greeting: {
    flexDirection: "column",
    marginTop: -30,
    marginBottom: 30,
    padding: 0
  },
  timeOfDay: {
    fontFamily: "shorelines_script_boldregular", //'Lumberjack Inline Rough',
    fontSize: 16,
    fontWeight: "bold",
    color: "black"
  },
  barberName: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 22,
    fontWeight: "bold",
    color: "black"
  },
  subItemTitle: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 11,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "uppercase"
  },
  month: {
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0
  },
  version: {
    textAlign: "center"
  }
});

const AVAILABLE_SLOT_DURATION = 30;

class ControlRoomPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const dateAsString = jsDateToDateString(new Date());

    const slots =
      slots && slots[dateAsString] ? { ...slots[dateAsString] } : {};
    this.processSlots(slots);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { slots } = this.props;
    const dateAsString = jsDateToDateString(new Date());

    if (prevProps.slots !== slots) {
      const slots =
        slots && slots[dateAsString] ? { ...slots[dateAsString] } : {};
      this.processSlots(slots);
    }
  }

  getCurrentHour() {
    const now = new Date();
    return parseInt(now.getHours());
  }

  getCurrentInMinutes() {
    const now = new Date();
    return parseInt(now.getHours() * 60 + now.getMinutes());
  }

  processSlots(slots) {
    const currentHour = this.getCurrentHour();
    const currentInMinutes = this.getCurrentInMinutes();

    let pastSlots = [],
      futureSlots = [],
      currentSlots = [];

    for (let hour = 0; hour < 24; hour++) {
      const slotsForHour = slots[hour];

      if (slotsForHour) {
        if (hour < currentHour) {
          slotsForHour.forEach(slot => {
            if (slot.begin + slot.duration >= currentInMinutes) {
              currentSlots.push(slot);
            } else {
              pastSlots.push(slot);
            }
          });
          //pastSlots.push(...slotsForHour);
        } else if (hour === currentHour) {
          slotsForHour.forEach((slot, index) => {
            if (slot.begin > currentInMinutes) {
              futureSlots.push(slot);
            } else if (slot.begin + slot.duration < currentInMinutes) {
              pastSlots.push(slot);
            } else {
              currentSlots.push(slot);
            }
          });
        } else {
          futureSlots.push(...slotsForHour);
        }
      }
    }

    this.setState({ pastSlots, futureSlots, currentSlots });
  }

  getTimeOfDayGreeting() {
    const hour = this.getCurrentHour();

    if (hour < 5) {
      return "goedenacht";
    } else if (hour < 11) {
      return "goedemorgen";
    } else if (hour < 14) {
      return "goedemiddag";
    } else if (hour < 16) {
      return "goedenamiddag";
    } else if (hour < 23) {
      return "goedenavond";
    } else {
      return "goedenacht";
    }
  }

  findBarber(id) {
    const { barbers } = this.props;

    return findInArrayByPropVal(barbers, "id", id);
  }

  handleDateChange(newDate) {
    const { shop } = this.props;

    this.setState({ redirect: routes.SCHEDULE }, () => {
      shop.update({ date: newDate });
    });
  }

  render() {
    const {
      shop,
      barbers,
      services,
      availability,
      toggleDrawer,
      requestAvailability,
      classes
    } = this.props;
    const { redirect, pastSlots, futureSlots, currentSlots } = this.state;

    // Redirect

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    // Render
    const now = new Date();

    const dateAsString = jsDateToDateString(now);
    const availabilityForDate = availability
      ? availability[dateAsString]
        ? { ...availability[dateAsString] }
        : {}
      : undefined;

    const midnight = Moment().startOf("day");
    const diff = Moment().diff(midnight, "minutes");
    const correctedDiff = Math.ceil(diff / 15) * 15;

    // Only if there is nothing else planned from the very beginning of the day (e.g. full day holiday)
    if (
      availabilityForDate &&
      (availabilityForDate[0] === undefined ||
        availabilityForDate[0] < correctedDiff)
    ) {
      availabilityForDate[0] = correctedDiff;
    }

    let barber, availableSlots, availableHours, availableHoursText;
    if (shop && shop.barberId) {
      barber = this.findBarber(shop.barberId);

      if (barber && barber.bookingHours && availabilityForDate) {
        let day = shop.date.getDay();
        let bookingHours = barber.bookingHours[day];

        availableSlots = getAvailableSlots(
          availabilityForDate,
          bookingHours,
          AVAILABLE_SLOT_DURATION
        );

        availableHours = Object.keys(availableSlots);
        if (availableHours.length > 1) {
          const lastAvailableHour = availableHours.pop();

          availableHoursText = availableHours
            .map(hour => `${hour}u`)
            .join(", ");
          availableHoursText += ` en ${lastAvailableHour}u`;
        } else {
          availableHoursText = `${availableHours[0]}u`;
        }
      }
    }

    return (
      <div className={classes.root}>
        <IconButton
          className={`AppDrawerToggle ${classes.menuButton}`}
          color="inherit"
          aria-label="Menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.notificationCenter}>
          <NotificationCenter shop={shop} />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.greeting}>
            <div className={classes.timeOfDay}>
              {this.getTimeOfDayGreeting()}
            </div>
            {barber && <div className={classes.barberName}>{barber.name}</div>}
          </ListItem>
          <div className={classes.text}>
            <ListItem>
              {futureSlots && futureSlots.length > 0 ? (
                <div>
                  {futureSlots.length === 1 ? (
                    <div>Er staat vandaag nog één afspraak op de agenda.</div>
                  ) : (
                    <div>
                      Er staan vandaag nog {futureSlots.length} afspraken op de
                      agenda.
                    </div>
                  )}
                </div>
              ) : (
                <div>Er staan vandaag geen afspraken meer op de agenda</div>
              )}
            </ListItem>
            {availableHours && availableHours.length > 0 && (
              <ListItem>
                <ListItemText>
                  {`Er zijn nog plaatsen vrij na ${availableHours[0]}u`}
                </ListItemText>
              </ListItem>
            )}
          </div>
          <div className={classes.content}>
            {currentSlots && currentSlots.length > 0 && (
              <div className={classes.slots}>
                <div>
                  <ListItem className={classes.subItemTitle}>
                    Huidige afspraak
                  </ListItem>
                  {currentSlots.map((slot, index) => (
                    <Slot
                      disabled={true}
                      key={index}
                      id={slot.id}
                      begin={slot.begin}
                      duration={slot.duration}
                      type={slot.type}
                      data={slot.data}
                      services={services}
                      handleSlotClick={this.props.handleSlotClick}
                      handleSlotOptionsClick={this.props.handleSlotOptionsClick}
                      handleBookSlotClick={this.props.handleBookSlotClick}
                      handleRemoveSlotClick={this.props.handleRemoveSlotClick}
                    />
                  ))}
                </div>
              </div>
            )}
            <div>
              <ListItem className={classes.month}>
                <Month
                  shop={shop}
                  barbers={barbers}
                  services={services}
                  availability={availability}
                  handleDateChange={this.handleDateChange}
                  requestAvailability={requestAvailability}
                  selectedDate={now}
                />
              </ListItem>
            </div>
          </div>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="overline"
                  className={classes.version}
                >{`BarberIO v${process.env.REACT_APP_VERSION}`}</Typography>
              }
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(ControlRoomPage));
