import React, { Component } from "react";

import { Redirect, Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import AsyncSelect from "react-select/lib/Async";

import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";

import Landing from "../components/Landing";

import { store } from "../firebase";

import * as routes from "../constants/routes";

const styles = (theme) => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  background: {
    position: "absolute",
    zIndex: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundImage: 'url("/img/background.jpg")',
    "&::before": {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.5)",
      content: `''`
    }
  },
  logo: {
    position: "absolute",
    zIndex: 15,
    top: 20,
    left: 20,
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundImage: 'url("/img/logo.png")'
  },
  loading: {
    position: "absolute",
    zIndex: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  spinner: {
    color: "rgba(255, 255, 255, 0.8)"
  },
  content: {
    position: "absolute",
    zIndex: 10,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minWidth: 300,
    maxWidth: 500
  },
  search: {},
  links: {
    marginTop: 40,
    fontSize: 12,
    textAlign: "center",
    color: "rgba(255, 255, 255, 0.9)"
  },
  link: {
    display: "inline-block",
    margin: 10,
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.8)"
  }
});

class SelectShopPage extends Component {
  constructor(props) {
    super(props);

    const hasLocator =
      props.match && props.match.params && props.match.params.locator;

    this.state = {};

    if (hasLocator) {
      this.state.hasLocator = hasLocator;
      this.state.locator = props.match.params.locator;
      this.state.loading = true;
    }

    if (props.match && props.match.params && props.match.params.redirect) {
      this.state.redirect = `/${props.match.params.redirect}`;
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { setShop } = this.props;
    const { locator } = this.state;

    if (locator) {
      store.getShopId(locator).then(
        (shopId) => {
          this.setState({ locator: undefined }, () => {
            setShop(shopId);
          });
        },
        (error) => {
          console.warn(error);
          this.setState({ loading: false });
        }
      );
    }
  }

  loadOptions(inputValue, callback) {
    setTimeout(() => {
      store.searchShops(inputValue).then(
        (shops) => {
          const formattedShops = shops.map((shop) => {
            return {
              value: shop.id,
              label: shop.label
            };
          });

          callback(formattedShops);
        },
        (error) => {
          console.warn(error);
        }
      );
    }, 0);
  }

  handleChange(input, change) {
    const { setShop } = this.props;
    if (change && change.action === "select-option" && input && input.value) {
      setShop(input.value);
    }
  }

  render() {
    const { classes, shop, info, barbers, services, toggleDrawer } = this.props;
    const { loading, locator, hasLocator, redirect } = this.state;

    if (locator === undefined && shop && shop.id) {
      if (redirect) {
        return <Redirect to={redirect} />;
      } else if (hasLocator) {
        return (
          <Landing
            shop={shop}
            info={info}
            barbers={barbers}
            services={services}
            toggleDrawer={toggleDrawer}
          />
        );
      } else {
        return <Redirect to={routes.LANDING} />;
      }
    }

    let title = "Boek je Kapper";
    let description = "Boek hier je afspraak.";
    let url = "https://app.boekjekapper.be/shops";
    if (info) {
      if (info.name) {
        title = info.name;
      }
      if (info.story) {
        description = info.story.join("\n");
      }
      if (info.website) {
        url = info.website;
      }
    }

    return (
      <div className={classes.container}>
        {!hasLocator && (
          <Helmet>
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org",
                "@type": "Kapper",
                "@id": "${url}",
                "name": "${title}",
                "url": "${url}",
                "acceptsReservations": "True"
              }
            `}
            </script>
          </Helmet>
        )}
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner} />
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.search}>
              <AsyncSelect
                cacheOptions
                loadOptions={this.loadOptions}
                defaultOptions
                onChange={this.handleChange}
                placeholder="Zoek een Kapperszaak..."
                noOptionsMessage={(inputValue) => "Geen shops gevonden."}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 255, 255, 0.9)"
                  })
                }}
              />
            </div>
            <div className={classes.links}>
              <a
                className={`${classes.link} blockOnMobile`}
                href="http://boekjekapper.be"
                target="_blank"
              >
                Meer Info
              </a>
              <span className="hideOnMobile">&bull;</span>
              <a
                className={`${classes.link} blockOnMobile`}
                href="http://boekjekapper.be/algemene-voorwaarden"
                target="_blank"
              >
                Algemene Voorwaarden
              </a>
              <span className="hideOnMobile">&bull;</span>
              <a
                className={`${classes.link} blockOnMobile`}
                href="http://boekjekapper.be/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        )}
        <div className={classes.background} />
        <div className={classes.logo} />
      </div>
    );
  }
}

export default withStyles(styles)(SelectShopPage);
