import { store, serverTimestamp, deleteField } from "./firebase";

import { formatSearchInput, jsDateToDateString } from "../utils";
import Moment from "moment";

export const searchShops = (query) => {
  return new Promise((resolve, reject) => {
    let limit = 10;
    let ref = store.collection("search");

    if (query) {
      const formattedQuery = formatSearchInput(query);
      if (formattedQuery !== "") {
        ref = ref.where("index", "array-contains", formattedQuery);
        limit = 20;
      }
    }

    return ref
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let shops = [];

        querySnapshot.forEach((doc) => {
          let shop = doc.data();
          shop.id = doc.id;
          shops.push(shop);
        });

        resolve(shops);
      })
      .catch(reject);
  });
};

export const searchShop = (id, query, collection) => {
  return new Promise((resolve, reject) => {
    let limit = 10;
    let ref = store
      .collection("shops")
      .doc(id)
      .collection("search");

    if (query) {
      const formattedQuery = formatSearchInput(query);
      if (formattedQuery !== "") {
        ref = ref.where("index", "array-contains", formattedQuery);
        limit = 20;
      }
    }
    if (collection) {
      ref = ref.where("collection", "==", collection.toLowerCase());
    }

    return ref
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let items = [];

        querySnapshot.forEach((doc) => {
          let item = doc.data();
          //item.id = doc.id;
          items.push(item);
        });

        resolve(items);
      })
      .catch(reject);
  });
};

export const getShopId = (locator) => {
  return new Promise((resolve, reject) => {
    return store
      .collection("search")
      .where("locator", "==", locator)
      .get()
      .then((docs) => {
        let shopId;
        console.info(docs.size);
        if (docs.size === 1) {
          docs.forEach((doc) => {
            shopId = doc.id;
          });
        } else if (docs.size === 0) {
          return reject("no shopId found");
        } else {
          return reject("found more then one shopId");
        }

        if (shopId) {
          return resolve(shopId);
        } else {
          return reject("no shopId found");
        }
      })
      .catch(reject);
  });
};

export const onGetShopInfo = (id, cb) => {
  return store
    .collection("shops")
    .doc(id)
    .onSnapshot((doc) => {
      cb(doc.data());
    });
};

export const updateShopInfo = (
  shopId,
  name,
  tagline,
  story,
  website,
  address,
  maxDaysInAdvanceToBook,
  maxHoursInAdvanceToCancel,
  profession,
  social,
  assets
) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }

    const toUpdate = {
      name,
      updatedAt: serverTimestamp()
    };

    if (tagline !== undefined && tagline !== null) {
      if (tagline === "") {
        toUpdate.tagline = deleteField();
      } else {
        toUpdate.tagline = tagline;
      }
    }

    if (story !== undefined && story !== null) {
      if (story === []) {
        toUpdate.story = deleteField();
      } else {
        toUpdate.story = story;
      }
    }

    if (story !== undefined && story !== null) {
      if (story === []) {
        toUpdate.story = deleteField();
      } else {
        toUpdate.story = story;
      }
    }

    if (address !== undefined && address !== null) {
      if (address === {}) {
        toUpdate.address = deleteField();
      } else {
        toUpdate.address = address;
      }
    }

    if (website !== undefined && website !== null) {
      if (website === "") {
        toUpdate.website = deleteField();
      } else {
        toUpdate.website = website;
      }
    }

    if (
      maxDaysInAdvanceToBook !== undefined &&
      maxDaysInAdvanceToBook !== null
    ) {
      toUpdate.maxDaysInAdvanceToBook = maxDaysInAdvanceToBook;
    }

    if (
      maxHoursInAdvanceToCancel !== undefined &&
      maxHoursInAdvanceToCancel !== null
    ) {
      toUpdate.maxHoursInAdvanceToCancel = maxHoursInAdvanceToCancel;
    }

    if (profession !== undefined && profession !== null) {
      if (profession === {}) {
        toUpdate.profession = deleteField();
      } else {
        toUpdate.profession = profession;
      }
    }

    if (social !== undefined && social !== null) {
      if (social === []) {
        toUpdate.social = deleteField();
      } else {
        toUpdate.social = social;
      }
    }

    if (
      assets &&
      assets.logoFilename &&
      assets.launchFilename &&
      assets.storyFilename
    ) {
      toUpdate.assets = {
        logoFilename: assets.logoFilename,
        launchFilename: assets.launchFilename,
        storyFilename: assets.storyFilename
      };
    }

    store
      .collection("shops")
      .doc(shopId)
      .update(toUpdate)
      .then(resolve, reject);
  });
};

export const onGetCustomerInfo = (shopId, customerId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("customers")
    .doc(customerId)
    .onSnapshot((doc) => {
      cb(doc.data());
    });
};

export const onGetBarberInfo = (shopId, barberId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("barbers")
    .doc(barberId)
    .onSnapshot((doc) => {
      cb(doc.data());
    });
};

export const updateBarber = (
  shopId,
  barberId,
  name,
  emailAddress,
  phoneNumber,
  imageFilename
) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }

    const toUpdate = {
      name,
      updatedAt: serverTimestamp()
    };

    if (emailAddress !== undefined && emailAddress !== null) {
      if (emailAddress === "") {
        toUpdate.emailAddress = deleteField();
      } else {
        toUpdate.emailAddress = emailAddress;
      }
    }

    if (phoneNumber !== undefined && phoneNumber !== null) {
      if (phoneNumber === "") {
        toUpdate.phoneNumber = deleteField();
      } else {
        toUpdate.phoneNumber = phoneNumber;
      }
    }

    if (imageFilename !== undefined && imageFilename !== null) {
      if (imageFilename === "") {
        toUpdate.imageFilename = deleteField();
      } else {
        toUpdate.imageFilename = imageFilename;
      }
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("barbers")
      .doc(barberId)
      .update(toUpdate)
      .then(resolve, reject);
  });
};

export const onGetServiceInfo = (shopId, serviceId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("services")
    .doc(serviceId)
    .onSnapshot((doc) => {
      cb(doc.data());
    });
};

export const addService = (shopId, name, color, duration, price) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }
    if (color === undefined || color === null || color === "") {
      return reject("color is invalid");
    }
    if (duration === undefined || duration === null || duration <= 0) {
      return reject("duration is invalid");
    }
    if (price === undefined || price === null || price < 0) {
      return reject("price is invalid");
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("services")
      .add({ name, color, duration, price, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const updateService = (
  shopId,
  serviceId,
  name,
  color,
  duration,
  price
) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }
    if (color === undefined || color === null || color === "") {
      return reject("color is invalid");
    }
    if (duration === undefined || duration === null || duration <= 0) {
      return reject("duration is invalid");
    }
    if (price === undefined || price === null || price < 0) {
      return reject("price is invalid");
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("services")
      .doc(serviceId)
      .update({ name, color, duration, price, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const removeService = (shopId, serviceId) => {
  return new Promise((resolve, reject) => {
    store
      .collection("shops")
      .doc(shopId)
      .collection("services")
      .doc(serviceId)
      .update({ isRemoved: true, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const onGetShopBarbers = (shopId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("barbers")
    .onSnapshot((querySnapshot) => {
      let barbers = [];

      querySnapshot.forEach((doc) => {
        let barber = doc.data();
        barber.id = doc.id;
        barbers.push(barber);
      });

      cb(barbers);
    });
};

export const onGetShopServices = (shopId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("services")
    .onSnapshot((querySnapshot) => {
      let services = [];

      querySnapshot.forEach((doc) => {
        let service = doc.data();
        service.id = doc.id;
        services.push(service);
      });

      cb(services);
    });
};

export const onGetShopAvailability = (shopId, beginDate, endDate, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("availability")
    .where("date", ">=", beginDate)
    .where("date", "<=", endDate)
    .where("available", "==", false)
    .onSnapshot((querySnapshot) => {
      let availability = {};

      querySnapshot.forEach((doc) => {
        let data = doc.data();

        if (availability[data.date] === undefined) {
          availability[data.date] = {};
        }
        if (availability[data.date][data.barberId] === undefined) {
          availability[data.date][data.barberId] = {};
        }

        availability[data.date][data.barberId][data.begin] = data.duration;
      });

      cb(availability);
    });
};

export const onGetShopAvailabilityForBarber = (
  shopId,
  barberId,
  beginDate,
  endDate,
  cb
) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("availability")
    .where("date", ">=", beginDate)
    .where("date", "<=", endDate)
    .where("barberId", "==", barberId)
    .where("available", "==", false)
    .onSnapshot((querySnapshot) => {
      let availability = {};

      querySnapshot.forEach((doc) => {
        let data = doc.data();

        if (availability[data.date] === undefined) {
          availability[data.date] = {};
        }

        availability[data.date][data.begin] = data.duration;
      });

      cb(availability);
    });
};

export const onGetShopSlots = (shopId, beginDate, endDate, cb) => {
  let ref = store
    .collection("shops")
    .doc(shopId)
    .collection("slots")
    .where("date", ">=", beginDate)
    .where("date", "<=", endDate);

  return ref.onSnapshot((querySnapshot) => {
    let slots = {};
    let promises = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();

      if (slots[data.date] === undefined) {
        slots[data.date] = {};
      }
      if (slots[data.date][data.barberId] === undefined) {
        slots[data.date][data.barberId] = {};
      }

      let hour = Math.floor(data.begin / 60);

      if (slots[data.date][data.barberId][hour] === undefined) {
        slots[data.date][data.barberId][hour] = [];
      }

      let slot = {
        id: doc.id,
        begin: data.begin,
        duration: data.duration,
        type: data.type,
        customerId: data.customerId,
        data: {
          serviceId: data.serviceId,
          payment: {}
        }
      };

      slot.data.isNoShow = data.isNoShow === true;

      if (data.paymentMethod) {
        slot.data.payment.method = data.paymentMethod;
      }
      if (data.paymentAmount) {
        slot.data.payment.amount = data.paymentAmount;
      }
      if (data.couponAmount) {
        slot.data.payment.coupon = data.couponAmount;
      }

      slots[data.date][data.barberId][hour].push(slot);

      promises.push(
        new Promise((resolve, reject) => {
          onGetCustomerInfo(shopId, data.customerId, (info) => {
            if (info) {
              slot.data.customer = {};

              if (info.name) {
                slot.data.customer.name = info.name;
              }
              if (info.phone) {
                slot.data.customer.phone = info.phone;
              }
              if (info.userId) {
                slot.data.customer.userId = info.userId;
              }
            }
            resolve();
          });
        })
      );
    });

    Promise.all(promises).then(() => {
      cb(slots);
    });
  });
};

export const onGetShopSlotsForBarber = (
  shopId,
  barberId,
  beginDate,
  endDate,
  cb
) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("slots")
    .where("date", ">=", beginDate)
    .where("date", "<=", endDate)
    .where("barberId", "==", barberId)
    .onSnapshot((querySnapshot) => {
      let slots = {};
      let promises = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        let hour = Math.floor(data.begin / 60);

        if (slots[data.date] === undefined) {
          slots[data.date] = [];
        }
        if (slots[data.date][hour] === undefined) {
          slots[data.date][hour] = [];
        }

        let slot = {
          id: doc.id,
          begin: data.begin,
          duration: data.duration,
          type: data.type,
          customerId: data.customerId,
          data: {
            serviceId: data.serviceId,
            payment: {}
          }
        };

        slot.data.isNoShow = data.isNoShow === true;

        if (data.paymentMethod) {
          slot.data.payment.method = data.paymentMethod;
        }
        if (data.paymentAmount) {
          slot.data.payment.amount = data.paymentAmount;
        }
        if (data.couponAmount) {
          slot.data.payment.coupon = data.couponAmount;
        }

        slots[data.date][hour].push(slot);

        promises.push(
          new Promise((resolve, reject) => {
            onGetCustomerInfo(shopId, data.customerId, (info) => {
              if (info) {
                slot.data.customer = {};

                if (info.name) {
                  slot.data.customer.name = info.name;
                }
                if (info.phone) {
                  slot.data.customer.phone = info.phone;
                }
                if (info.userId) {
                  slot.data.customer.userId = info.userId;
                }
              }

              resolve();
            });
          })
        );
      });

      Promise.all(promises).then(() => {
        cb(slots);
      });
    });
};

export const getCustomerId = (shopId, userId) => {
  return new Promise((resolve, reject) => {
    let shopRef = store.collection("shops").doc(shopId);
    return shopRef
      .collection("customers")
      .where("userId", "==", userId)
      .get()
      .then((docs) => {
        let customerId;

        if (docs.size === 1) {
          docs.forEach((doc) => {
            customerId = doc.id;
          });
        } else if (docs.size === 0) {
          return reject("no customerId found");
        } else {
          return reject("found more then one customerId");
        }

        if (customerId) {
          return resolve(customerId);
        } else {
          return reject("no customerId found");
        }
      })
      .catch(reject);
  });
};

export const getCustomerByUserId = (shopId, userId) => {
  return new Promise((resolve, reject) => {
    let shopRef = store.collection("shops").doc(shopId);
    return shopRef
      .collection("customers")
      .where("userId", "==", userId)
      .get()
      .then((docs) => {
        let customer;

        if (docs.size === 1) {
          docs.forEach((doc) => {
            customer = doc.data();
            customer.id = doc.id;
          });
        } else if (docs.size === 0) {
          return reject("no customer found");
        } else {
          return reject("found more then one customer");
        }

        if (customer) {
          return resolve(customer);
        } else {
          return reject("no customer found");
        }
      })
      .catch(reject);
  });
};

export const getCustomerInfo = (shopId, customerId) => {
  return new Promise((resolve, reject) => {
    return store
      .collection("shops")
      .doc(shopId)
      .collection("customers")
      .doc(customerId)
      .get()
      .then((doc) => {
        resolve(doc.data());
      })
      .catch(reject);
  });
};

export const onGetCustomerSlots = (shopId, customerId, fromDate, cb) => {
  let slotsRef = store
    .collection("shops")
    .doc(shopId)
    .collection("slots")
    .where("customerId", "==", customerId)
    .orderBy("date", "desc");

  if (fromDate) {
    slotsRef = slotsRef.where("date", ">=", fromDate);
  }

  return slotsRef.onSnapshot((querySnapshot) => {
    let slots = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      slots.push(data);
    });

    cb(slots);
  });
};

export const onGetCustomerReservations = (shopId, customerId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("reservations")
    .where("customerId", "==", customerId)
    .onSnapshot((querySnapshot) => {
      let reservations = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        reservations.push(data);
      });

      cb(reservations);
    });
};

export const onGetCustomerHairdos = (shopId, customerId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("customers")
    .doc(customerId)
    .collection("hairdos")
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let hairdos = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        hairdos.push(data);
      });

      cb(hairdos);
    });
};

export const onGetShopEarnings = (shopId, barberId, beginDate, endDate, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("slots")
    .where("date", ">=", beginDate)
    .where("date", "<=", endDate)
    .where("barberId", "==", barberId)
    .onSnapshot((querySnapshot) => {
      let total = {
        all: 0,
        onlyProducts: 0,
        methods: {},
        products: {}
      };
      let methods = {};
      let products = {};
      let days = {};

      querySnapshot.forEach((doc) => {
        let data = doc.data();

        let totalPaid = 0;
        if (data.paymentAmount) {
          totalPaid += data.paymentAmount;
        }
        if (data.couponAmount) {
          totalPaid += data.couponAmount;
        }
        if (totalPaid) {
          // Overall

          total.all += totalPaid;

          // On date

          if (days[data.date] === undefined) {
            days[data.date] = {
              total: 0,
              onlyProducts: 0,
              methods: {},
              products: {}
            };
          }

          days[data.date].total += totalPaid;

          // Payment method

          // For regular payments...

          if (data.paymentAmount) {
            const paymentMethod = data.paymentMethod
              ? data.paymentMethod
              : "onbekend";

            if (total["methods"][paymentMethod] === undefined) {
              total["methods"][paymentMethod] = 0;
            }
            total["methods"][paymentMethod] += data.paymentAmount;

            if (days[data.date]["methods"][paymentMethod] === undefined) {
              days[data.date]["methods"][paymentMethod] = 0;
            }
            days[data.date]["methods"][paymentMethod] += data.paymentAmount;
          }

          // ... and for coupons

          if (data.couponAmount) {
            if (total["methods"]["cadeaubon"] === undefined) {
              total["methods"]["cadeaubon"] = 0;
            }
            total["methods"]["cadeaubon"] += data.couponAmount;

            if (days[data.date]["methods"]["cadeaubon"] === undefined) {
              days[data.date]["methods"]["cadeaubon"] = 0;
            }
            days[data.date]["methods"]["cadeaubon"] += data.couponAmount;
          }

          // Payment method count

          if (data.paymentMethod) {
            if (methods[data.paymentMethod] === undefined) {
              methods[data.paymentMethod] = 0;
            }

            methods[data.paymentMethod]++;
          }
          if (data.couponAmount) {
            if (methods["cadeaubon"] === undefined) {
              methods["cadeaubon"] = 0;
            }

            methods["cadeaubon"]++;
          }

          // Purchased products

          if (data.purchasedProducts) {
            data.purchasedProducts.forEach((product) => {
              if (product.id !== undefined) {
                if (products[product.id] === undefined) {
                  products[product.id] = 0;
                }
                products[product.id]++;

                if (product.price !== undefined) {
                  total.onlyProducts += product.price;
                  days[data.date].onlyProducts += product.price;

                  if (total["products"][product.id] === undefined) {
                    total["products"][product.id] = 0;
                  }
                  total["products"][product.id] += product.price;

                  if (days[data.date]["products"][product.id] === undefined) {
                    days[data.date]["products"][product.id] = 0;
                  }
                  days[data.date]["products"][product.id] += product.price;
                }
              }
            });
          }
        }
      });

      cb({
        total,
        methods,
        products,
        days
      });
    });
};

export const setBarberOpeningHours = (shopId, barberId, openingHours) => {
  return new Promise((resolve, reject) => {
    store
      .collection("shops")
      .doc(shopId)
      .collection("barbers")
      .doc(barberId)
      .update({ openingHours })
      .then(resolve, reject);
  });
};

export const setBarberBookingHours = (shopId, barberId, bookingHours) => {
  return new Promise((resolve, reject) => {
    store
      .collection("shops")
      .doc(shopId)
      .collection("barbers")
      .doc(barberId)
      .update({ bookingHours })
      .then(resolve, reject);
  });
};

function parsePaymentMethod(paymentMethod) {
  if (paymentMethod === undefined || paymentMethod === "") {
    return deleteField();
  } else {
    return paymentMethod;
  }
}

function parsePaymentOrCouponAmount(paymentOrCouponAmount) {
  if (
    paymentOrCouponAmount === null ||
    paymentOrCouponAmount === undefined ||
    paymentOrCouponAmount === ""
  ) {
    return deleteField();
  } else {
    const parsedPaymentOrCouponAmount = parseInt(paymentOrCouponAmount);
    if (isNaN(parsedPaymentOrCouponAmount)) {
      throw new Error("parsedPaymentOrCouponAmount is NaN");
    }
    return parsedPaymentOrCouponAmount;
  }
}

export const setSlotPayment = (
  shopId,
  slotId,
  paymentMethod,
  paymentAmount,
  couponAmount
) => {
  return new Promise((resolve, reject) => {
    try {
      const newPaymentMethod = parsePaymentMethod(paymentMethod);
      const newPaymentAmount = parsePaymentOrCouponAmount(paymentAmount);
      const newCouponAmount = parsePaymentOrCouponAmount(couponAmount);

      store
        .collection("shops")
        .doc(shopId)
        .collection("slots")
        .doc(slotId)
        .update({
          paymentMethod: newPaymentMethod,
          paymentAmount: newPaymentAmount,
          couponAmount: newCouponAmount
        })
        .then(resolve, reject);
    } catch (error) {
      reject(error);
    }
  });
};

export const setSlotPaymentMethod = (shopId, slotId, paymentMethod) => {
  return new Promise((resolve, reject) => {
    try {
      const newPaymentMethod = parsePaymentMethod(paymentMethod);

      store
        .collection("shops")
        .doc(shopId)
        .collection("slots")
        .doc(slotId)
        .update({
          paymentMethod: newPaymentMethod
        })
        .then(resolve, reject);
    } catch (error) {
      reject(error);
    }
  });
};

export const setSlotPaymentAndCouponAmount = (
  shopId,
  slotId,
  paymentAmount,
  couponAmount
) => {
  return new Promise((resolve, reject) => {
    try {
      const newPaymentAmount = parsePaymentOrCouponAmount(paymentAmount);
      const newCouponAmount = parsePaymentOrCouponAmount(couponAmount);

      store
        .collection("shops")
        .doc(shopId)
        .collection("slots")
        .doc(slotId)
        .update({
          paymentAmount: newPaymentAmount,
          couponAmount: newCouponAmount
        })
        .then(resolve, reject);
    } catch (error) {
      reject(error);
    }
  });
};

export const setSlotIsNoShow = (shopId, slotId, isNoShow) => {
  return new Promise((resolve, reject) => {
    let toUpdate = {};

    if (isNoShow) {
      toUpdate.isNoShow = true;
      toUpdate.paymentAmount = deleteField();
      toUpdate.paymentMethod = deleteField();
    } else {
      toUpdate.isNoShow = deleteField();
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("slots")
      .doc(slotId)
      .update(toUpdate)
      .then(resolve, reject);
  });
};

export const setSlotPurchasedProducts = (shopId, slotId, purchasedProducts) => {
  return new Promise((resolve, reject) => {
    let newPurchasedProducts;

    if (purchasedProducts && purchasedProducts.length > 0) {
      newPurchasedProducts = purchasedProducts;
    } else {
      newPurchasedProducts = deleteField();
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("slots")
      .doc(slotId)
      .update({ purchasedProducts: newPurchasedProducts })
      .then(resolve, reject);
  });
};

export const setSlotAppliedProducts = (shopId, slotId, appliedProducts) => {
  return new Promise((resolve, reject) => {
    let newAppliedProducts;

    if (appliedProducts && appliedProducts.length > 0) {
      newAppliedProducts = appliedProducts;
    } else {
      newAppliedProducts = deleteField();
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("slots")
      .doc(slotId)
      .update({ appliedProducts: newAppliedProducts })
      .then(resolve, reject);
  });
};

export const onGetShopNotifications = (shopId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("notifications")
    .where("isSeen", "==", false)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let notifications = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        notifications.push(data);
      });

      cb(notifications);
    });
};

export const onGetBarberNotifications = (shopId, barberId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("notifications")
    .where("barberId", "==", barberId)
    .where("isSeen", "==", false)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      let notifications = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        notifications.push(data);
      });

      cb(notifications);
    });
};

export const setNotificationSeen = (shopId, notificationId) => {
  return new Promise((resolve, reject) => {
    const toUpdate = {
      isSeen: true,
      seenOn: serverTimestamp()
    };

    store
      .collection("shops")
      .doc(shopId)
      .collection("notifications")
      .doc(notificationId)
      .update(toUpdate)
      .then(resolve, reject);
  });
};

export const onGetShopProducts = (shopId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("products")
    .orderBy("name")
    .onSnapshot((querySnapshot) => {
      let products = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        products.push(data);
      });

      cb(products);
    });
};

export const addProduct = (shopId, name, price) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }
    if (price === undefined || price === null || price < 0) {
      return reject("price is invalid");
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("products")
      .add({ name, price, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const updateProduct = (shopId, productId, name, price) => {
  return new Promise((resolve, reject) => {
    if (name === undefined || name === null || name === "") {
      return reject("name is invalid");
    }
    if (price === undefined || price === null || price < 0) {
      return reject("price is invalid");
    }

    store
      .collection("shops")
      .doc(shopId)
      .collection("products")
      .doc(productId)
      .update({ name, price, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const removeProduct = (shopId, productId) => {
  return new Promise((resolve, reject) => {
    store
      .collection("shops")
      .doc(shopId)
      .collection("products")
      .doc(productId)
      .update({ isRemoved: true, updatedAt: serverTimestamp() })
      .then(resolve, reject);
  });
};

export const onGetShopReservations = (shopId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("reservations")
    .onSnapshot((querySnapshot) => {
      let reservations = {};
      let promises = [];

      querySnapshot.forEach((doc) => {
        let reservation = doc.data();
        reservation.id = doc.id;

        if (reservations[reservation.collectOnDate] === undefined) {
          reservations[reservation.collectOnDate] = [];
        }

        reservations[reservation.collectOnDate].push(reservation);

        promises.push(
          new Promise((resolve, reject) => {
            onGetCustomerInfo(shopId, reservation.customerId, (info) => {
              reservation.data = info;
              resolve();
            });
          })
        );
      });

      Promise.all(promises).then(() => {
        cb(reservations);
      });
    });
};

export const onGetSlotInfo = (shopId, slotId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("slots")
    .doc(slotId)
    .onSnapshot((doc) => {
      cb(doc.data());
    });
};

export const onGetHolidays = (shopId, barberId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("availability")
    .where("barberId", "==", barberId)
    .where("type", "==", "holiday")
    .orderBy("date", "asc")
    .onSnapshot((querySnapshot) => {
      let holidays = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        holidays.push(data);
      });

      cb(holidays);
    });
};

export const onGetHolidaysFromDate = (shopId, barberId, date, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("availability")
    .where("barberId", "==", barberId)
    .where("type", "==", "holiday")
    .where("date", ">=", date)
    .orderBy("date", "asc")
    .onSnapshot((querySnapshot) => {
      let holidays = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        holidays.push(data);
      });

      cb(holidays);
    });
};

export const addHoliday = (
  shopId,
  barberId,
  date,
  numberOfDays = 1,
  begin = 0,
  duration = 1440
) => {
  let holiday = {
    type: "holiday",
    available: false,
    barberId,
    begin,
    duration,
    timestamp: serverTimestamp()
  };

  const ref = store
    .collection("shops")
    .doc(shopId)
    .collection("availability");
  let currentDate = Moment(date);

  let promises = [];
  for (let i = 0; i < numberOfDays; i++) {
    holiday.date = jsDateToDateString(currentDate);
    promises.push(ref.add(holiday));

    currentDate.add(1, "day");
  }

  return Promise.all(promises);
};

/*export const removeHoliday = (shopId, barberId, date) => {
  return store.collection('shops').doc(shopId)
    .collection('availability')
    .where('barberId', '==', barberId)
    .where('type', '==', 'holiday')
    .where('date', '==', 'date')
    .delete();
}*/

export const removeAvailability = (shopId, availabilityId) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("availability")
    .doc(availabilityId)
    .delete();
};

export const addHairdo = (shopId, barberId, customerId, filename) => {
  let hairdo = {
    barberId,
    filename,
    timestamp: serverTimestamp()
  };

  const ref = store
    .collection("shops")
    .doc(shopId)
    .collection("customers")
    .doc(customerId)
    .collection("hairdos");
  return ref.add(hairdo);
};

export const getCrew = (userId) => {
  return new Promise((resolve, reject) => {
    return store
      .collection("crew")
      .doc(userId)
      .get()
      .then((doc) => {
        const crew = doc.data();

        resolve(crew);
      });
  });
};

export const getIsCrew = (shopId, userId) => {
  return new Promise((resolve, reject) => {
    return store
      .collection("shops")
      .doc(shopId)
      .collection("crew")
      .doc(userId)
      .onSnapshot((doc) => {
        const isCrew = doc.data();
        return resolve(isCrew !== undefined && isCrew.enabled === true);
      });
  });
};

export const onIsCrew = (shopId, userId, cb) => {
  return store
    .collection("shops")
    .doc(shopId)
    .collection("crew")
    .doc(userId)
    .onSnapshot((doc) => {
      const isCrew = doc.data();
      cb(isCrew && isCrew.enabled, isCrew);
    });
};

const putTask = (shopId, userId, isCrew, type, action, data, onProgress) => {
  return new Promise((resolve, reject) => {
    let task = {
      shopId,
      userId,
      isCrew,
      type,
      action,
      timestamp: serverTimestamp()
    };
    if (data) {
      task.data = data;
    }

    console.info(task);

    store
      .collection("tasks")
      .add(task)
      .then((taskRef) => {
        const taskId = taskRef.id;

        if (onProgress) {
          onProgress(taskId);
        }

        const unsubscribe = store
          .collection("tasks")
          .doc(taskId)
          .onSnapshot((doc) => {
            const task = doc.data();
            console.log(task);

            if (task.result) {
              unsubscribe();

              if (task.result.success) {
                resolve(task.result.data);
              } else {
                reject(task.result.error);
              }
            }
          });
      })
      .catch(reject);
  });
};

export const addBooking = (
  shopId,
  barberId,
  serviceId,
  date,
  begin,
  duration,
  phone,
  name,
  email,
  customerId,
  userId,
  isCrew,
  ignoreAvailabilityCheck,
  onProgress
) => {
  console.info(
    "addBooking",
    shopId,
    barberId,
    serviceId,
    date,
    begin,
    duration,
    phone,
    name,
    email,
    customerId,
    userId,
    isCrew,
    ignoreAvailabilityCheck
  );

  let data = {
    barberId,
    serviceId,
    date,
    begin,
    phone,
    name,
    email
  };

  if (duration) {
    data.duration = duration;
  }
  if (ignoreAvailabilityCheck) {
    data.ignoreAvailabilityCheck = true;
  }
  if (customerId) {
    data.customerId = customerId;
  }

  return putTask(shopId, userId, isCrew, "booking", "add", data, onProgress);
};

export const removeBooking = (shopId, userId, isCrew, slotId, onProgress) => {
  let data = {
    slotId
  };

  return putTask(shopId, userId, isCrew, "booking", "remove", data, onProgress);
};

export const addReservation = (
  shopId,
  userId,
  isCrew,
  products,
  onProgress
) => {
  console.info("addReservation", shopId, userId, products, userId, isCrew);

  let data = {
    products
  };

  return putTask(
    shopId,
    userId,
    isCrew,
    "reservation",
    "add",
    data,
    onProgress
  );
};

export const removeReservation = (
  shopId,
  userId,
  isCrew,
  reservationId,
  onProgress
) => {
  let data = {
    reservationId
  };

  return putTask(
    shopId,
    userId,
    isCrew,
    "reservation",
    "remove",
    data,
    onProgress
  );
};

export const updateUser = (
  shopId,
  userId,
  isCrew,
  fieldsToUpdate,
  onProgress
) => {
  console.info("updateUserEmail", shopId, userId, fieldsToUpdate);

  let data = {
    fieldsToUpdate
  };

  return putTask(shopId, userId, isCrew, "user", "update", data, onProgress);
};

export const createUser = (shopId, userId, isCrew, onProgress) => {
  console.info("createUser", shopId, userId, isCrew);

  return putTask(
    shopId,
    userId,
    isCrew,
    "user",
    "create",
    undefined,
    onProgress
  );
};

export const updateCustomer = (
  shopId,
  userId,
  isCrew,
  customerId,
  fieldsToUpdate,
  onProgress
) => {
  console.info(
    "updateCustomer",
    shopId,
    userId,
    isCrew,
    customerId,
    fieldsToUpdate
  );

  let data = {
    fieldsToUpdate,
    customerId
  };

  return putTask(
    shopId,
    userId,
    isCrew,
    "customer",
    "update",
    data,
    onProgress
  );
};

export const mergeCustomers = (
  shopId,
  userId,
  isCrew,
  masterCustomerId,
  slaveCustomerId,
  allowMasterSlaveSwitch,
  onProgress
) => {
  console.info(
    "updateCustomer",
    shopId,
    userId,
    masterCustomerId,
    slaveCustomerId,
    userId,
    isCrew
  );

  let data = {
    masterCustomerId,
    slaveCustomerId
  };

  if (allowMasterSlaveSwitch === true) {
    data.allowMasterSlaveSwitch = true;
  }

  return putTask(shopId, userId, isCrew, "customer", "merge", data, onProgress);
};
