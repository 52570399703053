import React, { Component } from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";

import { firebase } from "../firebase";

import AuthContext from "../context/auth";

import Loading from "../modals/Loading";
import Error from "../modals/Error";
import VerifyPhone from "../modals/VerifyPhone";

import { areEqualShallow, parsePhone, parseEmail } from "../utils";

const styles = theme => ({
  textField: {
    /*left: '50%',
    transform: 'translate(-50%, 0)'*/
    width: "100%;"
  },
  formButton: {
    display: "block",
    border: "none",
    width: "100%",
    padding: 0,
    background: "none"
  },
  hidden: {
    display: "none"
  }
});

class VerifiedCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      error: {
        open: false
      },
      verifyPhone: {
        open: false
      },
      validName: false,
      validPhone: false,
      validEmail: false,
      consentToAgreement: false,
      customer: {
        name: "",
        phone: "",
        email: ""
      }
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameUpdate = this.handleNameUpdate.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
    //this.onEntering = this.onEntering.bind(this);
    //this.handleSave = this.handleSave.bind(this);
  }

  reportInfo(info, opts, cb) {
    //return this.reportToBugsnag(info, opts ? opts : {}, cb, "info");
  }

  reportWarning(error, opts, cb) {
    return this.reportToBugsnag(error, opts, cb, "warning");
  }

  reportError(error, opts, cb) {
    return this.reportToBugsnag(error, opts, cb, "error");
  }

  reportToBugsnag(error, opts, cb, severity) {
    console.log("reportToBugsnag", error, opts, cb, severity);
    const { bugsnagClient } = this.props;

    if (bugsnagClient) {
      bugsnagClient.notify(error, { ...opts, severity }, cb);
    } else {
      console.error("bugsnagClient is not defined");
    }
  }

  componentDidMount() {
    const { auth } = this.props;

    if (auth && auth.session) {
      const customer = {
        name: "",
        phone: "",
        email: "",
        isVerified: false
      };

      if (auth.customer) {
        if (auth.customer.phone) {
          customer.phone = auth.customer.phone;
        }
        if (auth.customer.name) {
          customer.name = auth.customer.name;
        }
        if (auth.customer.email) {
          customer.email = auth.customer.email;
        }
        customer.isVerified = auth.customer.isVerified === true;
      }

      this.setState({ session: auth.session, customer }, this.notifyUpdate);
    }

    this.refs.form.onsubmit = event => {
      event.preventDefault();
      return true;
    };

    this.recaptchaVerifier = new firebase.recaptcha("verify-button", {
      size: "invisible",
      callback: response => {
        console.log("response:", response);

        this.reportInfo(`recaptchaResponse: ${response}`);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        //onSignInSubmit();
      },
      "expired-callback": reason => {
        console.error("recaptcha-expired:", reason);

        this.reportError("recaptcha-expired", {
          metaData: {
            description: "recaptcha-expired",
            reason
          }
        });
      },
      "error-callback": error => {
        console.error("recaptcha-error:", error);

        this.reportError("recaptcha-error", {
          metaData: {
            description: "recaptcha-error",
            error
          }
        });
      }
    });
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    const { session } = this.state;

    if (auth && auth.session !== session) {
      const customer = {
        name: "",
        phone: "",
        email: "",
        isVerified: false
      };

      if (auth.customer) {
        if (auth.customer.phone) {
          customer.phone = auth.customer.phone;
        }
        if (auth.customer.name) {
          customer.name = auth.customer.name;
        }
        if (auth.customer.email) {
          customer.email = auth.customer.email;
        }
        customer.isVerified = auth.customer.isVerified === true;
      }

      this.setState({ session: auth.session, customer }, this.notifyUpdate);
    }
  }

  notifyUpdate() {
    const { onChange } = this.props;
    const { customer } = this.state;

    if (onChange) {
      customer.isVerified =
        parsePhone(customer.phone) &&
        customer.name &&
        parseEmail(customer.email);
      onChange(customer);
    }
  }

  handleVerify = event => {
    const { consentToAgreement, validPhone } = this.state;

    if (!consentToAgreement) {
      return toast("Gelieve de privacy policy te accepteren", {
        autoClose: 3000
      });
    }

    if (validPhone) {
      const { phone } = this.state.customer;

      this.setState(
        {
          loading: {
            open: true,
            description:
              "We sturen je een SMS bericht met een verificatiecode..."
          }
        },
        () => {
          this.reportInfo(`firebase.auth.signInWithPhoneNumber: ${phone}`);
          firebase.auth
            .signInWithPhoneNumber(phone, this.recaptchaVerifier)
            .then(confirmationResult => {
              this.recaptchaVerifier.reset();

              console.info(confirmationResult);
              this.reportInfo(`signInWithPhoneNumberResult`, {
                metaData: {
                  description: "signInWithPhoneNumber-result",
                  confirmationResult
                }
              });
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              //this.handleVerifyPhoneOpen();

              this.confirmationResult = confirmationResult;

              this.setState({
                loading: {
                  open: false
                },
                verifyPhone: {
                  open: true
                }
              });
            })
            .catch(error => {
              console.error("signInWithPhoneNumber error:", error);

              this.recaptchaVerifier.reset();

              this.setState({
                loading: {
                  open: false
                },
                error: {
                  open: true,
                  description: `${error.message} (${error.code})`
                }
              });

              this.reportError(error, {
                metaData: {
                  description: "signInWithPhoneNumber-error",
                  error
                }
              });
            });
        }
      );
    }

    return false;
  };

  handleVerifyPhoneClose = code => {
    const { onSignedIn } = this.props;

    this.reportInfo(`handleVerifyPhoneClose: ${code}`, {
      metaData: {
        description: "handleVerifyPhoneClose",
        confirmationResult: this.confirmationResult
      }
    });
    if (code && this.confirmationResult) {
      this.setState(
        {
          verifyPhone: {
            open: false
          },
          loading: {
            open: true,
            description: "Verificatiecode controleren..."
          }
        },
        () => {
          this.reportInfo(`confirmationResult.confirm: ${code}`);
          this.confirmationResult
            .confirm(code)
            .then(result => {
              console.info(result);
              var user = result.user;

              !this.isCancelled &&
                this.setState(
                  {
                    loading: {
                      open: false
                    }
                  },
                  () => {}
                );

              /*if (onSignedIn) {
                setTimeout(onSignedIn, 0);
              }*/

              this.reportInfo(`confirmationResult.confirmResult`, {
                metaData: { description: "confirmResult", result }
              });
            })
            .catch(error => {
              console.error("confirmationResult.confirm error", error);

              this.setState({
                loading: {
                  open: false
                },
                error: {
                  open: true,
                  description: `${error.message} (${error.code})`
                }
              });

              this.reportError(error, {
                metaData: {
                  description: "confirmationResult.confirm-error",
                  error
                }
              });
            });
        }
      );
    } else {
      console.error(
        "code or this.confirmationResult is invalid:",
        code,
        this.confirmationResult
      );

      this.setState({
        verifyPhone: {
          open: false
        }
      });

      this.reportError("this.confirmationResultInvalid", {
        metaData: {
          description: "this.confirmationResultInvalid-error",
          code
        }
      });
    }
  };

  handleNameUpdate() {
    const { auth } = this.props;
    const { name } = this.state.customer;

    if (auth && auth.updateName) {
      const toastId = toast("Naam bewaren...", {
        autoClose: false
      });

      auth
        .updateName(name)
        .then(() => {
          toast.update(toastId, {
            render: "Naam bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });
        })
        .catch(error => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    }
  }

  handleEmailUpdate() {
    const { shop, auth } = this.props;
    const { email } = this.state.customer;

    if (auth && auth.updateEmail) {
      const toastId = toast("E-mailadres bewaren...", {
        autoClose: false
      });

      auth
        .updateEmail(shop.id, email)
        .then(() => {
          toast.update(toastId, {
            render: "E-mailadres bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });
        })
        .catch(error => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    }
  }

  handlePhoneChange(event) {
    const { customer } = this.state;

    const phone = event.target.value;
    const parsedPhone = parsePhone(phone);
    const validPhone = parsedPhone !== undefined;

    if (validPhone) {
      customer.phone = parsedPhone;
    } else {
      customer.phone = phone;
    }

    this.setState(
      {
        customer,
        validPhone
      },
      this.notifyUpdate
    );
  }

  handleNameChange(event) {
    const customer = this.state.customer;

    const name = event.target.value;

    customer.name = name;

    this.setState(
      {
        customer,
        validName: name && name !== ""
      },
      this.notifyUpdate
    );
  }

  handleEmailChange(event) {
    const { auth } = this.props;
    const { customer } = this.state;

    const email = event.target.value;
    const parsedEmail = parseEmail(email);
    const validEmail = parsedEmail !== undefined;

    if (validEmail) {
      customer.email = parsedEmail;
    } else {
      customer.email = email;
    }

    this.setState(
      {
        customer,
        validEmail
      },
      this.notifyUpdate
    );
  }

  handleVerifyPhoneOpen = () => {
    this.setState({ verifyPhone: { open: true } });
  };

  handleErrorClose = () => {
    this.setState({ error: { open: false } });
  };

  handleConsentChange = () => {
    this.setState({ consentToAgreement: !this.state.consentToAgreement });
  };

  render() {
    const { auth, showUpdateButtons, classes } = this.props;
    const {
      loading,
      error,
      verifyPhone,
      customer,
      validName,
      validPhone,
      validEmail,
      consentToAgreement
    } = this.state;

    return (
      <div className={classes.root}>
        <Loading open={loading.open} description={loading.description} />
        <Error
          open={error.open}
          description={error.description}
          onClose={this.handleErrorClose}
        />
        <VerifyPhone
          open={verifyPhone.open}
          onClose={this.handleVerifyPhoneClose}
          phone={customer.phone}
        />
        <form ref="form" className={classes.container} autoComplete="off">
          <List>
            <ListItem>
              <TextField
                required
                disabled={auth && auth.user ? true : false}
                id="phone"
                label="GSM nummer"
                helperText="Gebruik een Belgisch of Nederlands mobiel nummer"
                className={classes.textField}
                value={customer.phone}
                onChange={this.handlePhoneChange}
                margin="normal"
              />
            </ListItem>
            {auth && auth.user && auth.customer === undefined && (
              <ListItem>
                <CircularProgress size={20} />
                <ListItemText primary="Even geduld. Gebruiker wordt aangemaakt..." />
              </ListItem>
            )}
            {auth && auth.customer && (
              <div>
                <ListItem>
                  <TextField
                    required
                    id="name"
                    label="Naam"
                    className={classes.textField}
                    value={customer.name}
                    onChange={this.handleNameChange}
                    margin="normal"
                  />
                  {showUpdateButtons &&
                    validName &&
                    auth.customer.name !== customer.name && (
                      <Button onClick={this.handleNameUpdate}>Bewaren</Button>
                    )}
                </ListItem>
                <ListItem>
                  <TextField
                    required
                    id="email"
                    label="E-mailadres"
                    helperText="Vul je e-mailadres in om afspraakherinneringen te ontvangen"
                    className={classes.textField}
                    value={customer.email}
                    onChange={this.handleEmailChange}
                    margin="normal"
                  />
                  {showUpdateButtons &&
                    validEmail &&
                    auth.customer.email !== customer.email && (
                      <Button onClick={this.handleEmailUpdate}>Bewaren</Button>
                    )}
                </ListItem>
              </div>
            )}

            {!auth ||
              (!auth.customer && (
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consentToAgreement}
                        onChange={this.handleConsentChange}
                        value="consentToAgreement"
                      />
                    }
                    label={
                      <div>
                        Boek je Kapper mag mijn gegevens bewaren om mij te
                        kunnen contacteren (
                        <a
                          target="_blank"
                          href="https://boekjekapper.be/privacy-policy/#klant-kapper"
                        >
                          Privacy Policy
                        </a>
                        )
                      </div>
                    }
                  />
                </ListItem>
              ))}
            <div
              className={
                (!auth || !auth.customer) && validPhone ? "" : classes.hidden
              }
            >
              <button
                id="verify-button"
                type="submit"
                onClick={this.handleVerify}
                className={classes.formButton}
              >
                <ListItem button className={classes.button}>
                  <CheckIcon color="primary" className={classes.leftIcon} />
                  <ListItemText primary="GSM nummer verifiëren" />
                </ListItem>
              </button>
            </div>
          </List>
        </form>
      </div>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(props => (
    <AuthContext.Consumer>
      {auth => <VerifiedCustomer {...props} auth={auth} />}
    </AuthContext.Consumer>
  ))
);
