import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as routes from "../constants/routes";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";

import { withStyles } from "@material-ui/core/styles";

import { auth } from "../firebase";

import AuthContext from "../context/auth";

const styles = theme => ({
  header: {
    boxShadow: "none",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  title: {
    flexGrow: 1,
    fontFamily: "calligraffitiregular", //'shorelines_script_boldregular' 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "lowercase",
    color: "black"
  }
});

class UserHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleSignOut = () => {
    auth.doSignOut();
  };

  render() {
    const { classes, auth, title } = this.props;

    return (
      <AppBar position="fixed" color="default" className={classes.header}>
        <Toolbar>
          <Typography variant="title" color="inherit" className={classes.title}>
            {title}
          </Typography>
          {auth && auth.isCrew && (
            <Link to={routes.CONTROLROOM} className={classes.link}>
              <Button
                color="secondary"
                aria-label="controlroom"
                className={classes.button}
              >
                <WifiTetheringIcon className={classes.extendedIcon} />
              </Button>
            </Link>
          )}
          {auth && auth.customer && (
            <Button onClick={this.handleSignOut} color="secondary">
              Afmelden
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(props => (
  <AuthContext.Consumer>
    {auth => <UserHeader {...props} auth={auth} />}
  </AuthContext.Consumer>
));
