import React, { Component } from "react";

import {
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Button
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { makeCancelable, compareObjects } from "../utils";

import { store, storage } from "../firebase";

import * as modals from "../constants/modals";

import withCrewAuthorization from "../components/withCrewAuthorization";

import CrewHeader from "../components/CrewHeader";

import ShopGeneralInfoFields from "../components/ShopGeneralInfoFields";
import ShopWebsiteInfoFields from "../components/ShopWebsiteInfoFields";
import ShopAppointmentInfoFields from "../components/ShopAppointmentInfoFields";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  content: {
    minHeight: "calc(100vh - 114px)"
  },
  shopHeader: {
    display: "flex"
  },
  shopTitle: {
    width: "100%"
  },
  info: {
    maxWidth: "calc(100vw - 50px)"
  },
  shopAvatar: {
    borderRadius: 5
  },
  barbersHeader: {
    textTransform: "capitalize"
  }
});

class ShopPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshBarberImages();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { barbers } = this.props;

    const prevBarbers = prevProps.barbers;

    const barberImageFilenames = barbers
      ? barbers.map((barber) => barber.imageFilename)
      : undefined;
    const prevBarberImageFilenames = prevBarbers
      ? prevBarbers.map((barber) => barber.imageFilename)
      : undefined;

    if (!compareObjects(barberImageFilenames, prevBarberImageFilenames)) {
      this.refreshBarberImages();
    }
  }

  refreshBarberImages() {
    const { shop, barbers } = this.props;

    if (shop && shop.id && barbers) {
      storage
        .getBarberImages(
          shop.id,
          barbers
            .map((barber) => barber.imageFilename)
            .filter((filename) => filename !== undefined)
        )
        .then((barberImages) => {
          this.setState({ barberImages });
        })
        .catch((error) => {
          this.setState({ barberImages: null });
          console.warn(error);
        });
    } else {
      this.setState({ barberImages: null });
    }
  }

  handleShopGeneralInfoEdit = (event) => {
    const { shop, info, openModal } = this.props;

    openModal(modals.EDIT_SHOP, {
      shop,
      info,
      title: "Algemene informatie aanpassen",
      Fields: ShopGeneralInfoFields
    });
  };

  handleShopWebsiteInfoEdit = (event) => {
    const { shop, info, openModal } = this.props;

    openModal(modals.EDIT_SHOP, {
      shop,
      info,
      title: "Website informatie aanpassen",
      description: "Deze informatie wordt weergegeven op je website.",
      Fields: ShopWebsiteInfoFields
    });
  };

  handleShopAppointmentInfoEdit = (event) => {
    const { shop, info, openModal } = this.props;

    openModal(modals.EDIT_SHOP, {
      shop,
      info,
      title: "Afspraak informatie aanpassen",
      Fields: ShopAppointmentInfoFields
    });
  };

  handleBarberEdit = (barber) => (event) => {
    const { shop, info, openModal } = this.props;

    openModal(modals.EDIT_BARBER, {
      shop,
      info,
      barber
    });
  };

  render() {
    const { shop, info, barbers, toggleDrawer, classes } = this.props;
    const { barberImages } = this.state;

    return (
      <div className={classes.root}>
        <CrewHeader title="Mijn zaak" toggleDrawer={toggleDrawer} />
        <List className={classes.content}>
          <ListSubheader className={classes.shopHeader}>
            <span className={classes.shopTitle}>Algemene informatie</span>
            <Button onClick={this.handleShopGeneralInfoEdit}>Wijzigen</Button>
          </ListSubheader>
          <ListItem>
            <ListItemText primary="Zoals de naam en het adres van je zaak, je website en adres." />
          </ListItem>
          <ListSubheader className={classes.shopHeader}>
            <span className={classes.shopTitle}>Website informatie</span>
            <Button onClick={this.handleShopWebsiteInfoEdit}>Wijzigen</Button>
          </ListSubheader>
          <ListItem>
            <ListItemText primary="Zoals een beschrijving van je zaak, logo en sociale mediakanalen." />
          </ListItem>
          <ListSubheader className={classes.shopHeader}>
            <span className={classes.shopTitle}>Afspraak informatie</span>
            <Button onClick={this.handleShopAppointmentInfoEdit}>
              Wijzigen
            </Button>
          </ListSubheader>
          <ListItem>
            <ListItemText primary="Zoals hoe klanten je aanspreken en tot wanneer ze een afspraak kunnen annuleren." />
          </ListItem>
          <ListSubheader className={classes.barbersHeader}>
            {info && info.profession && info.profession.plural
              ? info.profession.plural
              : "Barbers"}
          </ListSubheader>
          {barbers &&
            barbers.map((barber) => (
              <ListItem key={barber.id} onClick={this.handleBarberEdit(barber)}>
                <ListItemAvatar>
                  <Avatar
                    alt={barber.name}
                    src={
                      barberImages
                        ? barberImages[barber.imageFilename]
                        : undefined
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={barber.name}
                  secondary={
                    <span>
                      {barber.emailAddress
                        ? barber.emailAddress
                        : "(Geen e-mailadres)"}
                      <br />
                      {barber.phoneNumber
                        ? barber.phoneNumber
                        : "(Geen telefoonnummer)"}
                    </span>
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(ShopPage));
