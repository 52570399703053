import React, { Component } from "react";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide
} from "@material-ui/core";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { parseShopFields } from "../utils";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  content: {
    minWidth: 550
  }
});

class EditShop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      website: "",
      address: { street: "", number: "", box: "", zip: "", city: "" },
      tagline: "",
      story: [],
      social: [],
      maxDaysInAdvanceToBook: 60,
      maxHoursInAdvanceToCancel: 24,
      profession: { singular: "", plural: "" }
    };
  }

  componentDidMount() {
    const { info } = this.props;
    if (info) {
      this.setState({ ...info });
    } else {
      this.setState({});
    }
  }

  handleUpdate = (info) => {
    this.setState({ ...info });
  };

  handleSave = () => {
    const { shop } = this.props;

    try {
      const toUpdate = parseShopFields(this.state);

      const toastId = toast("Wijzigingen bewaren...", {
        autoClose: false
      });

      const {
        name,
        tagline,
        story,
        website,
        address,
        maxDaysInAdvanceToBook,
        maxHoursInAdvanceToCancel,
        profession,
        social,
        assets
      } = toUpdate;

      store
        .updateShopInfo(
          shop.id,
          name,
          tagline,
          story,
          website,
          address,
          maxDaysInAdvanceToBook,
          maxHoursInAdvanceToCancel,
          profession,
          social,
          assets
        )
        .then(() => {
          toast.update(toastId, {
            render: "Wijzigingen bewaard",
            type: toast.TYPE.INFO,
            autoClose: 3000
          });

          this.handleClose();
        })
        .catch((error) => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.toString()})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    } catch (error) {
      console.warn(error);
      if (error) {
        toast.error(error.toString());
      }
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      classes,
      open,
      onClose,
      shop,
      info,
      title,
      description,
      Fields,
      type,
      ...other
    } = this.props;
    console.log("fields:", this.props.fields);
    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle>
            <Typography className={classes.title}>{title}</Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography>{description}</Typography>
            {
              <Fields
                shop={shop}
                info={this.state}
                onUpdate={this.handleUpdate}
              />
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Annuleren
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Bewaren
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(EditShop));
