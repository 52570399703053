import React, { Component } from "react";
import PropTypes from "prop-types";

import { store } from "../firebase";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Moment from "react-moment";

const styles = (theme) => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontFamily: "calligraffitiregular", //"shorelines_script_boldregular",
    fontSize: 32,
    color: "black"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  barber: {
    fontSize: 16,
    padding: "20px 0"
  }
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class SelectBarber extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onEntering = this.onEntering.bind(this);
    this.handleBarberConfirm = this.handleBarberConfirm.bind(this);
  }

  componentDidMount() {
    const { shop } = this.props;

    this.unsubscribeShopBarbers = store.onGetShopBarbers(shop.id, (barbers) => {
      this.setState({ barbers: barbers });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeShopBarbers) {
      this.unsubscribeShopBarbers();
      this.unsubscribeShopBarbers = undefined;
    }
  }

  onEntering() {
    const { shop } = this.props;
    if (shop) {
      this.setState({ barberId: shop.barberId });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  handleBarberChange(barberId) {
    //this.setState({ barberId : barberId });
    //setBarberId(barberId);

    const { shop } = this.props;
    shop.update({ barberId: barberId });
    this.handleClose();
  }

  handleBarberConfirm() {
    const { shop } = this.props;
    const { barberId } = this.state;

    shop.update({ barberId: barberId });

    this.handleClose();
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  /*handleGoToLanding = () => {
    window.location.replace("/");
  };*/

  render() {
    const { classes, open, onClose, shop, info, ...other } = this.props;
    const { barberId } = this.state;

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        onEntering={this.onEntering}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="Barber kiezen"
        shop={shop}
      >
        <DialogTitle>
          <div className={classes.dialogTitle}>
            <span className={classes.title}>
              <span className={classes.capitalize}>
                {info && info.profession && info.profession.singular
                  ? info.profession.singular
                  : "Barber"}
              </span>{" "}
              kiezen
            </span>
          </div>
        </DialogTitle>
        {this.state.barbers && this.state.barbers.length > 0 ? (
          <List>
            <ListItem>
              <ListItemText>
                Kies hieronder bij welke{" "}
                {info && info.profession && info.profession.singular
                  ? info.profession.singular
                  : "barber"}{" "}
                je een afspraak wil maken. Je kan dit steeds wijzigen onderaan
                de agenda.
              </ListItemText>
            </ListItem>
            {this.state.barbers.map((barber) => (
              <ListItem
                key={barber.id}
                onClick={() => {
                  this.handleBarberChange(barber.id);
                }}
                dense
                button
              >
                <ListItemText
                  primary={<strong>{barber.name}</strong>}
                  className={classes.barber}
                />
                <ListItemSecondaryAction>
                  <Radio
                    onClick={() => {
                      this.handleBarberChange(barber.id);
                    }}
                    checked={barberId === barber.id}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <List>
            <ListItem>
              <ListItemText>
                Er werden geen{" "}
                {info && info.profession && info.profession.plural
                  ? info.profession.plural
                  : "barbers"}{" "}
                gevonden
              </ListItemText>
            </ListItem>
          </List>
        )}
      </Dialog>
    );
  }
}

SelectBarber.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  barberId: PropTypes.string
};
//onChange={ setBarberId(barber.id) }

export default withMobileDialog()(withStyles(styles)(SelectBarber));

/*
<IconButton
  aria-label="Sluiten"
  color="secondary"
  onClick={this.handleGoToLanding}
>
  <CloseIcon />
</IconButton>
*/
