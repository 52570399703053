import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navigation from "./components/Navigation";

import Moment from "moment";

import CrewDrawer from "./components/CrewDrawer";

import SelectShopPage from "./pages/SelectShop";
import LandingPage from "./pages/Landing";
import HomePage from "./pages/Home";
import DayPage from "./pages/Day";
import SchedulePage from "./pages/Schedule";
import ShopSchedulePage from "./pages/ShopSchedule";
import WeekSchedulePage from "./pages/WeekSchedule";
import AvailabilityPage from "./pages/Availability";
import ShopPage from "./pages/Shop";
import UserPage from "./pages/User";
import UserSlotsPage from "./pages/UserSlots";
import UserReservationsPage from "./pages/UserReservations";
import ControlRoomPage from "./pages/ControlRoom";
import SignInPage from "./pages/SignIn";
import EarningsPage from "./pages/Earnings";
import ProductsPage from "./pages/Products";
import ServicesPage from "./pages/Services";
import ReservationsPage from "./pages/Reservations";
import CustomersPage from "./pages/Customers";

//import Test from "./Test";

import Conductor from "./modals/Conductor";

import * as routes from "./constants/routes";

import { firebase, store } from "./firebase";

import { isEmpty, jsDateToDateString } from "./utils";

import withAuthentication from "./components/withAuthentication";
import withAnalytics from "./components/withAnalytics";
import withBugsnag from "./components/withBugsnag";

import "./App.css";

console.info("*REACT_APP_NAME*", process.env.REACT_APP_NAME);
console.info("*REACT_APP_VERSION*", process.env.REACT_APP_VERSION);
console.info("*REACT_APP_ENV*", process.env.REACT_APP_ENV);

class App extends Component {
  constructor(props) {
    super(props);

    this.updateShop = (params) => {
      //delete params.date;
      return new Promise((resolve, reject) => {
        this.updateContextParams("shop", params)
          .then(() => {
            // Request availability for new Date or Barber
            if (params.date || params.barberId) {
              console.info(
                "Request availability for new Date or Barber:",
                params
              );
              this.requestAvailability();
            }

            resolve();
          })
          .catch(reject);
      });
    };

    this.resetShop = () => {
      return this.resetContextParams("shop");
    };

    let shop = this.initShop();
    shop.update = this.updateShop;
    shop.reset = this.resetShop;

    this.state = {
      shop,
      modal: {
        open: false
      },
      drawerOpen: false
      //modals: []
    };

    if (localStorage) {
      const shopStorage = localStorage.getItem("shop");
      if (shopStorage) {
        const shop = JSON.parse(shopStorage);
        delete shop.date;
        for (const key in shop) {
          this.state.shop[key] = shop[key];
        }
      }
    }

    if (this.state.shop.id && props.onShopUpdate) {
      props.onShopUpdate(this.state.shop.id);
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);

    this.setShop = this.setShop.bind(this);
    this.requestAvailability = this.requestAvailability.bind(this);
  }

  componentDidMount() {
    const { shop } = this.state;
    if (shop.id) {
      // Fetch shop data
      this.refreshShopData(shop.id);
      // Request initial availability
      this.requestAvailability();
    }

    firebase.auth.onAuthStateChanged((authUser) => {
      if (
        authUser &&
        (authUser.phoneNumber === undefined || authUser.phoneNumber === null)
      ) {
        store
          .getCrew(authUser.uid)
          .then((crewData) => {
            if (crewData && crewData.shops) {
              const shopIds = Object.keys(crewData.shops);
              if (shopIds.length > 0) {
                const shopId = shopIds[0];
                const barberId = crewData.shops[shopId].barberId;

                this.setShop(shopId, barberId, this.state.shop.serviceId);
              }
            }

            const isCrew = crewData !== undefined;
            this.setState({ isCrew }, () => {
              // If crew, also slots will be fetched (force refresh)
              this.requestAvailability(undefined, undefined, true);
            });

            /*if(isCrew) {
              if(window.location && window.location.pathname && window.location.pathname === "/") {
                window.location.replace(routes.CONTROLROOM);
              }
            }*/
          })
          .catch((error) => {
            console.warn(error);
            this.setState({ isCrew: false });
          });
      } else {
        this.setState({ isCrew: false });
      }
    });
  }

  setShop(shopId, barberId, serviceId) {
    const { onShopUpdate } = this.props;
    const { shop } = this.state;

    const newShop = this.initShop(shopId, barberId, serviceId);
    shop.update(newShop).then(
      () => {
        // Force refresh availability
        this.requestAvailability(undefined, undefined, true);
      },
      (error) => {
        console.warn(error);
      }
    );

    onShopUpdate(shopId);

    this.refreshShopData(shopId);
  }

  refreshShopData(shopId) {
    if (this.unsubscribeShopInfo) {
      this.unsubscribeShopInfo();
    }
    if (this.unsubscribeShopBarbers) {
      this.unsubscribeShopBarbers();
    }
    if (this.unsubscribeShopServices) {
      this.unsubscribeShopServices();
    }
    if (this.unsubscribeProducts) {
      this.unsubscribeProducts();
    }

    if (shopId) {
      this.unsubscribeShopInfo = store.onGetShopInfo(shopId, (info) => {
        this.setState({ info });
      });
      this.unsubscribeShopBarbers = store.onGetShopBarbers(
        shopId,
        (barbers) => {
          const { shop } = this.state;

          this.setState({ barbers });

          if (barbers && barbers.length === 1) {
            shop.update({ barberId: barbers[0].id });
          }
        }
      );
      this.unsubscribeShopServices = store.onGetShopServices(
        shopId,
        (services) => {
          const { shop } = this.state;

          this.setState({ services });

          if (this.state.shop && shop.serviceId) {
            const selectedService = services.filter(
              (service) => service.id === shop.serviceId && !service.isRemoved
            );

            if (selectedService.length === 0) {
              shop.update({ serviceId: null });
            }
          }

          /*if (services && services.length === 1) {
            shop.update({ serviceId: services[0].id });
          }*/
        }
      );
      this.unsubscribeProducts = store.onGetShopProducts(shopId, (products) => {
        this.setState({ products });
      });
    }
  }

  updateContextParams(context, params) {
    return new Promise((resolve, reject) => {
      let toChange = {};
      toChange[context] = { ...this.state[context] };
      for (var key in params) {
        let val = params[key];

        if (typeof val !== "function") {
          toChange[context][key] = val;
        }
      }

      this.setState(
        (state) => toChange,
        () => {
          if (localStorage) {
            let storage = localStorage.getItem(context);

            try {
              if (storage) {
                storage = JSON.parse(storage);
              } else {
                storage = {};
              }

              for (var key in params) {
                let val = params[key];

                if (typeof val !== "function") {
                  storage[key] = val;
                }
              }

              localStorage.setItem(context, JSON.stringify(storage));

              resolve();
            } catch (error) {
              reject(error);
            }
          }
        }
      );
    });
  }

  initShop(shopId, barberId, serviceId) {
    return {
      id: shopId ? shopId : "",
      barberId: barberId ? barberId : "",
      serviceId: serviceId ? serviceId : "",
      date: new Date(),
      settings: {
        spacingInMinutes: 10,
        showAvailableSlots: true
      }
    };
  }

  requestAvailability(beginDate, endDate, forceRefresh) {
    const { shop, isCrew } = this.state;

    if (beginDate === undefined) {
      beginDate = shop.date;
    }
    if (endDate === undefined) {
      endDate = beginDate;
    }

    if (shop === undefined || isEmpty(shop.barberId)) {
      return;
    }

    const dayCount = Moment(endDate).diff(beginDate, "days");

    // Minimum period = 1 month
    let beginDateAsString, endDateAsString;
    if (dayCount > 31) {
      beginDateAsString = jsDateToDateString(
        Moment(beginDate)
          .add(2, "hours") // Compensate for GMT+2 timeshift
          .toDate()
      );
      endDateAsString = jsDateToDateString(
        Moment(endDate)
          .add(2, "hours") // Compensate for GMT+2 timeshift
          .toDate()
      );
    } else {
      beginDateAsString = jsDateToDateString(
        Moment(beginDate)
          .startOf("month")
          .startOf("isoweek")
          .add(-3, "days")
          .add(2, "hours") // Compensate for GMT+2 timeshift
          .toDate()
      );
      endDateAsString = jsDateToDateString(
        Moment(endDate)
          .endOf("month")
          .endOf("isoweek")
          .add(3, "days")
          .add(2, "hours") // Compensate for GMT+2 timeshift
          .toDate()
      );
    }

    if (
      forceRefresh ||
      this.availabilityBeginDate === undefined ||
      this.availabilityEndDate === undefined ||
      this.availabilityBarberId === undefined ||
      (beginDateAsString < this.availabilityBeginDate ||
        endDateAsString > this.availabilityEndDate ||
        shop.barberId !== this.availabilityBarberId)
    ) {
      this.setState(
        {
          availability: null,
          slots: null
        },
        () => {
          const newBeginDate = beginDateAsString;
          const newEndDate = endDateAsString;
          /*const newBeginDate =
            this.availabilityBeginDate !== undefined &&
            this.availabilityBeginDate < beginDateAsString
              ? this.availabilityBeginDate
              : beginDateAsString;
          const newEndDate =
            this.availabilityEndDate !== undefined &&
            this.availabilityEndDate > endDateAsString
              ? this.availabilityEndDate
              : endDateAsString;*/

          this.availabilityBeginDate = newBeginDate;
          this.availabilityEndDate = newEndDate;
          this.availabilityBarberId = shop.barberId;

          console.info(
            this.availabilityBarberId,
            ":",
            this.availabilityBeginDate,
            "->",
            this.availabilityEndDate
          );

          if (this.unsubscribeShopAvailability) {
            this.unsubscribeShopAvailability();
          }

          this.unsubscribeShopAvailability = store.onGetShopAvailabilityForBarber(
            shop.id,
            shop.barberId,
            newBeginDate,
            newEndDate,
            (availability) => {
              //console.info(availability);
              this.setState({
                availability
              });
            }
          );

          if (isCrew) {
            if (this.unsubscribeShopSlots) {
              this.unsubscribeShopSlots();
            }
            this.unsubscribeShopSlots = store.onGetShopSlotsForBarber(
              shop.id,
              shop.barberId,
              newBeginDate,
              newEndDate,
              (slots) => {
                //console.info(slots);
                this.setState({ slots });
              }
            );
          }
        }
      );
    }
  }

  resetContextParams(context) {
    console.info(this.state);
  }

  openModal(name, props) {
    if (this.state.modal.open && name === this.state.modal.name) {
      return;
    }

    this.setState({
      modal: {
        open: true,
        name: name,
        props: props
      }
    });

    /*let modals = this.state.modals;

    modals.push({
      open: true,
      name: name,
      props: props
    });

    this.setState({ modals: modals});*/
  }

  closeModal() {
    if (this.state.modal.open) {
      let modal = this.state.modal;
      modal.open = false;

      this.setState({ modal });
    }

    /*let modals = this.state.modals;

    if(modals.length > 0) {
      modals[modals.length - 1].open = false;

      this.setState({ modals: modals }, () => {
        modals.pop();

        this.setState({ modals: modals });
      });
    }*/
  }

  handleDrawerOpen() {
    this.setState({ drawerOpen: true });
  }

  handleDrawerClose() {
    this.setState({ drawerOpen: false });
  }

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }

  render() {
    const { bugsnagClient } = this.props;
    const {
      shop,
      info,
      barbers,
      services,
      products,
      availability,
      slots,
      modal,
      isCrew,
      drawerOpen
    } = this.state; //withAnalytics(Routes);
    /*const Routes = () => (
      
    );

    const RoutesWithAnalytics = Routes;*/ return (
      <div>
        <ToastContainer />

        <div className="root">
          <Navigation />
          {isCrew && (
            <CrewDrawer
              open={drawerOpen}
              barbers={barbers}
              onOpen={this.handleDrawerOpen}
              onClose={this.handleDrawerClose}
            />
          )}
          <div className="background" />
          <div className="content">
            <Switch>
              <Route
                exact
                path={routes.TO_SHOP}
                render={(params) => (
                  <Redirect
                    to={
                      params &&
                      params.match &&
                      params.match.params &&
                      params.match.params.locator
                        ? routes.SELECT_SHOP.replace(
                            ":locator?",
                            params.match.params.locator
                          )
                        : routes.SELECT_SHOP_EMPTY
                    }
                  />
                )}
              />
              <Route
                exact
                path={routes.SELECT_SHOP}
                render={(params) =>
                  isCrew ? (
                    <Redirect to={routes.CONTROLROOM} />
                  ) : (
                    <SelectShopPage
                      shop={shop}
                      info={info}
                      barbers={barbers}
                      services={services}
                      toggleDrawer={this.toggleDrawer}
                      setShop={this.setShop}
                      {...params}
                    />
                  )
                }
              />
              <Route
                exact
                path={routes.LANDING}
                render={() =>
                  isCrew ? (
                    <Redirect to={routes.CONTROLROOM} />
                  ) : (
                    <LandingPage
                      shop={shop}
                      info={info}
                      barbers={barbers}
                      services={services}
                      toggleDrawer={this.toggleDrawer}
                    />
                  )
                }
              />
              <Route
                exact
                path={routes.HOME}
                render={() => (
                  <HomePage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    openModal={this.openModal}
                    setTitle={this.setTitle}
                    requestAvailability={this.requestAvailability}
                  />
                )}
              />
              <Route
                exact
                path={routes.DAY}
                render={() => (
                  <DayPage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    openModal={this.openModal}
                    setTitle={this.setTitle}
                    requestAvailability={this.requestAvailability}
                  />
                )}
              />
              <Route
                exact
                path={routes.SCHEDULE}
                render={() => (
                  <SchedulePage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    slots={slots}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                    requestAvailability={this.requestAvailability}
                  />
                )}
              />
              <Route
                exact
                path={routes.SHOP_SCHEDULE}
                render={() => (
                  <ShopSchedulePage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    services={services}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.WEEK_SCHEDULE}
                render={() => (
                  <WeekSchedulePage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    slots={slots}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                    requestAvailability={this.requestAvailability}
                  />
                )}
              />
              <Route
                exact
                path={routes.AVAILABILITY}
                render={() => (
                  <AvailabilityPage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.SHOP}
                render={() => (
                  <ShopPage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.USER}
                render={() => (
                  <UserPage
                    shop={shop}
                    info={info}
                    products={products}
                    setShop={this.setShop}
                    bugsnagClient={bugsnagClient}
                  />
                )}
              />
              <Route
                exact
                path={routes.USER_SLOTS}
                render={() => (
                  <UserSlotsPage shop={shop} info={info} products={products} />
                )}
              />
              <Route
                exact
                path={routes.USER_RESERVATIONS}
                render={() => (
                  <UserReservationsPage shop={shop} products={products} />
                )}
              />
              <Route
                exact
                path={routes.CONTROLROOM}
                render={() => (
                  <ControlRoomPage
                    shop={shop}
                    barbers={barbers}
                    services={services}
                    availability={availability}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                    requestAvailability={this.requestAvailability}
                  />
                )}
              />
              <Route
                exact
                path={routes.SIGN_IN}
                render={() => <SignInPage />}
              />
              <Route
                exact
                path={routes.EARNINGS}
                render={() => (
                  <EarningsPage
                    shop={shop}
                    info={info}
                    barbers={barbers}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.PRODUCTS}
                render={() => (
                  <ProductsPage
                    shop={shop}
                    info={info}
                    products={products}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.SERVICES}
                render={() => (
                  <ServicesPage
                    shop={shop}
                    info={info}
                    services={services}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.RESERVATIONS}
                render={() => (
                  <ReservationsPage
                    shop={shop}
                    products={products}
                    openModal={this.openModal}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
              <Route
                exact
                path={routes.CUSTOMERS}
                render={() => (
                  <CustomersPage
                    shop={shop}
                    products={products}
                    toggleDrawer={this.toggleDrawer}
                  />
                )}
              />
            </Switch>
          </div>
        </div>

        <Conductor
          shop={shop}
          info={info}
          barbers={barbers}
          services={services}
          products={products}
          bugsnagClient={bugsnagClient}
          closeModal={this.closeModal}
          open={modal.open}
          current={modal.name}
          {...modal.props}
        />
      </div>
    );
  }
}

const AppWithoutRouter = withRouter(
  withBugsnag(withAnalytics(withAuthentication(App)))
);

const AppWithRouter = () => (
  <Router>
    <AppWithoutRouter />
  </Router>
);

export default AppWithRouter;

//export default withRouter(withAnalytics(withAuthentication(App)));
