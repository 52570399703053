import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./registerServiceWorker";
//import registerServiceWorker from "./registerServiceWorker";

import "moment/locale/nl-be";

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
//registerServiceWorker();

window.obfuscateVal = function(val) {
  return btoa(val);
};

window.revealVal = function(obfuscated) {
  return atob(obfuscated);
};

window.revealEmail = function(obfuscatedEmail) {
  var address = window.revealVal(obfuscatedEmail);
  window.location.href = "mailto:" + address;
};
