import React, { Component } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core/styles";

import { isEmpty, parsePhone, parseEmail } from "../utils";

const styles = theme => ({
  textField: {
    /*left: '50%',
    transform: 'translate(-50%, 0)'*/
    width: "100%;"
  }
});

class AnonymousCustomer extends Component {
  constructor(props) {
    super(props);

    const { customer } = props;
    if (customer) {
      this.state = customer;
    } else {
      this.state = {
        id: null,
        isVerified: false,
        phone: "",
        name: "",
        email: ""
      };
    }

    this.props.onChange(this.state);
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { customer } = this.props;
    const { id } = this.state;

    if (customer && id !== customer.id) {
      this.setState(customer);
    }
  }

  /*onEntering() {
    this.revertChanges();
  }*/

  handleChange = key => event => {
    let val = event.target.value;

    let { phone, name, email, isVerified } = this.state;

    switch (key) {
      case "phone":
        if (!isEmpty(val)) {
          const parsedPhone = parsePhone(val);
          if (parsedPhone) {
            val = parsedPhone;
          }
        }

        phone = val;
        break;
      case "name":
        name = val;
        break;
      case "email":
        if (!isEmpty(val)) {
          const parsedEmail = parseEmail(val);
          if (parsedEmail) {
            val = parsedEmail;
          }
        }

        email = val;
        break;
    }

    // Valid if name is not empty and phone is empty or valid
    isVerified =
      !isEmpty(name) &&
      (isEmpty(phone) || parsePhone(phone) !== undefined) &&
      (isEmpty(email) || parseEmail(email) !== undefined);

    this.setState(
      {
        [key]: val,
        isVerified
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  render() {
    const { classes, disabled } = this.props;
    const { phone, name, email } = this.state;

    return (
      <div className={classes.root}>
        <form ref="form" className={classes.container} autoComplete="off">
          <List>
            <ListItem>
              <TextField
                id="phone"
                label="GSM nummer"
                helperText="Gebruik een Belgisch of Nederlands mobiel nummer"
                className={classes.textField}
                value={phone}
                onChange={this.handleChange("phone")}
                margin="normal"
                disabled={disabled}
              />
            </ListItem>
            <ListItem>
              <TextField
                required
                id="name"
                label="Naam"
                className={classes.textField}
                value={name}
                onChange={this.handleChange("name")}
                margin="normal"
                disabled={disabled}
              />
            </ListItem>
            <ListItem>
              <TextField
                id="email"
                label="E-mailadres"
                helperText="Vul het e-mailadres van de klant in om afspraakherinneringen te versturen"
                className={classes.textField}
                value={email}
                onChange={this.handleChange("email")}
                margin="normal"
              />
            </ListItem>
          </List>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(AnonymousCustomer);
