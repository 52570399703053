import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  ListSubheader,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  Button
} from "@material-ui/core";

import EditDayHours from "./EditDayHours";
import ViewDayHours from "./ViewDayHours";

const styles = theme => ({
  header: {
    zIndex: 101,
    fontWeight: "bold",
    textTransform: "uppercase",
    top: "56px",
    [theme.breakpoints.up("600")]: {
      top: "64px"
    },
    backgroundColor: theme.palette.background.paper
  },
  button: {
    marginRight: 10
  },
  description: {
    minHeight: 70,
    fontStyle: "italic",
    color: "#222"
  }
});

class DayHours extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false
    };
  }

  enableEditing = () => {
    const { dayHours, onStartEditing } = this.props;
    this.setState({ editing: true, editedDayHours: dayHours });
    if (onStartEditing) {
      onStartEditing();
    }
  };

  cancelChanges = () => {
    const { dayHours, onStopEditing } = this.props;
    this.setState({ editing: false, editedDayHours: dayHours });
    if (onStopEditing) {
      onStopEditing(false);
    }
  };

  saveChanges = () => {
    const { saveChanges, onStopEditing } = this.props;

    this.setState({ editing: false });

    if (saveChanges) {
      saveChanges(this.state.editedDayHours);
    }
    if (onStopEditing) {
      onStopEditing(true);
    }
  };

  handleChange = dayHours => {
    this.setState({ editedDayHours: dayHours });
  };

  render() {
    const { title, description, dayHours, canBeEdited, classes } = this.props;
    const { editing } = this.state;

    return (
      <div>
        <ListSubheader className={classes.header}>
          {title}
          <ListItemSecondaryAction>
            {editing ? (
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={this.cancelChanges}
                >
                  Annuleren
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={this.saveChanges}
                >
                  Bewaren
                </Button>
              </div>
            ) : (
              <div>
                {canBeEdited && (
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.button}
                    onClick={this.enableEditing}
                  >
                    Wijzigen
                  </Button>
                )}
              </div>
            )}
          </ListItemSecondaryAction>
        </ListSubheader>
        <ListItem>
          <ListItemText
            primary={description}
            classes={{ primary: classes.description }}
          />
        </ListItem>
        {editing ? (
          <EditDayHours dayHours={dayHours} onChange={this.handleChange} />
        ) : (
          <ViewDayHours dayHours={dayHours} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DayHours);
