import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

import Hour from './Hour';

const styles = theme => ({
  day: {
    width: '100%',
    maxWidth: '600px',
    minHeight: 'calc(100vh - 114px)',
    margin: '0 auto',
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  noSlots: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }
});

class Day extends Component {
  constructor() {
    super();

    this.handleSlotClick = this.handleSlotClick.bind(this);
    this.handleSlotOptionsClick = this.handleSlotOptionsClick.bind(this);
    this.handleBookSlotClick = this.handleBookSlotClick.bind(this);
    this.handleRemoveSlotClick = this.handleRemoveSlotClick.bind(this);
  }

  handleSlotClick(slot) {
    const { date, barberId, handleSlotClick } = this.props;

    if(handleSlotClick) {
      slot.date = date;
      if(barberId) {
        slot.barberId = barberId;
      }
      handleSlotClick(slot);
    }
  }

  handleSlotOptionsClick(slot) {
    const { date, barberId, handleSlotOptionsClick } = this.props;

    if(handleSlotOptionsClick) {
      slot.date = date;
      if(barberId) {
        slot.barberId = barberId;
      }
      handleSlotOptionsClick(slot);
    }
  }

  handleBookSlotClick(slot) {
    const { date, barberId, handleBookSlotClick } = this.props;

    if(handleBookSlotClick) {
      slot.date = date;
      if(barberId) {
        slot.barberId = barberId;
      }
      handleBookSlotClick(slot);
    }
  }

  handleRemoveSlotClick(slot) {
    const { date, barberId, handleRemoveSlotClick } = this.props;

    if(handleRemoveSlotClick) {
      slot.date = date;
      if(barberId) {
        slot.barberId = barberId;
      }
      handleRemoveSlotClick(slot);
    }
  }

  render() {
    const { 
      classes, 
      beginHour, 
      endHour, 
      slots, 
      services,
      handleSlotClick, 
      handleSlotOptionsClick, 
      handleBookSlotClick, 
      handleRemoveSlotClick, 
      renderEmpty,
      stickyTop,
      ...other 
    } = this.props;

    //const { beginHour, endHour } = this.props;
    const parsedBeginHour  = parseInt(beginHour);
    const parsedEndHour  = parseInt(endHour);

    let slotCount = 0;
    let hours = [];

    if(slots) {
      slotCount = Object.keys(slots).length;

      //if(slotCount > 0) {
        for (let hour = parsedBeginHour; hour <= parsedEndHour; hour++) {
          hours.push(
            <Hour 
              key={ hour } 
              hour={ hour } 
              slots={ slots && slots[hour] } 
              services={ services }
              handleSlotClick={ this.handleSlotClick } 
              handleSlotOptionsClick={ this.handleSlotOptionsClick } 
              handleBookSlotClick={ this.handleBookSlotClick } 
              handleRemoveSlotClick={ this.handleRemoveSlotClick }
              stickyTop={ stickyTop }
              { ...other }
            />);
        }
      //}
    }
    
    return (
      <List className={ classes.day }>
        { slots === undefined ?
            <CircularProgress className={ classes.progress } /> :
            slotCount === 0 && !renderEmpty ?
              <p className={ classes.noSlots }>Geen afspraken beschikbaar</p> :
              hours 
        }
      </List>
    );
  }
}

export default withStyles(styles)(Day);
