import React from "react";

import AuthContext from "../context/auth";

import { firebase, store } from "../firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ready: false,
        shopId: null,
        user: null,
        isCrew: false,
        authUser: null
      };

      this.onShopUpdate = this.onShopUpdate.bind(this);
      this.updateName = this.updateName.bind(this);
      this.updateEmail = this.updateEmail.bind(this);
    }

    componentDidMount() {
      this.refreshOnAuthState();
    }

    componentWillUnmount() {}

    onShopUpdate(shopId) {
      this.setState({ shopId }, this.refreshOnAuthState);
    }

    refreshOnAuthState() {
      if (this.unsubscribeOnAuthStateChanged) {
        this.unsubscribeOnAuthStateChanged();
      }

      this.unsubscribeOnAuthStateChanged = firebase.auth.onAuthStateChanged(
        authUser => {
          this.setState({ authUser }, this.refreshUser);
        }
      );
    }

    refreshUser() {
      const { authUser, shopId } = this.state;

      const session = `session_${new Date().getTime()}`;
      const newState = {
        ready: true,
        isCrew: false,
        session,
        user: null,
        customer: null
      };

      if (authUser) {
        newState.user = authUser;

        store
          .getCrew(authUser.uid)
          .then(crewData => {
            if (crewData) {
              newState.isCrew = true;
              this.setState(newState);
            } else {
              if (shopId) {
                store
                  .getCustomerByUserId(shopId, authUser.uid)
                  .then(data => {
                    const customer = {
                      userId: authUser.uid
                    };

                    if (authUser.phoneNumber) {
                      customer.phone = authUser.phoneNumber;
                    }
                    if (authUser.displayName) {
                      customer.name = authUser.displayName;
                    }

                    if (data) {
                      if (data.id) {
                        customer.customerId = data.id;
                      }
                      if (data.email) {
                        customer.email = data.email;
                      }
                    }

                    customer.isVerified =
                      customer.phone && customer.name && customer.email
                        ? true
                        : false;

                    newState.customer = customer;
                    this.setState(newState);
                  })
                  .catch(error => {
                    if (error === "no customer found") {
                      this.setState({ ...newState });

                      // A user is signed in, but unknown to this shop
                      store
                        .createUser(shopId, authUser.uid, false)
                        .then(data => {
                          const newSession = `session_${new Date().getTime()}`;
                          newState.session = newSession;

                          if (data && data.user) {
                            const customer = {
                              ...data.user
                            };

                            customer.isVerified =
                              customer.phone && customer.name && customer.email
                                ? true
                                : false;

                            newState.customer = customer;
                          }
                          
                          this.setState({ ...newState });
                        })
                        .catch(error => {
                          console.warn(error);
                          this.setState(newState);
                        });
                    } else {
                      console.warn(error);
                      this.setState(newState);
                    }
                  });
              } else {
                this.setState(newState);
              }
            }
          })
          .catch(error => {
            console.warn(error);
            this.setState(newState);
          });
      } else {
        this.setState(newState);
      }
    }

    updateName(name) {
      const { user } = this.state;

      return new Promise((resolve, reject) => {
        if (user) {
          return user
            .updateProfile({
              displayName: name
            })
            .then(data => {
              this.refreshUser();
              resolve(data);
            })
            .catch(reject);
        } else {
          return reject("no user found");
        }
      });
    }

    updateEmail(shopId, email) {
      const { user, isCrew } = this.state;

      return new Promise((resolve, reject) => {
        if (user && user.uid) {
          store
            .updateUser(shopId, user.uid, isCrew === true, {
              email
            })
            .then(data => {
              this.refreshUser();
              resolve(data);
            })
            .catch(reject);
        } else {
          return reject("no user or user.uid found");
        }
      });
    }

    render() {
      const { ready, session, user, isCrew, shopId, customer } = this.state;

      const auth = {
        ready,
        session,
        user,
        isCrew,
        updateName: this.updateName,
        updateEmail: this.updateEmail
      };
      if (shopId) {
        auth.shopId = shopId;
      }
      if (customer) {
        auth.customer = customer;
      }

      return (
        <AuthContext.Provider value={auth}>
          <Component {...this.props} onShopUpdate={this.onShopUpdate} />
        </AuthContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
