import React, { Component } from "react";

import { Link } from "react-router-dom";

import AuthContext from "../context/auth";

import * as routes from "../constants/routes";

import VerifiedCustomer from "../components/VerifiedCustomer";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import { jsDateToDateString } from "../utils";

import { withStyles } from "@material-ui/core/styles";

import withShop from "../components/withShop";

import UserFooter from "../components/UserFooter";
import UserHeader from "../components/UserHeader";
import Slots from "../components/Slots";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  sectionHeader: {
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  list: {
    minHeight: "calc(100vh - 122px)",
    backgroundColor: theme.palette.background.paper
  },
  link: {
    textDecoration: "none",
    color: "#777"
  },
  button: {
    fontFamily: "Lumberjack Inline Rough",
    textTransform: "uppercase",
    textAlign: "center"
  },
  slot: {},
  slotDate: {
    fontSize: 13,
    textTransform: "lowercase"
  },
  productsTitle: {
    display: "inline-block",
    marginTop: 1,
    marginRight: 5,
    verticalAlign: "middle",
    fontSize: 13,
    textTransform: "uppercase"
  },
  product: {
    display: "inline-block",
    marginRight: 5
  },
  version: {
    textAlign: "center"
  }
});

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleSelectShop = () => {
    const { setShop } = this.props;

    setShop();
  };

  handleCustomerChange = customer => {
    this.setState({ customer });
  };

  render() {
    const { classes, shop, info, products, auth, bugsnagClient } = this.props;

    const fromDate = jsDateToDateString(new Date());

    return (
      <div className={classes.root}>
        <UserHeader title="Mijn gegevens" />
        <List className={classes.list}>
          {info && (
            <div>
              <ListItem>
                <ListItemText
                  primary="Huidige Kapperszaak"
                  secondary={
                    <Link to={routes.LANDING} className={classes.link}>
                      {info.name}
                    </Link>
                  }
                />
                <Button onClick={this.handleSelectShop}>Aanpassen</Button>
              </ListItem>
              <Divider />
            </div>
          )}
          <VerifiedCustomer
            shop={shop}
            onChange={this.handleCustomerChange}
            showUpdateButtons={true}
            bugsnagClient={bugsnagClient}
          />
          <Divider />
          <div>
            {!auth ||
              (!auth.isCrew && (
                <div>
                  <Link to={routes.HOME} className={classes.link}>
                    <ListItem button className={classes.button}>
                      <ListItemText primary="Maak een afspraak" />
                    </ListItem>
                  </Link>
                  <Divider />
                </div>
              ))}
            {auth && auth.customer && (
              <div>
                <ListSubheader className={classes.sectionHeader}>
                  Volgende afspraken
                </ListSubheader>
                <Slots
                  shop={shop}
                  info={info}
                  products={products}
                  customerId={auth.customer.customerId}
                  userId={auth.customer.userId}
                  fromDate={fromDate}
                  showAddToCalendar={true}
                />
              </div>
            )}
          </div>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  variant="overline"
                  className={classes.version}
                >{`BarberIO v${process.env.REACT_APP_VERSION}`}</Typography>
              }
            />
          </ListItem>
        </List>
        {auth && auth.customer && <UserFooter />}
      </div>
    );
  }
}

export default withShop()(
  withStyles(styles)(props => (
    <AuthContext.Consumer>
      {auth => <UserPage {...props} auth={auth} />}
    </AuthContext.Consumer>
  ))
);
