import React, { PureComponent } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";

const ITEM_HEIGHT = 48;

const styles = theme => ({
  selected: {
    fontWeight: "bold"
    /*backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white*/
  }
});

class ListPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleItemClick(itemId) {
    this.props.onClick(itemId);

    //this.handleClose();
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { anchor, items, selectedItemId, classes } = this.props;

    const open = Boolean(anchor);

    return (
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={this.handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {items &&
          items.map(item => (
            <MenuItem
              key={item.id}
              onClick={() => {
                this.handleItemClick(item.id);
              }}
              dense
              button
              className={
                selectedItemId && selectedItemId === item.id
                  ? classes.selected
                  : ""
              }
            >
              {item.component}
            </MenuItem>
          ))}
      </Menu>
    );
  }
}

export default withStyles(styles)(ListPopup);
