import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Zoom from '@material-ui/core/Zoom';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

function Transition(props) {
  return <Zoom {...props} />;
}

const styles = theme => ({
  title: {
    fontFamily: 'Lumberjack Inline Rough',
    fontSize: 20
  },
  text: {

  },
  progress: {
      display: 'block',
      textAlign: 'center'
  }
});

class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    /*if(this.props.handleCancel) {
        this.props.handleCancel();
    }*/
  };

  handleConfirm = () => {
    if(this.props.handleConfirm) {
        this.props.handleConfirm();
    }
  };

  handleCancel = () => {
    if(this.props.handleCancel) {
        this.props.handleCancel();
    }
  };

  render() {
    const { classes, open, onClose, description, handleConfirm, handleCancel, ...other } = this.props;

    return (
      <Dialog TransitionComponent={ Transition } open={ open } onClose={ this.handleClose } disableBackdropClick={ true } aria-labelledby="Laden..." {...other}>
        <DialogTitle>
          <Typography className={ classes.title }>
            Ben je zeker?
          </Typography>
        </DialogTitle>
        <List>
            <ListItem>
                <ListItemText>
                    <Typography className={ classes.text } variant="subheading" id="simple-modal-description">
                        { description }
                    </Typography>
                </ListItemText>
            </ListItem>
            <ListItem button className={ classes.button } onClick={ this.handleConfirm }>
                <CheckIcon color="primary" className={ classes.leftIcon } />
                <ListItemText primary="Bevestigen" />
            </ListItem>
            <ListItem button className={ classes.button } onClick={ this.handleCancel }>
                <CloseIcon color="secondary" className={ classes.leftIcon } />
                <ListItemText primary="Annuleren" />
            </ListItem>
        </List>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Confirm);