import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as routes from "../constants/routes";
import * as modals from "../constants/modals";
import * as urls from "../constants/urls";

import AuthContext from "../context/auth";

import { withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import FaceIcon from "@material-ui/icons/Face";
import ToysIcon from "@material-ui/icons/Toys";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import FeedbackIcon from "@material-ui/icons/Feedback";

import ListPopup from "./ListPopup";

import { findInArrayByPropVal, filterNotRemoved, orderArray } from "../utils";

const styles = (theme) => ({
  footer: {
    zIndex: 100,
    top: "auto",
    bottom: 0,
    textAlign: "center",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  link: {
    textDecoration: "none"
  },
  button: {
    //maxWidth: '33%'
  },
  capitalize: {
    textTransform: "capitalize"
  },
  buttonTextOn500: {
    display: "none",
    [theme.breakpoints.up("500")]: {
      display: "block"
    }
  },
  buttonTextOn600: {
    display: "none",
    [theme.breakpoints.up("600")]: {
      display: "block"
    }
  },
  buttonTextOn800: {
    display: "none",
    [theme.breakpoints.up("800")]: {
      display: "block"
    }
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  leftItems: {
    position: "absolute",
    top: 0,
    left: 0
  },
  rightItems: {
    position: "absolute",
    top: 0,
    right: 0
  },
  leftOrRightButton: {
    display: "inline-block"
  },
  grey: {
    color: "grey"
  }
});

class DayFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { shop, openModal } = this.props;

    if (openModal) {
      if (!shop.barberId) {
        openModal(modals.SELECT_BARBER);
      } else if (!shop.serviceId) {
        openModal(modals.SELECT_SERVICE);
      }
    }
  }

  componentWillUnmount() {}

  handleSelectBarberOpen = (event) => {
    this.setState({ barbersAnchor: event.currentTarget });
  };

  handleSelectServiceOpen = (event) => {
    this.setState({ servicesAnchor: event.currentTarget });
  };

  handleBarbersClick = (barberId) => {
    const { shop } = this.props;
    shop.update({ barberId });

    this.handleBarbersClose();
  };

  handleBarbersClose = () => {
    this.setState({ barbersAnchor: null });
  };

  handleServicesClick = (serviceId) => {
    const { shop } = this.props;
    shop.update({ serviceId });

    this.handleServicesClose();
  };

  handleServicesClose = () => {
    this.setState({ servicesAnchor: null });
  };

  render() {
    const {
      classes,
      shop,
      auth,
      info,
      barbers,
      services,
      hideService,
      hideBarber,
      hideCustomer,
      hideCrew,
      leftButtons,
      rightButtons,
      toggleDrawer
    } = this.props;
    const { barbersAnchor, servicesAnchor } = this.state;

    const barber = findInArrayByPropVal(barbers, "id", shop.barberId);
    const service = findInArrayByPropVal(services, "id", shop.serviceId);

    let barberItems;
    if (barbers) {
      barberItems = orderArray(barbers, false, "order").map(
        (barber, index) => ({
          id: barber.id,
          component: barber.name
        })
      );
    }

    let serviceItems;
    if (services) {
      if (auth.isCrew) {
        serviceItems = filterNotRemoved(services).map((service, index) => ({
          id: service.id,
          component: (
            <span>
              <span
                style={{
                  display: "inline-block",
                  width: 10,
                  height: 10,
                  borderRadius: 5,
                  marginRight: 10,
                  background: service.color ? service.color : "black"
                }}
              />
              {service.name}
            </span>
          )
        }));
      } else {
        serviceItems = filterNotRemoved(services).map((service, index) => ({
          id: service.id,
          component: service.name
        }));
      }
    }

    return (
      <ListSubheader className={classes.footer}>
        <ListPopup
          anchor={barbersAnchor}
          items={barberItems}
          selectedItemId={shop.barberId}
          onClick={this.handleBarbersClick}
          onClose={this.handleBarbersClose}
        />
        <ListPopup
          anchor={servicesAnchor}
          items={serviceItems}
          selectedItemId={shop.serviceId}
          onClick={this.handleServicesClick}
          onClose={this.handleServicesClose}
        />
        <div className={classes.leftItems}>
          {leftButtons &&
            leftButtons.map((LeftButton, index) => (
              <div key={index} className={classes.leftOrRightButton}>
                {LeftButton}
              </div>
            ))}
        </div>
        {!hideService && serviceItems && serviceItems.length > 1 && (
          <Button
            color={service ? "primary" : "secondary"}
            aria-label="service"
            onClick={this.handleSelectServiceOpen}
            className={service ? classes.button : `${classes.button} fadeInOut`}
          >
            <ToysIcon className={classes.extendedIcon} />
            <span className={classes.buttonTextOn500}>
              {service ? service.name : "Type kiezen"}
            </span>
          </Button>
        )}
        {!hideBarber && barberItems && barberItems.length > 1 && (
          <Button
            color={barber ? "primary" : "secondary"}
            aria-label="barber"
            onClick={this.handleSelectBarberOpen}
            className={barber ? classes.button : `${classes.button} fadeInOut`}
          >
            <FaceIcon className={classes.extendedIcon} />
            <span className={classes.buttonTextOn500}>
              {barber ? (
                barber.name
              ) : (
                <span>
                  <span className={classes.capitalize}>
                    {info && info.profession && info.profession.singular
                      ? info.profession.singular
                      : "Barber"}
                  </span>{" "}
                  kiezen
                </span>
              )}
            </span>
          </Button>
        )}
        {auth && auth.isCrew && !hideCrew ? (
          <IconButton
            className={`AppDrawerToggle ${classes.menuButton}`}
            color="secondary"
            aria-label="Menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          !hideCustomer &&
          auth &&
          auth.customer && (
            <Link to={routes.USER} className={classes.link}>
              <Button
                color="secondary"
                aria-label="user"
                className={classes.button}
              >
                <PermContactCalendarIcon className={classes.extendedIcon} />
                <span className={classes.buttonTextOn600}>
                  {auth.customer.name}
                </span>
              </Button>
            </Link>
          )
        )}
        <div className={classes.rightItems}>
          {!auth ||
            (!auth.isCrew && (
              <a href={urls.FEEDBACK} target="_blank" className={classes.link}>
                <Button
                  color="primary"
                  aria-label="feedback"
                  className={classes.button}
                >
                  <FeedbackIcon
                    className={`${classes.grey} ${classes.extendedIcon}`}
                  />
                  <span
                    className={`${classes.grey} ${classes.buttonTextOn800}`}
                  >
                    Feedback
                  </span>
                </Button>
              </a>
            ))}
          {rightButtons &&
            rightButtons.map((RightButton, index) => (
              <div key={index} className={classes.leftOrRightButton}>
                {RightButton}
              </div>
            ))}
        </div>
      </ListSubheader>
    );
  }
}

export default withStyles(styles)((props) => (
  <AuthContext.Consumer>
    {(auth) => <DayFooter {...props} auth={auth} />}
  </AuthContext.Consumer>
));
