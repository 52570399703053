import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as routes from '../constants/routes';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const styles = theme => ({
  footer: {
    top: 'auto',
    bottom: 0,
    textAlign: 'center',
    background: 'rgba(250,250,250,.85)',
    webkitBackground: 'rgba(250,250,250,.75)',
    webkitBackdropFilter: 'saturate(180%) blur(20px)',
    backdropFilter: 'saturate(180%) blur(20px)'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    //maxWidth: '33%'
  },
  buttonTextOn500: {
    display: 'none',
    [theme.breakpoints.up('500')]: {
      display: 'block'
    }
  },
  buttonTextOn600: {
    display: 'none',
    [theme.breakpoints.up('600')]: {
      display: 'block'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  }
});

class UserFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { classes, location } = this.props;

    const route = (location ? location.pathname : undefined);

    return (
      <ListSubheader className={ classes.footer }>
        <Link to={ routes.USER } className={ classes.link }>
            <Button color={ route === routes.USER ? 'secondary' : 'primary' } aria-label="service" className={ classes.button }>
                <PermContactCalendarIcon className={ classes.extendedIcon } />
                <span className={ classes.buttonTextOn500 }>Profiel</span>
            </Button>
        </Link>
        <Link to={ routes.USER_SLOTS } className={ classes.link }>
            <Button color={ route === routes.USER_SLOTS ? 'secondary' : 'primary' } aria-label="slots" className={ classes.button }>
                <DateRangeIcon className={ classes.extendedIcon } />
                <span className={ classes.buttonTextOn500 }>Afspraken</span>
            </Button>
        </Link>
      </ListSubheader>
    );
  }
}

export default withStyles(styles)(withRouter(UserFooter));

/*
  <Link to={ routes.USER_RESERVATIONS } className={ classes.link }>
      <Button color={ route === routes.USER_RESERVATIONS ? 'secondary' : 'primary' } aria-label="reservations" className={ classes.button }>
          <FormatListBulletedIcon className={ classes.extendedIcon } />
          <span className={ classes.buttonTextOn500 }>Reservaties</span>
      </Button>
  </Link>
*/