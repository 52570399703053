export const MONDAY = {
  name: "mon",
  label: "maandag",
  index: 1
};

export const TUESDAY = {
  name: "tue",
  label: "dinsdag",
  index: 2
};

export const WEDNESDAY = {
  name: "wed",
  label: "woensdag",
  index: 3
};

export const THURSDAY = {
  name: "thu",
  label: "donderdag",
  index: 4
};

export const FRIDAY = {
  name: "fri",
  label: "vrijdag",
  index: 5
};

export const SATURDAY = {
  name: "sat",
  label: "zaterdag",
  index: 6
};

export const SUNDAY = {
  name: "sun",
  label: "zondag",
  index: 0
};

export const ALL = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY
];
