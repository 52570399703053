import React, { Component } from 'react';


import DayHeader from '../components/DayHeader';
import Day from '../components/Day';
import DayFooter from '../components/DayFooter';

import * as modals from '../constants/modals';

import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import Loading from '../modals/Loading';
import Confirm from '../modals/Confirm';

import { store } from '../firebase';
import { getAvailableSlots, mergeSlots, getBeginAndEndHourFromDayHours, jsDateToDateString, findInArrayByPropVal } from '../utils';

import withCrewAuthorization from '../components/withCrewAuthorization';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    zIndex: 100,
    position: 'fixed',
    bottom: 20,
    left: '50%',
    transform: 'translate(-50%, 0)',
    margin: theme.spacing.unit,
  },
  days: {
    display: 'flex',
    minHeight: 'calc(100vh - 114px)',
    paddingBottom: 80
  },
  day: {
    width: '100%'
  },
  barber: {
    zIndex: 101,
    paddingTop: '20px',
    paddingBottom: '10px',
    fontFamily: 'Lumberjack Inline Rough',
    lineHeight: '26px',
    textAlign: 'center',
    top: "56px",
    [theme.breakpoints.up("600")]: {
      top: "64px",
    },
    backgroundColor: 'white',
    color: '#666'
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  add: {
    position: 'fixed',
    zIndex: 100,
    left: '50%',
    bottom: 68,
    transform: 'translate(-50%, 0)'
  }
});

class ShopSchedulePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      confirm: {
        open: false
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.refreshAvailability();
  }

  componentWillUnmount() {
    if(this.unsubscribeShopAvailability) {
      this.unsubscribeShopAvailability();
      this.unsubscribeShopAvailability = undefined;
    }
    if(this.unsubscribeShopSlots) {
      this.unsubscribeShopSlots();
      this.unsubscribeShopSlots = undefined;
    }
  }

  refreshAvailability() {
    const { shop } = this.props;
    const dateAsString = jsDateToDateString(shop.date);

    // Availability
    if(this.unsubscribeShopAvailability) {
      this.unsubscribeShopAvailability();
    }
    this.unsubscribeShopAvailability = store.onGetShopAvailability(shop.id, dateAsString, dateAsString, (availabilityForDate) => {
      const availability = (availabilityForDate[dateAsString] ? availabilityForDate[dateAsString] : {});
      this.setState({ availability : availability });
    });

    // Slots
    if(this.unsubscribeShopSlots) {
      this.unsubscribeShopSlots();
    }
    this.unsubscribeShopSlots = store.onGetShopSlots(shop.id, dateAsString, dateAsString, (slotsForDate) => {
      const slots = (slotsForDate[dateAsString] ? slotsForDate[dateAsString] : {});
      this.setState({ slots : slots });
    });
  }

  handleDateChange(newDate) {
    const { shop } = this.props;

    shop
      .update({ date: newDate })
      .then(() => {
        this.refreshAvailability();
      })
      .catch(error => {
        console.warn(error);
      });
  };

  handleConfirmClick = () => {
    const slot = this.state.slotToRemove;

    this.setState({
      confirm: {
        open: false
      },
      slotToRemove: undefined
    }, () => {
      this.handleSlotRemove(slot);
    });
  };

  handleCancelClick = () => {
    this.setState({
      confirm: {
        open: false
      },
      slotToRemove: undefined
    }, () => {});
  };

  handleSlotClick = (slot) => {
    switch(slot.type) {
      case 'available':
        this.handleBookSlotOpen(slot);
        break;
      case 'appointment':
        this.handleSlotOpen(slot);
        break;
    }
  };

  handleBookSlotOpen = (slot) => {
    console.info('handleBookSlotOpen:', slot);

    const { shop, services, openModal } = this.props;
    const { barberId, date, begin } = slot;
    let { duration } = slot;

    if(duration === undefined && shop) {
      const service = findInArrayByPropVal(services, 'id', shop.serviceId);
      duration = service.duration;
    }

    openModal(modals.BOOK_SLOT, {
      barberId,
      date,
      begin,
      duration
    });
  };

  handleSlotOpen = (slot) => {
    const { openModal } = this.props;

    openModal(modals.EDIT_SLOT, { 
      id: slot.id
    });
  };

  handleConfirmSlotRemove = (slot) => {
    this.setState({
      slotToRemove: slot,
      confirm: {
        open: true,
        description: 'Ben je zeker dat je deze afspraak wil verwijderen?'
      }
    });
  };

  handleSlotRemove = (slot) => {
    this.setState({
      loading: {
        open: true,
        description: 'Versturen van je aanvraag...'
      }
    });

    store.removeBooking(this.props.shop.id, this.props.auth.user.uid, this.props.auth.isCrew, slot.id, 
      (taskId) => {
        console.log(taskId);
        let loading = this.state.loading;
        loading.description = 'Verifiëren en verwerken van je aanvraag...'
        this.setState({
          loading: loading
        });
      })
      .then(() => {
        console.log("Document successfully updated!");
        this.setState({ loading: { open: false } });
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        this.setState({ loading: { open: false } });
      });
  };

  handleSelectSlotOpen = () => {
    this.props.openModal(modals.SELECT_SLOT, {
      onConfirm: this.handleBookSlotOpen
    });
  };

  render() {
    const { classes, shop, info, barbers, services, openModal, toggleDrawer } = this.props;
    const { availability } = this.state;
    let { slots } = this.state;
    
    const service = findInArrayByPropVal(services, 'id', shop.serviceId);

    let days = [];

    let beginHour = -1, endHour = -2;

    if(barbers && availability && slots) {
      const dayOfWeek = shop.date.getDay();

      for(const index in barbers) {
        const barber = barbers[index];
        let day = {
          barberId: barber.id,
          barberName: barber.name
        };

        if(barber.bookingHours && barber.bookingHours[dayOfWeek]) {
          const bookingHours = barber.bookingHours[dayOfWeek];
          const barberAvailability = availability[barber.id];

          day.bookingHours = bookingHours;
          //day.availability = barberAvailability;

          const availableSlots = getAvailableSlots(barberAvailability, bookingHours, service ? service.duration : 20);

          let barberSlots = slots[barber.id];
          console.info(barberSlots);

          barberSlots = mergeSlots(barberSlots, availableSlots);
          console.info(barberSlots);
          day.slots = barberSlots;

          //const hours = getBeginAndEndHourFromDayHours(bookingHours);
          //day.beginHour = hours.beginHour;
          //day.endHour = hours.endHour;
          const hours = Object.keys(barberSlots);
          if(hours.length > 0) {
            const barberBeginHour = Math.min(...hours);
            const barberEndHour = Math.max(...hours);

            if(beginHour < 0 || barberBeginHour < beginHour) {
              beginHour = barberBeginHour;
            }
            if(endHour < 0 || barberEndHour > endHour) {
              endHour = barberEndHour;
            }
          }
        } else {
          day.slots = [];
        }

        days.push(day);
      }
    }

    return (
      <div className={ classes.root }>
        <Loading open={ this.state.loading.open } description={ this.state.loading.description } />
        <Confirm open={ this.state.confirm.open } description={ this.state.confirm.description } handleConfirm={ this.handleConfirmClick } handleCancel={ this.handleCancelClick } />
        <DayHeader shop={ shop } onDateChanged={ this.handleDateChange } canGoBack={ true } canGoForward={ true } className="AppBar" />
        <div className={ classes.days }>
        {
          days && days.map((day, index) =>
          <div key={ index } className={ classes.day }>
            <ListSubheader className={ classes.barber }>
              { day.barberName }
            </ListSubheader>
            <Day
              date={ shop.date } 
              beginHour={ beginHour } 
              endHour={ endHour } 
              slots={ day.slots } 
              services={ services }
              handleSlotClick={ this.handleSlotClick } 
              handleBookSlotClick={ this.handleBookSlotOpen } 
              handleRemoveSlotClick={ this.handleConfirmSlotRemove }
              barberId={ day.barberId }
              hideHourTitle="true"
              fixedHourHeight="true"
              addBookingPerHourButton="true"
              size="small"
            />
          </div>)
        }
        </div>
        <DayFooter 
          shop={ shop } 
          info={ info }
          services={ services }
          hideBarber={ true } 
          hideCustomer={ true } 
          openModal={ openModal } 
          toggleDrawer={ toggleDrawer }
        />
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(ShopSchedulePage));
