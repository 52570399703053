import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";

import Moment from "moment";
import ReactMoment from "react-moment";
import "moment-timezone";

import * as routes from "../constants/routes";

import AuthContext from "../context/auth";

import AddSlotToCalendar from "../components/AddSlotToCalendar";
import { addressToString } from "../utils";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  title: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20
  },
  confirmedItem: {
    padding: "3px 16px !important",
    textAlign: "center",
    backgroundColor: "#4caf50"
  },
  confirmedText: {
    color: "rgba(255, 255, 255, 0.9)"
  },
  addToCalendar: {
    backgroundColor: "#eee",
    color: "#222"
  },
  link: {
    textDecoration: "none"
  }
});

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      classes,
      open,
      onClose,
      shop,
      slotId,
      date,
      begin,
      duration,
      phone,
      name,
      auth,
      info,
      ...other
    } = this.props;

    let beginTime, endTime, event;

    if (date !== undefined && begin !== undefined) {
      const formattedDate = Moment(date).format("YYYY-MM-DD");

      let beginHour = Math.floor(begin / 60);
      if (beginHour < 10) {
        beginHour = `0${beginHour}`;
      }
      let beginMinutes = begin % 60;
      if (beginMinutes < 10) {
        beginMinutes = `0${beginMinutes}`;
      }
      beginTime = `${beginHour}:${beginMinutes}`;
      const beginTimeWithLocale = Moment.tz(
        `${formattedDate} ${beginTime}`,
        "YYYY-MM-DD HH:mm",
        "Europe/Brussels"
      );

      if (duration !== undefined) {
        const end = begin + duration;
        let endHour = Math.floor(end / 60);
        if (endHour < 10) {
          endHour = `0${endHour}`;
        }
        let endMinutes = end % 60;
        if (endMinutes < 10) {
          endMinutes = `0${endMinutes}`;
        }
        endTime = `${endHour}:${endMinutes}`;
        const endTimeWithLocale = Moment.tz(
          `${formattedDate} ${endTime}`,
          "YYYY-MM-DD HH:mm",
          "Europe/Brussels"
        );

        if (info && info.name && end !== undefined) {
          let location = "";
          if (info && info.location && info.location.address) {
            location = addressToString(info.location.address);
          }

          event = {
            title: `Afspraak bij ${info.name}`,
            description: `Wijzig je afspraak op ${window.location.origin}/user`,
            location,
            startTime: beginTimeWithLocale.toDate().toISOString(),
            endTime: endTimeWithLocale.toDate().toISOString()
          };
        }
      }

      /*const icon = { 'calendar-plus-o': 'left' };
      const items = [
        { apple: 'Apple Calendar' },
        { google: 'Google' },
        { outlook: 'Outlook' },
        { outlookcom: 'Outlook.com' },
        { yahoo: 'Yahoo' }
      ];*/
    }

    let isCrew = false;
    if (auth) {
      isCrew = auth.isCrew === true;
    }

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="Afspraak bevestigd"
        {...other}
      >
        <DialogTitle>
          <Typography className={classes.title}>Afspraak bevestigd</Typography>
        </DialogTitle>
        <List>
          <ListItem>
            <ListItemText primary="Geen bevestigingsmail ontvangen? Check je ongewenste mail of spambox!" />
          </ListItem>
          <ListItem>
            <ListItemText className={classes.confirmedItem}>
              <Typography
                className={classes.confirmedText}
                variant="subheading"
                id="simple-modal-description"
              >
                <span>Je afspraak op </span>
                <strong>
                  <ReactMoment locale="nl-be" format="dddd D MMMM">
                    {date}
                  </ReactMoment>
                </strong>
                <span> om </span>
                <strong>{beginTime}</strong>
                <span> is bevestigd.</span>
              </Typography>
            </ListItemText>
          </ListItem>
          {!isCrew && (
            <div>
              {event && (
                <ListItem className={classes.addToCalendar}>
                  <AddSlotToCalendar
                    shop={shop}
                    slotId={slotId}
                    caption="Toevoegen aan mijn agenda"
                  />
                </ListItem>
              )}
              <Link
                to={routes.USER}
                className={classes.link}
                onClick={this.handleClose}
              >
                <ListItem button>
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Ga naar mijn afspraken" />
                </ListItem>
              </Link>
            </div>
          )}
          <ListItem
            button
            className={classes.button}
            onClick={this.handleClose}
          >
            <CloseIcon color="secondary" className={classes.leftIcon} />
            <ListItemText primary="Sluiten" />
          </ListItem>
        </List>
      </Dialog>
    );
  }
}

//<AddToCalendar event={ event } buttonLabel="Toevoegen aan mijn agenda" buttonTemplate={ icon } listItems={ items } />

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  phone: PropTypes.string,
  name: PropTypes.string
};

export default withMobileDialog()(
  withStyles(styles)((props) => (
    <AuthContext.Consumer>
      {(auth) => <Confirmation {...props} auth={auth} />}
    </AuthContext.Consumer>
  ))
);
