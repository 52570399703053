import React, { Component } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/Menu";

import { withStyles } from "@material-ui/core/styles";

import { auth } from "../firebase";

//import CrewDrawer from "../components/CrewDrawer";
//import WifiTetheringIcon from "@material-ui/icons/WifiTethering";

const styles = (theme) => ({
  header: {
    boxShadow: "none",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  title: {
    flexGrow: 1,
    fontFamily: "calligraffitiregular", // 'shorelines_script_boldregular' 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "lowercase",
    color: "black"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

class CrewHeader extends Component {
  constructor(props) {
    super(props);

    /*this.state = {
      controlRoomOpen: false
    };

    this.handleControlRoomOpen = this.handleControlRoomOpen.bind(this);
    this.handleControlRoomClose = this.handleControlRoomClose.bind(this);*/
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*handleControlRoomOpen() {
    this.setState({ controlRoomOpen: true });
  }

  handleControlRoomClose() {
    this.setState({ controlRoomOpen: false });
  }*/

  render() {
    const { title, classes, toggleDrawer, buttons } = this.props;

    return (
      <div>
        <AppBar
          position="fixed"
          color="default"
          className={`AppBar ${classes.header}`}
        >
          <Toolbar>
            <IconButton
              className={`AppDrawerToggle ${classes.menuButton}`}
              color="inherit"
              aria-label="Menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
            >
              {title}
            </Typography>
            <div>
              {buttons &&
                buttons.map((button, index) => <div key={index}>{button}</div>)}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
/*
<CrewDrawer
          open={this.state.controlRoomOpen}
          onOpen={this.handleControlRoomOpen}
          onClose={this.handleControlRoomClose}
        />
        <Button
              color="secondary"
              aria-label="controlroom"
              onClick={this.handleControlRoomOpen}
            >
              <WifiTetheringIcon />
            </Button>*/
export default withStyles(styles)(CrewHeader);
