import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Slide from "@material-ui/core/Slide";

import { store, storage } from "../firebase";

import FileUploader from "react-firebase-file-uploader";

const styles = (theme) => ({
  uploadButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    cursor: "pointer"
  }
});

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  setUploadState(isUploading, progress) {
    const { onUpdate } = this.props;

    const uploadState = { isUploading };

    if (isUploading) {
      if (progress !== undefined) {
        uploadState.progress = progress;
      }
    } else {
      uploadState.progress = null;
    }

    this.setState({ ...uploadState });

    if (onUpdate) {
      onUpdate(uploadState);
    }
  }

  handleUploadStart = () => this.setUploadState(true, 0);

  handleProgress = (progress) => this.setUploadState(true, progress);

  handleUploadError = (error) => {
    const { onError } = this.props;

    this.setUploadState(false);

    onError(error);
  };

  handleUploadSuccess = (filename) => {
    const { onSuccess } = this.props;

    this.setUploadState(false);

    onSuccess(filename);
  };

  render() {
    const { classes, uploadRef, label } = this.props;
    const { isUploading, progress } = this.state;

    return (
      <List>
        <ListItem button={!isUploading} className={classes.button}>
          <CameraAltIcon color="primary" className={classes.leftIcon} />
          <ListItemText
            primary={
              isUploading
                ? `Uploaden... ${progress}%`
                : label === undefined
                ? "Nieuwe foto toevoegen"
                : label
            }
          />
          <label className={classes.uploadButton}>
            <FileUploader
              hidden
              accept="image/*"
              name="hairdo"
              randomizeFilename
              storageRef={uploadRef}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </label>
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(UploadImage);
