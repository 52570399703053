import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TodayIcon from "@material-ui/icons/Today";
import HowToRegIcon from "@material-ui/icons/Person";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";

import Moment from "react-moment";
import { Typography } from "@material-ui/core";
import AppointmentSlot from "./AppointmentSlot";

import { findInArrayByPropVal } from "../utils";

const styles = theme => ({
  smallSlot: {
    margin: 1,
    padding: "5px 10px",
    "&::before": {
      position: "absolute",
      top: 0,
      left: 0,
      width: 2,
      height: "100%",
      background: "red",
      content: `''`
    }
  },
  availableSlot: {
    background: "rgba(150, 150, 150, 0.1)",
    "&::before": {
      background: "rgb(50, 50, 50)"
    }
  },
  bookedSlot: {
    background: "rgba(245, 0, 87, 0.1)",
    "&::before": {
      background: "rgb(245, 0, 87)"
    }
  },
  check: {
    color: "green"
  },
  smallTime: {
    fontSize: 14
  },
  smallCustomer: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 14,
    fontWeight: "bold",
    color: "black"
  },
  smallPhone: {
    fontSize: 12
  },
  smallButton: {
    fontSize: 12
  },
  smallIcon: {
    fontSize: 20
  },
  customer: {
    fontFamily: "Lumberjack Inline Rough",
    fontSize: 20,
    fontWeight: "bold",
    color: "black"
  }
});

class Slot extends PureComponent {
  constructor() {
    super();

    this.handleSlotClick = this.handleSlotClick.bind(this);
    this.handleBookSlotClick = this.handleBookSlotClick.bind(this);
    this.handleRemoveSlotClick = this.handleRemoveSlotClick.bind(this);
    this.handleSlotOptionsClick = this.handleSlotOptionsClick.bind(this);
  }

  getSlot() {
    let slot = {};

    for (let key in this.props) {
      if (
        key !== "classes" &&
        this.props[key] !== undefined &&
        typeof this.props[key] !== "function"
      ) {
        slot[key] = this.props[key];
      }
    }

    return slot;
  }

  handleSlotClick() {
    const { handleSlotClick } = this.props;

    if (handleSlotClick) {
      handleSlotClick(this.getSlot());
    }
  }

  handleBookSlotClick() {
    const { handleBookSlotClick } = this.props;

    if (handleBookSlotClick) {
      handleBookSlotClick(this.getSlot());
    }
  }

  handleRemoveSlotClick(event) {
    event.stopPropagation();

    const { handleRemoveSlotClick } = this.props;

    if (handleRemoveSlotClick) {
      handleRemoveSlotClick(this.getSlot());
    }
  }

  handleSlotOptionsClick(event) {
    event.stopPropagation();

    const { handleSlotOptionsClick } = this.props;

    if (handleSlotOptionsClick) {
      handleSlotOptionsClick(this.getSlot());
    }
  }

  findService = id => {
    const { services } = this.props;

    return findInArrayByPropVal(services, "id", id);
  };

  render() {
    const { id, begin, duration, type, data, size, disabled, classes } = this.props;
    const end = begin + duration;

    let beginHour = Math.floor(begin / 60);
    let endHour = Math.floor(end / 60);
    let beginMinute = begin % 60;
    if (beginMinute < 10) {
      beginMinute = "0" + beginMinute;
    }
    let endMinute = end % 60;
    if (endMinute < 10) {
      endMinute = "0" + endMinute;
    }

    let slotTime = beginHour + ":" + beginMinute;
    if (size !== "small") {
      slotTime += " - " + endHour + ":" + endMinute;
    }

    let color;
    if (data && data.serviceId) {
      const service = this.findService(data.serviceId);
      if (service) {
        color = service.color;
      }
    }

    switch (type) {
      case "available":
        return (
          <ListItem
            button
            disabled={disabled}
            onClick={this.handleSlotClick}
            className={
              size === "small"
                ? `${classes.smallSlot} ${classes.availableSlot}`
                : ""
            }
          >
            {size !== "small" && (
              <ListItemIcon>
                <CheckIcon className={classes.check} />
              </ListItemIcon>
            )}
            <ListItemText>
              <Typography className={size === "small" ? classes.smallTime : ""}>
                {slotTime}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Button
                color={size === "small" ? "default" : "secondary"}
                className={size === "small" ? classes.smallButton : ""}
                onClick={this.handleBookSlotClick}
              >
                Boek nu
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        );
        break;
      case "appointment":
        return (
          <AppointmentSlot
            id={id}
            disabled={disabled}
            size={size}
            color={color}
            slotTime={slotTime}
            data={data}
            handleSlotClick={this.handleSlotClick}
            handleRemoveSlotClick={this.handleRemoveSlotClick}
          />
        );
        /*return (
          <ListItem button onClick={ this.handleSlotClick } className={ size === 'small' ? `${classes.smallSlot} ${classes.bookedSlot}` : '' }>
            { size !== 'small' && <ListItemIcon><TodayIcon className={ classes.today } /></ListItemIcon> }
            <ListItemText>
              <Typography className={ size === 'small' ? classes.smallTime : '' }>{ `${slotTime}` }</Typography>
              { data.customer &&
                <div>
                  <Typography className={ size === 'small' ? classes.smallCustomer : classes.customer }>{ `${data.customer.name}` }</Typography>
                  { data.customer.phone && size !== 'small' && <Typography className={ size === 'small' ? classes.smallPhone : '' }>{ `${data.customer.phone}` }</Typography> }
                </div>
              }
            </ListItemText>
            <ListItemIcon onClick={ this.handleRemoveSlotClick }><DeleteIcon className={ size === 'small' ? classes.smallIcon : '' } /></ListItemIcon>
          </ListItem>
        );*/
        break;
      /*case 'holiday':
        return (
          <ListItem button onClick={ this.handleSlotClick } className={ size === 'small' ? classes.smallSlot : '' }>
            { size !== 'small' && <ListItemIcon><TodayIcon className={ classes.today } /></ListItemIcon> }
            <ListItemText>
              <Typography className={ size === 'small' ? classes.smallTime : '' }>{ `${slotTime}` }</Typography>
            </ListItemText>
            { size !== 'small' && <ListItemIcon onClick={ this.handleRemoveSlotClick }><DeleteIcon /></ListItemIcon> }
          </ListItem>
        );
        break;*/
    }
  }
}

export default withStyles(styles)(Slot);
