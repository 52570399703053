import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Moment from 'moment';
import ReactMoment from 'react-moment';

import { jsDateToDateString, dateStringToJSDate } from '../utils';

const styles = theme => ({
  header: {
    boxShadow: 'none',
    background: 'rgba(250,250,250,.85)',
    webkitBackground: 'rgba(250,250,250,.75)',
    webkitBackdropFilter: 'saturate(180%) blur(20px)',
    backdropFilter: 'saturate(180%) blur(20px)'
  },
  title: {
    flex: 1,
    marginTop: '8px',
    marginBottom: '-4px',
    textAlign: 'center',
    fontFamily: 'calligraffitiregular' //'shorelines_script_boldregular',
  },
  titleDate: {
    margin: '0 5px',
    fontWeight: 'bold'
  },
  hidden: {
    visibility: 'hidden'
  }
});

class MonthBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleDateChange(event) {
    if(event.target.value) {
      let date = dateStringToJSDate(event.target.value);
      this.changeDate(date);
    }
  };

  previousMonth() {
    return this.increaseMonth(-1);
  };

  nextMonth() {
    return this.increaseMonth(1);
  };

  increaseMonth(increment) {
    const { date } = this.props;
    const newDate = Moment(date)
      .add(increment, "months")
      .toDate();
    this.changeDate(newDate);
  };

  changeDate(date) {
    this.props.onDateChanged(date);
  };

  render() {
    const { classes, date, canGoBack, canGoForward, className } = this.props;

    return (
      <div>
        <Toolbar>
          <IconButton className={ canGoBack ? undefined : classes.hidden } color="secondary" aria-label="Vorige" onClick={ this.previousMonth }>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="title" color="inherit" className={ classes.title }>
            <ReactMoment className={ classes.titleDate } locale="nl-be" format="MMMM">
                { date }
            </ReactMoment>
          </Typography>
          <IconButton className={ canGoForward ? undefined : classes.hidden } color="secondary" aria-label="Volgende" onClick={ this.nextMonth }>
            <ArrowForwardIcon />
          </IconButton>
        </Toolbar>
      </div>
    );
  }
}

export default withStyles(styles)(MonthBar);
