//export const SIGN_UP = '/signup';
export const TO_SHOP = "/to/:locator?";
export const SELECT_SHOP_EMPTY = "/shops";
export const SELECT_SHOP = "/shops/:locator?/:redirect?";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const HOME = "/home";
export const DAY = "/day";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/pw-forget";
export const SCHEDULE = "/schedule";
export const SHOP_SCHEDULE = "/shop-schedule";
export const WEEK_SCHEDULE = "/week-schedule";
export const AVAILABILITY = "/availability";
export const SHOP = "/shop";
export const USER = "/user";
export const USER_SLOTS = "/user-slots";
export const USER_RESERVATIONS = "/user-reservations";
export const CONTROLROOM = "/controlroom";
export const EARNINGS = "/earnings";
export const PRODUCTS = "/products";
export const SERVICES = "/services";
export const RESERVATIONS = "/reservations";
export const CUSTOMERS = "/customers";
