import React, { PureComponent } from "react";

import Button from "@material-ui/core/Button";

import MonthBar from "./MonthBar";
import DaysPreviewMatrix from "./DaysPreviewMatrix";

import Moment from "moment";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  month: {},
  preview: {
    minWidth: 322,
    minHeight: 232
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 6
  }
});

class Month extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.selectToday = this.selectToday.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  componentDidMount() {
    const { selectedDate } = this.props;

    this.handleMonthChange(selectedDate);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  selectToday() {
    const { handleDateChange } = this.props;
    handleDateChange(new Date());
  }

  handleMonthChange(date) {
    const { requestAvailability } = this.props;

    const beginOfMonthDate = Moment(date)
      .startOf("month")
      .startOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift
    const endOfMonthDate = Moment(date)
      .endOf("month")
      .endOf("isoweek")
      .add(2, "hours")
      .toDate(); // Compensate for GMT+2 timeshift

    this.setState({ date, beginOfMonthDate, endOfMonthDate });

    requestAvailability(beginOfMonthDate, endOfMonthDate);
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      shop,
      barbers,
      services,
      availability,
      selectedDate,
      handleDateChange,
      classes
    } = this.props;
    const { date, beginOfMonthDate, endOfMonthDate } = this.state;

    return (
      <div className={classes.month}>
        <MonthBar
          date={date}
          onDateChanged={this.handleMonthChange}
          canGoBack={true}
          canGoForward={true}
        />
        <div className={classes.preview}>
          <DaysPreviewMatrix
            shop={shop}
            barbers={barbers}
            services={services}
            availability={availability}
            beginDate={beginOfMonthDate}
            endDate={endOfMonthDate}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
        </div>
        <div className={classes.controls}>
          <Button color="secondary" onClick={this.selectToday}>
            Vandaag
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Month);
