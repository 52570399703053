import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Moment from 'moment';
import ReactMoment from 'react-moment';

import { jsDateToDateString, dateStringToJSDate } from '../utils';

const styles = theme => ({
  header: {
    boxShadow: 'none',
    background: 'rgba(250,250,250,.85)',
    webkitBackground: 'rgba(250,250,250,.75)',
    webkitBackdropFilter: 'saturate(180%) blur(20px)',
    backdropFilter: 'saturate(180%) blur(20px)'
  },
  title: {
    flex: 1,
    marginTop: '8px',
    marginBottom: '-4px',
    textAlign: 'center',
    fontFamily: 'calligraffitiregular', //'shorelines_script_boldregular',
  },
  titleText: {
    
  },
  titleDate: {
    margin: '0 5px',
    fontWeight: 'bold'
  },
  week: {
    fontFamily: 'calligraffitiregular', //'shorelines_script_boldregular' 'Lumberjack Inline Rough',
    fontSize: 22,
    fontWeight: 'bold',
    color: 'black'
  },
  picker: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dateField: {
    left: '50%',
    transform: 'translate(-50%, 0)',
    marginTop: -7,
    marginLeft: 38,
    marginRight: theme.spacing.unit,
    width: 140
  },
  resize:{
    fontFamily: 'Lumberjack Inline Rough',
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#f50057'
  },
  hidden: {
    visibility: 'hidden'
  }
});

class WeekHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
    this.previousWeek = this.previousWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  handleDateChange(event) {
    if(event.target.value) {
      let date = dateStringToJSDate(event.target.value);
      this.changeDate(date);
    }
  };

  previousWeek() {
    return this.increaseDate(-7);
  };

  nextWeek() {
    return this.increaseDate(7);
  };

  increaseDate(increment) {
    const { date } = this.props.shop;
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + increment);
    this.changeDate(newDate);
  };

  changeDate(date) {
    this.props.onDateChanged(date);
  };

  render() {
    const { classes, shop, canGoBack, canGoForward, className } = this.props;

    const beginOfWeekDate = Moment(shop.date).startOf('isoweek').toDate();
    const endOfWeekDate = Moment(shop.date).endOf('isoweek').toDate();

    return (
      <AppBar position="fixed" color="default" className={`${classes.header} ${className ? className : ""}`}>
        <Toolbar>
          <IconButton className={ canGoBack ? undefined : classes.hidden } color="secondary" aria-label="Vorige" onClick={ this.previousWeek }>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="title" color="inherit" className={ classes.title }>
            <ReactMoment className={ classes.titleDate } locale="nl-be" format="D MMMM">
                { beginOfWeekDate }
            </ReactMoment>
            <span className={ classes.titleText }>-</span>
            <ReactMoment className={ classes.titleDate } locale="nl-be" format="D MMMM">
                { endOfWeekDate }
            </ReactMoment>
          </Typography>
          <IconButton className={ canGoForward ? undefined : classes.hidden } color="secondary" aria-label="Volgende" onClick={ this.nextWeek }>
            <ArrowForwardIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(WeekHeader);
