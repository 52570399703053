import React, { PureComponent } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";

import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import { withStyles } from "@material-ui/core/styles";
import Notification from "./Notification";

import { dateStringToJSDate } from "../utils";

import { store } from "../firebase";

const styles = theme => ({
  noNotifications: {
    opacity: 1
  },
  center: {
    padding: 0,
    textAlign: "center"
  }
});

class NotificationCenter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };

    //this.handleOptionClick = this.handleOptionClick.bind(this);
  }

  componentDidMount() {
    const { shop } = this.props;

    this.unsubscribeBarberNotifications = store.onGetBarberNotifications(
      shop.id,
      shop.barberId,
      notifications => {
        this.setState({ notifications });
      }
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeBarberNotifications) {
      this.unsubscribeBarberNotifications();
      this.unsubscribeBarberNotifications = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  handleListToggle = event => {
    if (this.state.anchor) {
      this.setState({ anchor: undefined });
    } else {
      this.setState({ anchor: event.currentTarget });
    }
  };

  handleNotificationClick = (id, type, timestamp, data) => {
    const { shop } = this.props;

    if (data && data.slot && data.slot.date) {
      const date = dateStringToJSDate(data.slot.date);

      this.setState({ redirect: routes.SCHEDULE }, () => {
        shop.update({ date });
        this.setNotificationSeen(id);
      });
    }
  };

  handleNotificationCheckClick = (id, type, timestamp, data) => {
    this.setNotificationSeen(id);
  };

  setNotificationSeen = id => {
    const { shop } = this.props;

    store
      .setNotificationSeen(shop.id, id)
      .then(() => {
        console.info("Notification seen OK");
      })
      .catch(error => {
        console.info("Notification seen Error:", error);
      });
  };

  handleClose = () => {
    this.setState({ anchor: undefined });
  };

  render() {
    const { classes } = this.props;
    const { anchor, notifications, redirect } = this.state;

    const open = Boolean(anchor);

    // Redirect

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <div>
        <IconButton aria-label="Notifications" onClick={this.handleListToggle}>
          <Badge
            badgeContent={notifications ? notifications.length : 0}
            color="secondary"
            invisible={
              notifications === undefined || notifications.length === 0
            }
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchor}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          getContentAnchorEl={undefined}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              //maxHeight: 600,
              width: 450
              //maxHeight: ITEM_HEIGHT * 4.5,
              //width: 450
            }
          }}
        >
          {notifications && notifications.length > 0 ? (
            notifications.map(notification => (
              <Notification
                key={notification.id}
                id={notification.id}
                type={notification.type}
                timestamp={notification.timestamp.toDate()}
                data={notification.data}
                onClick={this.handleNotificationClick}
                onCheckClick={this.handleNotificationCheckClick}
              />
            ))
          ) : (
            <MenuItem disabled={true} className={classes.noNotifications}>
              <ListItemText
                className={classes.center}
                primary="Geen meldingen"
              />
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(NotificationCenter);
