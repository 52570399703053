import { storage } from "./firebase";

export const ref = (path) => {
  return storage.ref(path);
};

export const getShopImages = (shopId, assets) => {

  return new Promise((resolve, reject) => {
    const promises = [
      getShopLogoImage(shopId, assets.logoFilename),
      getShopLaunchImage(shopId, assets.launchFilename),
      getShopStoryImage(shopId, assets.storyFilename)
    ];

    Promise.all(promises)
      .then((shopImages) => {
        if (shopImages && shopImages.length === promises.length) {
          resolve({
            logo: shopImages[0],
            launch: shopImages[1],
            story: shopImages[2]
          });
        } else {
          reject("shopImages invalid");
        }
      })
      .catch(reject);
  });
};

export const getBarberImages = (shopId, filenames) => {
  return new Promise((resolve, reject) => {
    const promises = filenames.map((filename) =>
      getBarberImage(shopId, filename)
    );

    Promise.all(promises)
      .then((barberImages) => {
        if (barberImages && barberImages.length === promises.length) {
          const barberImagesAsObject = {};
          barberImages.forEach((barberImage, index) => {
            barberImagesAsObject[filenames[index]] = barberImage;
          });
          resolve(barberImagesAsObject);
        } else {
          reject("barberImages invalid");
        }
      })
      .catch(reject);
  });
};

export const getHairdoImages = (shopId, customerId, filenames) => {
  return new Promise((resolve, reject) => {
    const promises = filenames.map((filename) =>
      getHairdoImage(shopId, customerId, filename)
    );

    Promise.all(promises)
      .then((hairdoImages) => {
        if (hairdoImages && hairdoImages.length === promises.length) {
          const hairdoImagesAsObject = {};
          hairdoImages.forEach((hairdoImage, index) => {
            hairdoImagesAsObject[filenames[index]] = hairdoImage;
          });
          resolve(hairdoImagesAsObject);
        } else {
          reject("hairdoImages invalid");
        }
      })
      .catch(reject);
  });
};

export const getShopLogoImage = (shopId, filename) => {
  return getShopAssetsRef(shopId)
    .child(filename)
    .getDownloadURL();
};

export const getShopLaunchImage = (shopId, filename) => {
  return getShopAssetsRef(shopId)
    .child(filename)
    .getDownloadURL();
};

export const getShopStoryImage = (shopId, filename) => {
  return getShopAssetsRef(shopId)
    .child(filename)
    .getDownloadURL();
};

export const getBarberImage = (shopId, filename) => {
  return getBarbersRef(shopId)
    .child(filename)
    .getDownloadURL();
};

export const getHairdoImage = (shopId, customerId, filename) => {
  return getHairdosRef(shopId, customerId)
    .child(filename)
    .getDownloadURL();
};

export const getShopAssetsRef = (shopId) => {
  return ref("shops")
    .child(shopId)
    .child("assets");
};

export const getBarbersRef = (shopId) => {
  return ref("shops")
    .child(shopId)
    .child("barbers");
};

export const getHairdosRef = (shopId, customerId) => {
  return ref("shops")
    .child(shopId)
    .child("customers")
    .child(customerId)
    .child("hairdos");
};
