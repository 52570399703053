import React, { PureComponent } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import MergeTypeIcon from "@material-ui/icons/MergeType";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

import AuthContext from "../context/auth";

import Loading from "../modals/Loading";
import Error from "../modals/Error";
import Confirm from "../modals/Confirm";
import Customers from "./Customers";

import { parsePhone, parseEmail } from "../utils";

const styles = theme => ({
  textField: {
    width: "100%;"
  },
  merge: {
    marginLeft: 16,
    paddingLeft: 0
  }
});

class Customer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      name: "",
      email: "",
      loading: {
        open: false
      },
      error: {
        open: false
      },
      confirm: {
        open: false
      }
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetch();
  }

  fetch() {
    const { shop, id } = this.props;

    if (id !== this.state.id) {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      this.setState(
        {
          id,
          phone: "",
          name: "",
          email: "",
          error: {
            open: false
          },
          unsavedChanges: undefined
        },
        () => {
          this.unsubscribe = store.onGetCustomerInfo(shop.id, id, info => {
            if (info) {
              info.unsavedChanges = undefined;
              this.setState(info);
            }
          });
        }
      );
    }
  }

  handleChange = key => event => {
    let val = event.target.value;

    this.setState({ [key]: val, unsavedChanges: true });
  };

  handleSaveChanges = () => {
    const { auth, shop, id } = this.props;
    const { phone, name, email } = this.state;

    let changes = {};
    if (phone) {
      changes.phone = phone;
    }
    if (name) {
      changes.name = name;
    }
    if (email) {
      changes.email = email;
    }

    this.setState(
      {
        loading: {
          open: true,
          description: "Wijzigingen bewaren..."
        }
      },
      () => {
        store
          .updateCustomer(
            shop.id,
            auth.user.uid,
            auth.isCrew === true,
            id,
            changes,
            taskId => {
              console.log(taskId);
              /*let loading = this.state.loading;
              loading.description = "Verifiëren en verwerken van je aanvraag...";
              this.setState({
                loading: loading
              });*/
            }
          )
          .then(() => {
            this.setState({
              unsavedChanges: undefined,
              loading: { open: false }
            });
          })
          .catch(error => {
            console.info(error);
            this.setState({
              loading: { open: false },
              error: {
                open: true,
                description: error && error.text ? error.text : "Onbekende fout"
              }
            });
          });
      }
    );
  };

  handleMergeWithCustomer = event => {
    this.setState({ anchor: event.currentTarget });
  };

  handleMergeWithCustomerSelect = (id, label, fields) => {
    this.setState({
      confirm: {
        open: true,
        description: `Ben je zeker dat je deze klant wil samenvoegen met '${label}'?`,
        id
      }
    });
  };

  handleMergeWithCustomerClose = () => {
    this.setState({ anchor: undefined });
  };

  handleErrorClose = () => {
    this.setState({ error: { open: false } });
  };

  handleConfirmDo = () => {
    const { auth, shop, id, onClear } = this.props;

    const customerId = this.state.confirm.id;

    this.setState(
      {
        loading: {
          open: true,
          description: "Klanten samenvoegen..."
        },
        confirm: { open: false }
      },
      () => {
        store
          .mergeCustomers(
            shop.id,
            auth.user.uid,
            auth.isCrew === true,
            id,
            customerId,
            true,
            taskId => {
              console.log(taskId);
              let loading = this.state.loading;
              loading.description =
                "Verifiëren en verwerken van je aanvraag...";
              this.setState({
                loading: loading
              });
            }
          )
          .then(() => {
            this.setState({ loading: { open: false } }, () => {
              if (onClear) {
                onClear();
              }
            });
          })
          .catch(error => {
            console.info(error);
            this.setState({
              loading: { open: false },
              error: {
                open: true,
                description: error && error.text ? error.text : "Onbekende fout"
              }
            });
          });
      }
    );
  };

  handleConfirmCancel = () => {
    this.setState({ confirm: { open: false } });
  };

  render() {
    const { shop, id, classes } = this.props;
    const {
      name,
      phone,
      email,
      loading,
      error,
      confirm,
      anchor,
      unsavedChanges
    } = this.state;

    return (
      <div>
        <Loading open={loading.open} description={loading.description} />
        <Error
          open={error.open}
          description={error.description}
          onClose={this.handleErrorClose}
        />
        <Confirm
          open={confirm.open}
          description={confirm.description}
          handleConfirm={this.handleConfirmDo}
          handleCancel={this.handleConfirmCancel}
        />
        <Customers
          shop={shop}
          anchor={anchor}
          omitItems={[id]}
          onSelect={this.handleMergeWithCustomerSelect}
          onClose={this.handleMergeWithCustomerClose}
        />
        <List>
          <ListItem>
            <TextField
              id="phone"
              label="Telefoonnummer"
              className={classes.textField}
              value={phone}
              onChange={this.handleChange("phone")}
              margin="normal"
              disabled={true}
            />
          </ListItem>
          <ListItem>
            <TextField
              required
              id="name"
              label="Naam"
              className={classes.textField}
              value={name}
              onChange={this.handleChange("name")}
              margin="normal"
            />
          </ListItem>
          <ListItem>
            <TextField
              id="email"
              label="E-mailadres"
              className={classes.textField}
              value={email}
              onChange={this.handleChange("email")}
              margin="normal"
            />
          </ListItem>
          {unsavedChanges && (
            <ListItem
              button
              className={classes.confirm}
              onClick={this.handleSaveChanges}
            >
              <CheckIcon color="primary" className={classes.leftIcon} />
              <ListItemText primary="Bevestigen" />
            </ListItem>
          )}
          <ListItem
            button
            className={classes.merge}
            onClick={this.handleMergeWithCustomer}
          >
            <MergeTypeIcon color="primary" className={classes.leftIcon} />
            <ListItemText primary="Samenvoegen met een andere klant" />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(props => (
  <AuthContext.Consumer>
    {auth => <Customer {...props} auth={auth} />}
  </AuthContext.Consumer>
));
