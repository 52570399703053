import React, { Component } from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

import { withStyles } from "@material-ui/core/styles";

import Moment from "moment";

import { dateStringToJSDate, findInArrayByPropVal } from "../utils";

import Loading from "../modals/Loading";
import Confirm from "../modals/Confirm";
import Reserve from "../components/Reserve";

import AddSlotToCalendar from "../components/AddSlotToCalendar";

import { store } from "../firebase";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  list: {
    minHeight: "calc(100vh - 122px)",
    backgroundColor: theme.palette.background.paper
  }
});

class Slots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        open: false
      },
      confirm: {
        open: false
      },
      reserve: {}
    };
  }

  componentDidMount() {
    this.refreshSlots();
  }

  componentWillUnmount() {
    if (this.unsubscribeCustomerSlots) {
      this.unsubscribeCustomerSlots();
      this.unsubscribeCustomerSlots = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerId } = this.props;

    if (customerId !== prevProps.customerId) {
      this.refreshSlots();
    }
  }

  refreshSlots() {
    const { shop, customerId, fromDate } = this.props;

    if (this.unsubscribeCustomerSlots) {
      this.unsubscribeCustomerSlots();
      this.unsubscribeCustomerSlots = undefined;
      this.setState({ slots: [] });
    }

    if (shop && shop.id && customerId) {
      this.unsubscribeCustomerSlots = store.onGetCustomerSlots(
        shop.id,
        customerId,
        fromDate,
        customerSlots => {
          this.setState({ slots: customerSlots });
        }
      );
    }
  }

  parseSlotHour = (begin, duration) => {
    const hour = Math.floor(begin / 60);

    let minute = begin % 60;
    if (minute < 10) {
      minute = `0${minute}`;
    }

    return `${hour}:${minute}`;
  };

  isSlotInTheFuture = date => {
    const now = Moment();
    const diff = Moment(dateStringToJSDate(date)).diff(now, "hours");

    return diff > 0;
  };

  canSlotBeRemoved = date => {
    const { info } = this.props;

    const maxHoursInAdvanceToCancel =
      info && info.maxHoursInAdvanceToCancel
        ? info.maxHoursInAdvanceToCancel
        : 24;

    const now = Moment();
    const diff = Moment(dateStringToJSDate(date)).diff(now, "hours");

    return diff > maxHoursInAdvanceToCancel;
  };

  handleConfirmSlotRemove = slot => {
    this.setState({
      slotToRemove: slot,
      confirm: {
        open: true,
        description: "Ben je zeker dat je deze afspraak wil verwijderen?"
      }
    });
  };

  handleConfirmClick = () => {
    const slot = this.state.slotToRemove;

    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {
        this.handleSlotRemove(slot);
      }
    );
  };

  handleCancelClick = () => {
    this.setState(
      {
        confirm: {
          open: false
        },
        slotToRemove: undefined
      },
      () => {}
    );
  };

  handleSlotRemove = slot => {
    const { shop, userId } = this.props;

    this.setState(
      {
        loading: {
          open: true,
          description: "Versturen van je aanvraag..."
        }
      },
      () => {
        store
          .removeBooking(shop.id, userId, false, slot.id, taskId => {
            console.log(taskId);
            let loading = this.state.loading;
            loading.description = "Verifiëren en verwerken van je aanvraag...";
            this.setState({
              loading: loading
            });
          })
          .then(() => {
            console.log("Document successfully updated!");

            this.setState({
              loading: {
                open: false
              }
            });
          })
          .catch(error => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);

            this.setState({
              loading: {
                open: false
              }
            });
          });
      }
    );
  };

  findProduct(id) {
    const { products } = this.props;

    return findInArrayByPropVal(products, "id", id);
  }

  printProduct(id) {
    const product = this.findProduct(id);

    if (product) {
      return product.name;
    }

    return "(onbekend)";
  }

  handleProductClick = (event, productId) => {
    /*this.setState({
      reserve: {
        anchor: event.currentTarget,
        productId
      }
    });*/
  };

  handleReserveClose = () => {
    this.setState({ reserve: {} });
  };

  render() {
    const { classes, shop, info, userId, showAddToCalendar } = this.props;
    const { loading, confirm, reserve, slots } = this.state;

    return (
      <div className={classes.root}>
        <Loading open={loading.open} description={loading.description} />
        <Confirm
          open={confirm.open}
          description={confirm.description}
          handleConfirm={this.handleConfirmClick}
          handleCancel={this.handleCancelClick}
        />
        {userId && (
          <Reserve
            anchor={reserve.anchor}
            shop={shop}
            userId={userId}
            productId={reserve.productId}
            onClose={this.handleReserveClose}
          />
        )}
        {slots && slots.length > 0 ? (
          slots.map((slot, index) => (
            <div key={slot.id} className={classes.slot}>
              <ListItem>
                {showAddToCalendar && this.isSlotInTheFuture(slot.date) && (
                  <AddSlotToCalendar shop={shop} slotId={slot.id} />
                )}
                <ListItemText
                  primary={
                    <span className={classes.slotDate}>
                      {`${Moment(slot.date).format(
                        "dddd D MMMM"
                      )}, ${this.parseSlotHour(slot.begin, slot.duration)}`}
                    </span>
                  }
                  secondary={
                    <span>
                      {slot.appliedProducts && (
                        <span>
                          <span className={classes.productsTitle}>
                            Gebruikte producten
                          </span>
                          <span>
                            {slot.appliedProducts.map((product, index) => (
                              <Button
                                key={index}
                                color="primary"
                                aria-label="product"
                                className={classes.product}
                                onClick={event => {
                                  this.handleProductClick(event, product.id);
                                }}
                              >
                                {this.printProduct(product.id)}
                              </Button>
                            ))}
                          </span>
                        </span>
                      )}
                    </span>
                  }
                />
                {userId && info && this.canSlotBeRemoved(slot.date) && (
                  <ListItemIcon
                    onClick={() => {
                      this.handleConfirmSlotRemove(slot);
                    }}
                  >
                    <DeleteIcon />
                  </ListItemIcon>
                )}
              </ListItem>
            </div>
          ))
        ) : (
          <ListItem>
            <ListItemText>Geen afspraken gevonden</ListItemText>
          </ListItem>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Slots);
