import React from "react";

import { Fab } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { withStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";

import { generateAuthorizedLink } from "../utils";

import AuthContext from "../context/auth";

const styles = theme => ({
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
    color: "#3f51b5" //'#f50057'
  },
  linkIcon: {
    display: "flex"
  },
  linkText: {
    marginLeft: 32
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

class AuthorizedLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.openLink = this.openLink.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  openLink() {
    const { auth, shop, link, params, target, onClick } = this.props;

    if (auth && auth.user && shop && shop.id) {
      generateAuthorizedLink(auth.user, shop.id, link, params)
        .then(url => {
          window.open(url, target ? target : "_blank");
        })
        .catch(error => {
          toast.error(error);
        });
    } else {
      toast.error("Geen gemachtigde gebruiker gevonden");
    }

    if (onClick) {
      onClick();
    }
  }

  render() {
    const { classes, shop, text, icon } = this.props;

    return (
      <div className={classes.link} onClick={this.openLink}>
        <div className={classes.linkIcon}>
          {icon ? icon : <ArrowDownwardIcon />}
        </div>
        {text && <span className={classes.linkText}>{text}</span>}
      </div>
    );
  }
}

/*
<Fab
        variant="extended"
        aria-label="download"
        className={classes.fab}
        onClick={this.openLink}
      >
        <div className={classes.extendedIcon}>
          {icon ? icon : <ArrowDownwardIcon />}
        </div>
        {text}
      </Fab>
*/

export default withStyles(styles)(props => (
  <AuthContext.Consumer>
    {auth => <AuthorizedLink {...props} auth={auth} />}
  </AuthContext.Consumer>
));
