import React, { Component } from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { withStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { filterNotRemoved } from "../utils";

import * as modals from "../constants/modals";

import withCrewAuthorization from "../components/withCrewAuthorization";

import Confirm from "../modals/Confirm";

import CrewHeader from "../components/CrewHeader";
import DayFooter from "../components/DayFooter";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  products: {
    minHeight: "calc(100vh - 114px)"
  }
});

class ProductsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: {
        open: false
      }
    };
  }

  handleProductAdd = () => {
    const { openModal } = this.props;

    openModal(modals.ADD_PRODUCT);
  };

  handleProductEdit = (product) => (event) => {
    const { openModal } = this.props;

    openModal(modals.EDIT_PRODUCT, {
      product
    });
  };

  handleConfirmProductRemove = (product) => (event) => {
    event.preventDefault();

    this.setState({
      productToRemove: product,
      confirm: {
        open: true,
        description: "Ben je zeker dat je dit product wil verwijderen?"
      }
    });
  };

  handleConfirmClick = () => {
    const product = this.state.productToRemove;

    this.setState(
      {
        confirm: {
          open: false
        },
        productToRemove: undefined
      },
      () => {
        this.handleProductRemove(product);
      }
    );
  };

  handleCancelClick = () => {
    this.setState(
      {
        confirm: {
          open: false
        },
        productToRemove: undefined
      },
      () => {}
    );
  };

  handleProductRemove = (product) => {
    const { shop } = this.props;

    const toastId = toast("Product verwijderen...", {
      autoClose: false
    });

    store
      .removeProduct(shop.id, product.id)
      .then(() => {
        toast.update(toastId, {
          render: "Product verwijderd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch((error) => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      });
  };

  render() {
    const { shop, info, products, toggleDrawer, classes } = this.props;
    const { confirm } = this.state;

    return (
      <div className={classes.root}>
        <Confirm
          open={confirm.open}
          description={confirm.description}
          handleConfirm={this.handleConfirmClick}
          handleCancel={this.handleCancelClick}
        />
        <CrewHeader
          title="Producten"
          toggleDrawer={toggleDrawer}
          buttons={[
            <IconButton onClick={this.handleProductAdd}>
              <AddIcon />
            </IconButton>
          ]}
        />
        <List className={classes.products}>
          {products &&
            filterNotRemoved(products).map((product, index) => (
              <ListItem key={index} onClick={this.handleProductEdit(product)}>
                <ListItemText
                  primary={product.name}
                  secondary={`€${product.price}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={this.handleConfirmProductRemove(product)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        <DayFooter
          shop={shop}
          info={info}
          hideService={true}
          hideBarber={true}
          hideCustomer={true}
          hideCrew={true}
        />
      </div>
    );
  }
}

export default withCrewAuthorization()(withStyles(styles)(ProductsPage));
